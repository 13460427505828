import { Formik, Form, Field } from "formik";
import { useState } from "react";
import { View, Text } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  useUpdateCompanyMutation,
} from "../../../../../__generated__/graphql-types";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientInputWrapper from "../../../web-components/ClientInputWrapper";
import ClientTextInput from "../../../web-components/ClientTextInput";
import {
  ANREDE_DROPDOWN_DATA,
  COUNTRIES,
  POSITIONEN_VERTRETUNGSBERECHTIGT,
  RECHTSFORM_DROPDOWN_DATA,
} from "../../../web-components/dropdown_data";
import * as yup from "yup";
import { contuineFormButton } from "../screens/ContractFormScreen";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

export default function BetreiberForm(props: {
  form: Company;
  contuineAndSave: () => void;
  contuineWithoutSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup.object({
    rechtsform: yup.string().required(),
    firma: yup.string().required(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    postleitzahl: yup.string().required(),
    land: yup.string().required(),
    telefon: yup.string().required(),
    mobil: yup.string().required(),
    whatsapp: yup.string().required(),
    email: yup.string().required(),
    vertretung_anrede: yup.string().required(),
    vertretung_vorname: yup.string().required(),
    vertretung_nachname: yup.string().required(),
    vertretung_position: yup.string().required(),
  });

  const [formData] = useState<Company>(props.form);
  const [showInfos, setShowInfos] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const companyTypesDE = [
    { companyType: "Einzelunternehmen", representative: "Inhaber/in" },
    { companyType: "GmbH", representative: "Geschäftsführer/in" },
    {
      companyType: "UG (haftungsbeschränkt)",
      representative: "Geschäftsführer/in",
    },
    { companyType: "GbR", representative: "Gesellschafter/in" },
    { companyType: "e.V.", representative: "1. Vorsitzender" },
    { companyType: "AG", representative: "Vorstandsvorsitzender" },
    { companyType: "OHG", representative: "Geschäftsführer/in" },
    { companyType: "KG", representative: "Gesellschafter/in" },
    { companyType: "Gemeinden", representative: "Bürgermeister" },
  ];

  const companyTypesAusland = [
    {
      companyType: "SA/NV (AG in Belgien,Frankreich,Luxemburg,Spanien)",
      representative: "Vorstandsvorsitzender",
    },
    {
      companyType: "SPRL/BVBA ( Belgien GmbH)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "A/S (AG Dänemark)",
      representative: "Vorstandsvorsitzender",
    },
    { companyType: "SE (AG Europa)", representative: "Vorstandsvorsitzender" },
    {
      companyType: "S.A.R.L. (GmbH Frankreich,Luxemburg,Schweiz)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "E.U.R.L ( KG Frankreich)",
      representative: "Gesellschafter/in",
    },
    {
      companyType: "AE/SA (AG Griechenland)",
      representative: "Vorstandsvorsitzender",
    },
    {
      companyType: "EPE (GmbH Griechenland)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "OE (OHG Griechenland)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "EE ( KG Griechenland)",
      representative: "Gesellschafter/in",
    },
    {
      companyType: "Ltd. (GmbH England,Irland)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "GesmbH (GmbH Österreich)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "KEG ( KG Österreich)",
      representative: "Gesellschafter/in",
    },
    {
      companyType: "CSA (AG Portugal)",
      representative: "Vorstandsvorsitzender",
    },
    {
      companyType: "AB (AG Schweden)",
      representative: "Vorstandsvorsitzender",
    },
    { companyType: "KB (KG Schweden)", representative: "Gesellschafter/in" },
    {
      companyType: "S.L. ( GmbH Spanien)",
      representative: "Geschäftsführer/in",
    },
    {
      companyType: "LLP ( UG-haftungsbeschränkt, England)",
      representative: "Geschäftsführer/in",
    },
  ];

  const [updateCompanyMutation] = useUpdateCompanyMutation();

  return (
    <Formik
      initialValues={formData.betreiberDetails || {}}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (newBetreiberForm) => {
        try {
          setIsLoading(true);
          let response = await updateCompanyMutation({
            variables: {
              id: formData._id,
              updateCompanyInput: {
                betreiberDetails: {
                  ...formData["betreiberDetails"],
                  ...newBetreiberForm,
                },
              },
            },
          })
          props.contuineAndSave();
          addToast("Erfolgreich gespeichert", { appearance: "success" });
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, setFieldValue, submitForm }) => (
        <Form>

          <View className="flex flex-row ml-auto mb-5 justify-end">
            {contuineFormButton({
              formIsReadonly: props.form.isReadonly,
              disabled: !isValid,
              isLoading,
              submitForm,
              contuineWithoutSaving: props.contuineWithoutSave
            })}
          </View>

          <div>
            <div>

              {/* {JSON.stringify(values, null, 2)}
              {JSON.stringify(errors, null, 2)} */}

              <div className="flex flex-rowjustify-between w-full lg:w-7/12 max-w-600px my-5 items-center">
                <div className="mt-5 ml-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Rechtsform</label>
                  <Autocomplete
                    size="small"
                    className="w-72"
                    noOptionsText="Rechtsform nicht gefunden."
                    disablePortal={false}
                    options={RECHTSFORM_DROPDOWN_DATA}
                    onChange={(event, rechtsform) => {
                      setFieldValue('rechtsform', rechtsform)
                    }}
                    defaultValue={values.rechtsform}
                    renderInput={(params) => (
                      <TextField {...params}></TextField>
                    )}
                  />
                </div>

              </div>
            </div>

            <Field
              component={ClientTextInput}
              name="firma"
              label="Firma"
              mode="text"
              disabled={props.form.isReadonly}
            ></Field>

            <ClientInputWrapper>
              <Field
                component={ClientTextInput}
                name="strasse"
                grow
                label="Straße"
                mode="text"
                disabled={props.form.isReadonly}
              ></Field>
              <Field
                component={ClientTextInput}
                name="postleitzahl"
                label="Postleitzahl"
                mode="text"
                disabled={props.form.isReadonly}
              ></Field>
              <Field
                component={ClientTextInput}
                name="ort"
                disabled={props.form.isReadonly}
                grow
                label="Ort"
                mode="text"
              ></Field>
              <Field
                component={ClientTextInput}
                disabled={props.form.isReadonly}
                name="land"
                label="Land"
                mode="textAndDropdown"
                onClick={(value: string) =>
                  setFieldValue("land", value)
                }
                dropdownData={COUNTRIES}
                addPleaseSelectOption={false}
              ></Field>
            </ClientInputWrapper>

            <ClientInputWrapper>
              <Field
                component={ClientTextInput}
                name="telefon"
                disabled={props.form.isReadonly}
                label="Telefon"
                mode="text"
              ></Field>
              <Field
                component={ClientTextInput}
                name="mobil"
                disabled={props.form.isReadonly}
                label="Mobil"
                mode="textAndDropdown"
                dropdownData={["n.v."]}
                addPleaseSelectOption={false}
                onClick={(value: string) => setFieldValue("mobil", value)}
              ></Field>
              <Field
                component={ClientTextInput}
                name="whatsapp"
                disabled={props.form.isReadonly}
                label="WhatsApp"
                mode="textAndDropdown"
                dropdownData={["n.v."]}
                addPleaseSelectOption={false}
                onClick={(value: string) => setFieldValue("whatsapp", value)}
              ></Field>
            </ClientInputWrapper>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="email"
              label="E-Mail"
              mode="text"
            ></Field>

            <AdminFormHeader>Vertretungsberechtige Person</AdminFormHeader>
            <Field
              component={ClientTextInput}
              name="vertretung_anrede"
              disabled={props.form.isReadonly}
              label="Anrede"
              mode="dropdown"
              dropdownData={ANREDE_DROPDOWN_DATA}
              addPleaseSelectOption={true}
            ></Field>

            <ClientInputWrapper>
              <Field
                component={ClientTextInput}
                disabled={props.form.isReadonly}
                name="vertretung_vorname"
                label="Vorname"
                mode="text"
                grow
              ></Field>
              <Field
                disabled={props.form.isReadonly}
                component={ClientTextInput}
                name="vertretung_nachname"
                label="Nachname"
                mode="text"
                grow
              ></Field>
              <Field
                component={ClientTextInput}
                disabled={props.form.isReadonly}
                name="vertretung_position"
                label="Position"
                mode="textAndDropdown"
                grow
                description="Wer ist Vertretungsberechtigt? (weitere Infos)"
                onDescriptionClick={() => setShowInfos(true)}
                onClick={(value: string) =>
                  setFieldValue("vertretung_position", value)
                }
                dropdownData={POSITIONEN_VERTRETUNGSBERECHTIGT}
                addPleaseSelectOption={false}
              ></Field>
            </ClientInputWrapper>

            {showInfos && (
              <View className="mx-3 sm:grid sm:grid-cols-1 p-2">
                <div>
                  <div className="px-2 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900 mb-4 ">
                      Wer ist Vertretungsberechtigt in Deutschland?
                    </h3>
                  </div>
                  <div className="border-gray-100">
                    {companyTypesDE.map((a) => (
                      <dl className="divide-y divide-gray-100 border-t p-1">
                        <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-1 sm:px-0">
                          <Text>{a.companyType}</Text>
                          <Text>{a.representative}</Text>
                        </div>
                      </dl>
                    ))}
                  </div>
                </div>
                <div>
                  <div className="px-2 sm:px-0 mt-2">
                    <h3 className="text-base font-semibold leading-7 text-gray-900 mb-4 ">
                      Wer ist Vertretungsberechtigt im Ausland?
                    </h3>
                  </div>
                  <div className="border-gray-100">
                    {companyTypesAusland.map((a) => (
                      <dl className="divide-y divide-gray-100 border-t p-1">
                        <div className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-1 sm:px-0">
                          <Text>{a.companyType}</Text>
                          <Text>{a.representative}</Text>
                        </div>
                      </dl>
                    ))}
                  </div>
                </div>
              </View>
            )}
          </div>
        </Form>
      )
      }
    </Formik >
  );
}
