import { Octicons, Feather, AntDesign } from "@expo/vector-icons";
import { Formik, Form } from "formik";
import { useState } from "react";
import { View, Text } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  PerformancePlace,
  PropertyFeatures,
  useUpdatePerformancePlacesPropertyFeaturesMutation,
} from "../../../../../__generated__/graphql-types";
import { textvorlage_2_extras } from "../../../../../constants";
import { classNames } from "../../../../../helpers";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientButton from "../../../web-components/ClientButton";
import { previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import ErfuellungsOrteTabs from "../../../web-components/ErfuellungsOrteTabs";

export default function PropertyFeaturesForm(props: {
  form: Company;
  contuineAndSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup
    .array()
    .of(
      yup.object({
        parking: yup.boolean().required(),
        beer_garden_terrace: yup.boolean().required(),
        vegetarian: yup.boolean().required(),
        playground_indoor: yup.boolean().required(),
        playground_outdoor: yup.boolean().required(),
        accessible: yup.boolean().required(),
        nfc_supported: yup.boolean().required(),
        dogs_allowed: yup.boolean().required(),
        home_delivery: yup.boolean().required(),
        event_hall: yup.boolean().required(),
        party_service: yup.boolean().required(),
        debit_card_payment: yup.boolean().required(),
        credit_card_payment: yup.boolean().required(),
        paypal: yup.boolean().required(),
        apple_pay: yup.boolean().required(),
        google_pay: yup.boolean().required(),
        vegan: yup.boolean().required(),
        wifi: yup.boolean().required(),
        e_bike_rental: yup.boolean().required(),
        e_car_rental: yup.boolean().required(),
        halal: yup.boolean().required(),
        bio: yup.boolean().required(),
        accommodation: yup.boolean().required(),
      })
    )
    .min(1);

  const [formData] = useState<Company>(props.form);
  const [updatePropertyFeatures] = useUpdatePerformancePlacesPropertyFeaturesMutation();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [currentErfuellungsOrtIndex, setCurrentErfuellungsOrt] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentErfuellungsOrt(newValue);
  };

  const getFeature = (
    values: PerformancePlace["propertyFeatures"][],
    formName: string
  ) => {
    return values[currentErfuellungsOrtIndex][
      formName as keyof PropertyFeatures
    ];
  };

  return (
    <Formik
      initialValues={formData.performancePlaces.map(
        (a) => a.propertyFeatures
      )}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (propertyFeatures) => {
        setIsLoading(true);
        try {
          const inputs = propertyFeatures.map((features, index) => ({
            performancePlaceId: formData.performancePlaces[index]._id!,
            propertyFeatures: features,
          }));

          await updatePropertyFeatures({
            variables: {
              updatePerformancePlacesPropertyFeaturesInputs: inputs
            },
          });

          props.contuineAndSave();
          addToast("Erfolgreich gespeichert", { appearance: "success" });
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, setFieldValue }) => (
        <Form>
          {/* {JSON.stringify(values, null, "\t")}
          {JSON.stringify(errors, null, "\t")} */}
          <View>
            <View className="flex flex-row ml-auto mb-5 justify-end">
              {previousFormButton(props.onBackClick)}

              <ClientButton
                type="submit"
                onClick={() => ""}
                disabled={!isValid}
                loading={isLoading}
                label="Speichern & Weiter"
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
                }
              ></ClientButton>
            </View>

            <ErfuellungsOrteTabs
              performancePlaces={formData.performancePlaces}
              onChange={handleChange}
            ></ErfuellungsOrteTabs>

            <AdminFormHeader onClick={() => console.log(values)}>
              <View className="flex flex-row justify-between">
                <Text>Ausstattungsmerkmale</Text>
                <Text>
                  {
                    textvorlage_2_extras.filter(
                      (ex) => getFeature(values, ex.formName) !== null
                    )?.length
                  }
                  {" von "}
                  {textvorlage_2_extras?.length}
                  {" ausgewählt "}
                </Text>
              </View>
            </AdminFormHeader>
            <View className="p-3 pr-0">
              <ul role="list" className="grid gap-2 grid-cols-1 lg:grid-cols-4">
                {textvorlage_2_extras.map((extra) => (
                  <li
                    key={extra.name}
                    className="w-52 col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <div className="flex-1 flex flex-col p-4">
                      <View className="bg-gray-50 p-2 rounded-xl w-15 center mx-auto">
                        {extra.icon}
                      </View>

                      <h3 className="mt-6 text-gray-900 text-base ">
                        {extra.name}
                      </h3>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div
                          onClick={() => {
                            setFieldValue(
                              `${currentErfuellungsOrtIndex}.${extra.formName}`,
                              true
                            );
                          }}
                          className={classNames(
                            "w-0 flex-1 hover:cursor-pointer rounded-bl-lg",
                            getFeature(values, extra.formName)
                              ? "bg-teal-500/60"
                              : ""
                          )}
                        >
                          <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                            <Octicons
                              name="check"
                              size={24}
                              color={
                                getFeature(values, extra.formName)
                                  ? "white"
                                  : "#14b8a6"
                              }
                            />
                          </div>
                        </div>

                        <div
                          className={classNames(
                            "w-0 flex-1 hover:cursor-not-allowed",
                            getFeature(values, extra.formName) === null
                              ? "bg-zinc-200"
                              : ""
                          )}
                        >
                          <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                            <Feather
                              name="slash"
                              size={24}
                              color={
                                getFeature(values, extra.formName) === null
                                  ? "gray"
                                  : "#e4e4e7"
                              }
                            />
                          </div>
                        </div>

                        <div
                          className={classNames(
                            "w-0 flex-1 hover:cursor-pointer rounded-br-lg",
                            getFeature(values, extra.formName) === false
                              ? "bg-red-500/70"
                              : ""
                          )}
                          onClick={() => {
                            setFieldValue(
                              `${currentErfuellungsOrtIndex}.${extra.formName}`,
                              false
                            );
                          }}
                        >
                          <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent hover:text-gray-500">
                            <AntDesign
                              name="close"
                              size={24}
                              color={
                                getFeature(values, extra.formName) === false
                                  ? "white"
                                  : "#f43f5e"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </View>
          </View>
        </Form>
      )}
    </Formik>
  );
}
