import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { LogEntity, useGetAllLogsQuery } from "../../../../__generated__/graphql-types";
import { transformDate } from "../../../../helpers";
import { MySwal } from "../../../../../WebApp";
import ReactJson from '@microlink/react-json-view'

export default function ErrorLogsScreen() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    data: logs,
    error,
    loading,
  } = useGetAllLogsQuery();

  const columns = useMemo<MRT_ColumnDef<LogEntity>[]>(
    () => [
      {
        accessorFn: (row) => row.typ,
        header: "Typ",
        Cell({ cell, column, row, table }) {
          if (row.original.typ === "error") {
            return <Text
              className={`p-1.5 bg-red-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide text-center`}
            >
              ERROR
            </Text>
          }

          if (row.original.typ === "warn") {
            return <Text
              className={`p-1.5 bg-yellow-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide text-center`}
            >
              WARN
            </Text>
          }

          if (row.original.typ === "info") {
            return <Text
              className={`p-1.5 bg-blue-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide text-center`}
            >
              INFO
            </Text>
          }
        },
      },
      {
        accessorFn: (row) => transformDate(row.updatedAt, true),
        header: "Datum",
      },
      {
        accessorFn: (row) => row.message,
        header: "Meldung",
      },
      {
        accessorFn: (row) => row.param,
        header: "Parameter",
        Cell({ cell, column, row, table }) {
          return <TouchableOpacity onPress={async () => {
            addToast("In die Console geloggt!", {appearance: "info"})
            console.log(row.original.param, null, "\t")
          }}
            disabled={!row.original.param}
            className="bg-gray-50 rounded-lg p-1 text-pink-500 font-medium text-center">
            <Text>Parameter anzeigen</Text>
          </TouchableOpacity>
        },
      },
      {
        accessorFn: (row) => row.error,
        header: "Fehler",
        Cell({ cell, column, row, table }) {
          return <TouchableOpacity onPress={async () => {
            addToast("In die Console geloggt!", {appearance: "info"})
            console.log(row.original.error, null, "\t")
          }}
            disabled={!row.original.error}
            className="bg-gray-50 rounded-lg p-1 text-pink-500 font-medium text-center">
            <Text>Fehler anzeigen</Text>
          </TouchableOpacity>
        },
      },
    ],
    []
  );

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white mt-2">
      <MaterialReactTable
        enableColumnFilterModes
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={logs?.getAllLogs || []}
        state={{ showSkeletons: loading }}
        columns={columns}
        initialState={{ density: "compact" }}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => { },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
      ></MaterialReactTable>
    </View>
  );
}
