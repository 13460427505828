import * as React from "react";
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from "@apollo/client";
import { useFonts } from "expo-font";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminClientLayout from "./src/screens/web/admin/AdminClientLayout";
import AdminAllContractsScreen from "./src/screens/web/admin/AdminAllContractsScreen";
import AdminSettingsScreen from "./src/screens/web/admin/AdminSettingsScreen";
import { ContractFormScreen } from "./src/screens/web/admin/contract/screens/ContractFormScreen";
import CouponTextTemplateList from "./src/screens/web/admin/settings/CouponTextTemplateList";
import CategoriesScreen from "./src/screens/web/admin/settings/CategoriesScreen";
import CouponTextTemplateDetails from "./src/screens/web/admin/settings/CouponTextTemplateDetails";
import { ToastProvider } from "react-toast-notifications";
import { setLocale as yupSetLocale } from "yup";
import { useEffect, useState } from "react";
import AdminLoginScreen from "./src/screens/web/admin/AdminLoginScreen";
import { CompanyOverviewScreen } from "./src/screens/web/admin/contract/screens/CompanyOverviewScreen";
import { VollmachtScreen } from "./src/screens/web/admin/contract/screens/VollmachtScreen";
import AdminDashboard from "./src/screens/web/admin/AdminDashboard";
import { persistor, store } from "./src/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import AuthBrowserRouter from "./src/screens/web/admin/AuthBrowserRouter";
import InitializeApolloWebClient from "./src/screens/app/services/InitializeApolloWebClient";
import Test from "./src/screens/web/admin/Test";
import AdminCouponCodes from "./src/screens/web/admin/AdminCouponCodesRequests";
import NiceModal from "@ebay/nice-modal-react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AdminOrderScreen from "./src/screens/web/admin/order/AdminOrderScreen";
import AdminAllOrdersScreen from "./src/screens/web/admin/AdminAllOrdersScreen";
import AdminSignRequestsReactions from "./src/screens/web/admin/AdminCustomerSignReactions";
import SignRequestResolveScreen from "./src/screens/web/extern/SignRequestResolveScreen";
import LoadingScreen from "./src/screens/web/web-components/LoadingScreen";
import ErrorLogsScreen from "./src/screens/web/admin/logs/ErrorLogsScreen";
import { Platform } from "react-native";
import AdminSignRequestConfirmationScreen from "./src/screens/web/admin/AdminSignRequestConfirmation";
import WebErrorBoundary from "./src/screens/web/admin/WebErrorBoundary";
import AdminRedeemedCouponsScreen from "./src/screens/web/admin/AdminRedeemedCouponsScreen";

export const MySwal = withReactContent(Swal);

export default function WebApp(props: any) {
  if (Platform.OS === 'web') {
    window.onerror = function (message, source, line, col, error) {
      console.error('ERROR HANDLER WEBAPP.TSX ', message, source, line, col, error)
      return true; // This will prevent further event propagation
    };
  } else {
    try {
      const defaultErrorHandler = ErrorUtils.getGlobalHandler()
      const myErrorHandler = (e: any, isFatal: boolean | undefined) => {
        // e: the error throwed
        // isFatal: if the error is fatal and will kill the app
        // define your code here...
        // after all, if you want to forward to default error handler
        // just call the variable we stored in the previous step
        console.error('ERROR HANDLER WEBAPP.TSX ', 'Error: ' + e, 'isFatal' + isFatal)
        defaultErrorHandler(e, isFatal)
      }
      ErrorUtils.setGlobalHandler(myErrorHandler)
    } catch (error) {
      console.error('ErrorUtils.getGlobalHandler ERROR for Platform ' + Platform.OS + error)
    }
  }

  yupSetLocale({
    mixed: {
      default: "Fehlerhafte Eingabe",
      required: "Erforderlich",
      oneOf: "Bitte auswählen",
    },
    string: {
      email: "Ungültige E-Mail-Adresse",
      min: "Muss mindestens ${min} Zeichen lang sein",
    },
  });

  useFonts({
    "RadioCanada-VariableFont": require("./assets/app-fonts/RadioCanada-VariableFont.ttf"),
  });

  let [apolloClient, setApolloClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);

  useEffect(() => {
    //@ts-ignore
    const script_1 = document.createElement("script");
    script_1.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    script_1.type = "text/javascript";
    //@ts-ignore
    document.body.appendChild(script_1);

    //@ts-ignore
    const script_2 = document.createElement("script");
    script_2.src = "https://product-gallery.cloudinary.com/all.js";
    script_2.type = "text/javascript";
    //@ts-ignore
    document.body.appendChild(script_2);

    const initApolloClient = async () => {
      let _apolloClient = await InitializeApolloWebClient();
      setApolloClient(_apolloClient);
    };
    initApolloClient();
  }, []);

  if (!apolloClient) {
    return <LoadingScreen>Portal wird geladen.. Bitte warten..</LoadingScreen>
  }

  //wenn apollo nicht läuft dann in playground in console schauen!!!
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Test>
          <ApolloProvider client={apolloClient}>
            <WebErrorBoundary>
              <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
                <NiceModal.Provider>
                  <BrowserRouter>
                    <AuthBrowserRouter>
                      <Routes>
                        <Route
                          path="/client/login"
                          element={<AdminLoginScreen />}
                        />
                        <Route
                          path="/client/signRequest/:id/"
                          element={<SignRequestResolveScreen />}
                        />
                      </Routes>
                      <AdminClientLayout>
                        <Routes>
                          <Route>
                            <Route
                              path="/client/dashboard"
                              element={<AdminDashboard />}
                            />
                            <Route
                              path="/client/coupon-codes"
                              element={<AdminCouponCodes />}
                            />
                            <Route
                              path="/client/redeemed-coupons"
                              element={<AdminRedeemedCouponsScreen />}
                            />
                            <Route
                              path="/client/overview/:id/"
                              element={<CompanyOverviewScreen />}
                            />
                            <Route
                              path="/client/orders/newOrder"
                              element={<AdminOrderScreen />}
                            />
                            <Route
                              path="/client/orders/:orderId"
                              element={<AdminOrderScreen />}
                            />
                            <Route
                              path="/client/orders/signrequest-confirmation"
                              element={<AdminSignRequestConfirmationScreen />}
                            />
                            <Route
                              path="/client/orders/all-orders"
                              element={<AdminAllOrdersScreen />}
                            />
                            <Route
                              path="/client/vollmacht/:id/"
                              element={<VollmachtScreen />}
                            />
                            <Route
                              path="/client/update/:id/:step"
                              element={<ContractFormScreen />}
                            />
                            <Route
                              path="/client/contracts"
                              element={<AdminAllContractsScreen />}
                            />
                            <Route
                              path="/client/reactions/sign-requests-reactions"
                              element={<AdminSignRequestsReactions />}
                            />
                            <Route
                              path="/client/settings"
                              element={<AdminSettingsScreen />}
                            />
                            <Route
                              path="/client/settings/text-templates"
                              element={<CouponTextTemplateList />}
                            />
                            <Route
                              path="/client/settings/text-templates/:id"
                              element={<CouponTextTemplateDetails />}
                            />
                            <Route
                              path="/client/settings/categories"
                              element={<CategoriesScreen />}
                            />
                            <Route
                              path="/client/error-logs"
                              element={<ErrorLogsScreen />}
                            />
                            <Route
                              path="*"
                              element={<Navigate to="/client/dashboard" replace={true} />}
                            />
                          </Route>
                        </Routes>
                      </AdminClientLayout>
                    </AuthBrowserRouter>
                  </BrowserRouter>
                </NiceModal.Provider>
              </ToastProvider>
            </WebErrorBoundary>
          </ApolloProvider>
        </Test>
      </PersistGate>
    </Provider>
  );
}
