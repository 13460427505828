import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  CouponCodes,
  RedeemedCoupon,
  useGetAllCouponCodesRequestsQuery,
  useGetAllRedeemedCouponsQuery,
} from "../../../__generated__/graphql-types";
import { Feather } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { IconButton } from "@mui/material";
import { transformDate } from "../../../helpers";

export default function AdminRedeemedCouponsScreen() {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    data: couponCodeRequests,
    error,
    loading: couponCodeRequestsOnLoading,
    refetch: refetchCouponCodeRequests,
  } = useGetAllRedeemedCouponsQuery();

  const columns = useMemo<MRT_ColumnDef<RedeemedCoupon>[]>(
    () => [
      {
        accessorFn: (row) => transformDate(row.redeemedAt),
        header: "Eingelöst am",
      },
      {
        accessorFn: (row) => row.user?.username + "(" + row.user?.email + ")",
        header: "Eingelöst von",
      },
      {
        accessorFn: (row) => row.erfuellungsOrt,
        header: "Erfüllungsort",
      },
      {
        accessorFn: (row) => row.coupon?.maximumAmount + " €",
        header: "Maximale Ersparnis €",
      },
      {
        accessorFn: (row) => row.coupon?.header,
        header: "Gutschein",
      },
      {
        accessorFn: (row) => row.coupon?.description,
        header: "Beschreibung",
      },
    ],
    []
  );

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white">
      <MaterialReactTable
        enableColumnFilterModes
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={couponCodeRequests?.getAllRedeemedCoupons || []}
        state={{
          showSkeletons: couponCodeRequestsOnLoading,
        }}
        columns={columns as any}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => {
          },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Aktionen",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Eingelöste Coupons
          </h2>
        )}
      ></MaterialReactTable>
    </View>
  );
}
