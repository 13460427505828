import { Octicons } from "@expo/vector-icons";
import { Formik, Form, Field } from "formik";
import { useState } from "react";
import { View } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  KommunikationsanschriftDetails,
  useUpdateCompanyMutation,
} from "../../../../../__generated__/graphql-types";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientButton from "../../../web-components/ClientButton";
import ClientInputWrapper from "../../../web-components/ClientInputWrapper";
import ClientTextInput from "../../../web-components/ClientTextInput";
import {
  ANREDE_DROPDOWN_DATA,
  COUNTRIES,
  LOAD_KOMMUNIKATIONSANSCHRIFT,
  POSITIONEN_VERTRETUNGSBERECHTIGT,
} from "../../../web-components/dropdown_data";
import { contuineFormButton, previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";

export default function KommunikationsanschriftForm(props: {
  form: Company;
  contuineAndSave: () => void;
  contuineWithoutSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup.object({
    firma: yup.string().required(),
    strasse: yup.string().required(),
    ort: yup.string().required(),
    postleitzahl: yup.string().required(),
    land: yup.string().required(),

    telefon: yup.string(),
    mobil: yup.string().required(),
    email: yup.string().required(),

    anrede: yup.string().required(),
    vorname: yup.string().required(),
    nachname: yup.string().required(),
    position: yup.string().required()
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formData] = useState<Company>(props.form);
  const [updateCompanyMutation] = useUpdateCompanyMutation();
  const { addToast } = useToasts();

  return (
    <Formik
      initialValues={formData.kommunikationsanschriftDetails || {}}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (newkommunikationsanschriftForm) => {
        try {
          console.log('onsubmit')
          setIsLoading(true);
          let response = await updateCompanyMutation({
            variables: {
              id: formData._id,
              updateCompanyInput: {
                kommunikationsanschriftDetails: {
                  ...formData["kommunikationsanschriftDetails"],
                  ...newkommunikationsanschriftForm,
                },
              },
            },
          });
          if (response.data?.updateCompany) {
            props.contuineAndSave();
            addToast("Erfolgreich gespeichert!", { appearance: "success" });
          } else {
            addToast("Daten konnten nicht gespeichert werden! #5 response.data?.updateCompany ist leer!", { appearance: "error" });
          }
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, resetForm, submitForm, setFieldValue }) => (
        <Form>
          {/* {JSON.stringify(values, null, 2)}
          {JSON.stringify(errors, null, 2)} */}

          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}
            {contuineFormButton({
              formIsReadonly: props.form.isReadonly,
              disabled: !isValid,
              isLoading,
              submitForm,
              contuineWithoutSaving: props.contuineWithoutSave
            })}
          </View>

          <ClientTextInput
            label="Betreiber/Erfüllungsort laden"
            mode="dropdown"
            disabled={props.form.isReadonly}
            dropdownData={LOAD_KOMMUNIKATIONSANSCHRIFT}
            addPleaseSelectOption={false}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              let value = event.target.value;
              if (value === "AUS BETREIBER LADEN") {
                const existingKeys = Object.keys(
                  formData.betreiberDetails
                ).filter((key) => values.hasOwnProperty(key));
                const newValues = existingKeys.reduce(
                  (result, key) => {
                    result[key] = formData.betreiberDetails[key];
                    return result;
                  },
                  { ...values }
                );
                resetForm({ values: newValues });
              }
            }}
          ></ClientTextInput>

          <AdminFormHeader>Adresse...</AdminFormHeader>
          <Field
            component={ClientTextInput}
            name="firma"
            disabled={props.form.isReadonly}
            label="Erfüllungsort / Firma"
            mode="text"
          ></Field>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="strasse"
              label="Straße"
              grow
              mode="text"
            ></Field>
            <Field
              component={ClientTextInput}
              name="postleitzahl"
              disabled={props.form.isReadonly}
              label="Plz"
              mode="text"
            ></Field>
            <Field
              component={ClientTextInput}
              name="ort"
              disabled={props.form.isReadonly}
              label="Ort"
              grow
              mode="text"
            ></Field>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="land"
              label="Land"
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("land", value)
              }
              dropdownData={COUNTRIES}
              addPleaseSelectOption={false}
            ></Field>
          </ClientInputWrapper>

          <AdminFormHeader>Kontakt...</AdminFormHeader>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              name="telefon"
              disabled={props.form.isReadonly}
              label="Telefon"
              mode="text"
            ></Field>
            <Field
              component={ClientTextInput}
              name="mobil"
              disabled={props.form.isReadonly}
              label="Mobil"
              mode="text"
            ></Field>
            <Field
              component={ClientTextInput}
              name="email"
              disabled={props.form.isReadonly}
              label="Kommunikations E-Mail"
              mode="text"
              grow
            ></Field>
          </ClientInputWrapper>

          <AdminFormHeader>Ansprechpartner...</AdminFormHeader>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              name="anrede"
              label="Anrede"
              disabled={props.form.isReadonly}
              mode="dropdown"
              dropdownData={ANREDE_DROPDOWN_DATA}
              addPleaseSelectOption={true}
            ></Field>
            <Field
              component={ClientTextInput}
              name="vorname"
              label="Vorname"
              grow
              disabled={props.form.isReadonly}
              addPleaseSelectOption={false}
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("vorname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_vorname,
              ]}
            ></Field>
            <Field
              component={ClientTextInput}
              name="nachname"
              label="Nachname"
              grow
              disabled={props.form.isReadonly}
              addPleaseSelectOption={false}
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("nachname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_nachname,
              ]}
            ></Field>
          </ClientInputWrapper>
          <Field
            component={ClientTextInput}
            name="position"
            disabled={props.form.isReadonly}
            label="Position im Unternehmen"
            grow
            mode="textAndDropdown"
            onClick={(value: string) =>
              setFieldValue("position", value)
            }
            dropdownData={POSITIONEN_VERTRETUNGSBERECHTIGT}
          ></Field>
        </Form>
      )}
    </Formik>
  );
}
