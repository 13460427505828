import dayjs from "dayjs";
import { PerformancePlace, Maybe, CompanyState, OrderState, Role } from "./__generated__/graphql-types";
import * as Location from "expo-location";

export const classNames = (...classes: (string | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
}

export const requestLocationPremissions = async (): Promise<{ granted: boolean, coordinates: Location.LocationObjectCoords | undefined }> => {
  let { status } = await Location.requestForegroundPermissionsAsync();
  if (status !== "granted") {
    return { granted: false, coordinates: undefined };
  }
  let location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.High });
  return { granted: true, coordinates: location.coords };
}

export const transformDate = (input: any, showOnlyTimeWhenToday?: boolean, hideTime?: boolean) => {
  if (input === undefined) {
    return 'ungültiges Datum!'
  }
  if (input === '--') {
    return input;
  }

  if (new Date(input).toString() === 'Invalid Date') {
    return input;
  }

  // console.log(input, typeof input, moment(input), moment(new Date(input)))

  let date = dayjs(new Date(input)).locale('de');
  if (showOnlyTimeWhenToday && dayjs(input).isSame(new Date(), "day")) {
    return date.format('HH:mm')
  } else {
    return hideTime ? date.format('DD.MM.YYYY') : date.format('DD.MM.YYYY, HH:mm')
  }
}

export const toDate = (input: any) => {
  try {
    return new Date(input);
  } catch (error) {
    return undefined;
  }
}

export const getPerformancePlaceTitle = (orte: PerformancePlace[] | undefined) => {
  if (!orte) {
    return "";
  }

  if (orte.length === 1) {
    return orte[0].erfuellungsOrt;
  }

  return orte[0].erfuellungsOrt + " + " + (orte.length - 1) + " weitere...";
}

export const sliceId = (_id: string | undefined | Maybe<string>) => {
  if (!_id) return '';
  return _id.slice(_id.length - 7, _id.length).toUpperCase();
}

export const delay = async (timeOut: number) => {
  await new Promise((f) => setTimeout(f, timeOut));
}

export const generateRandomString = (length: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export const createPdfViewer = (base64?: string, showToolbar: boolean = true, height: number = 630) => {
  if (!base64) {
    return <div>----base64 empty----</div>
  }

  let url = base64;

  if (base64.startsWith("http")) {
    if (url.indexOf("cloudinary") > -1)
      url = url.replace("http", "https");
  } else {
    const blob = base64ToBlob(base64);
    url = URL.createObjectURL(blob);
  }


  if (!showToolbar) {
    url += "#toolbar=0"
  }

  return (
    <div
      style={{
        height,
      }}
    >
      <div
        id="preview-pane"
        style={{
          position: "relative",
          height,
          zIndex: "999",
        }}
      >
        <object className="overflow-hidden w-full h-full" data={url}></object>
      </div>
    </div>
  )
}

export const base64ToBlob = (base64: string) => {
  const binStr = atob(base64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: "application/pdf" });
}


export const formatNumberToCurrency = (
  input: string | number | Maybe<number> | undefined
): string => {
  try {
    const numericValue =
      typeof input === "string" ? parseFloat(input.replace(",", ".")) : input;
    if (numericValue === undefined || isNaN(numericValue)) {
      // addToast("Ungültiger Zahlenwert:" + numericValue, { appearance: "warning" });
      return "";
    }
    const formattedValue = numericValue?.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
    return formattedValue || "";
  } catch (error) {
    console.log('FEHLER: Wert ungültig! ' + error)
    return "FEHLER: Wert ungültig!";
  }
};

export const enumToArray = (enumObj: any): string[] => {
  if (enumObj === null || typeof enumObj !== "object") {
    throw new Error("Input must be an Enum.");
  }

  const keys = Object.keys(enumObj);
  return keys.map(key => key.toUpperCase());
}

export const decisionTableIcon = () => (
  <div className="flex flex-row border border-gray-100 rounded-xl">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(13, 148, 136)" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(248, 113, 113)" className="-ml-2 w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
)

export const getDescriptionForCompanyState = (state: CompanyState): string => {
  switch (state) {
    case CompanyState.DRAFT:
      return "Entwurf";
    case CompanyState.WAIT_CUSTOMER_REACTION:
      return "Warte auf Kundenreaktion";
    case CompanyState.IN_REVIEW:
      return "In Prüfung";
    case CompanyState.APPROVED:
      return "Genehmigt";
    case CompanyState.REJECTED:
      return "Abgelehnt";
    case CompanyState.INACTIVE:
      return "Inaktiv";
    case CompanyState.PRINTED_SENDED:
      return "Gedruckt & Verschickt";
    case CompanyState.ACTIVE:
      return "Aktiv";
    default:
      return state;
  }
}

export const getDescriptionForOrdersState = (state: OrderState): string => {
  switch (state) {
    case OrderState.DRAFT:
      return "Entwurf";
    case OrderState.WAIT_CUSTOMER_REACTION:
      return "Warte auf Kundenreaktion";
    case OrderState.IN_REVIEW:
      return "In Prüfung";
    case OrderState.NOT_PAYED:
      return "Nicht bezahlt";
    case OrderState.PAYED_NOT_PRINTED:
      return "Bezahlt - nicht gedruckt";
    case OrderState.PAYED_SHIPPED:
      return "Bezahlt & versendet";
    case OrderState.REJECTED:
      return "Abgelehnt";
    default:
      return state;
  }
}

export const getDescriptionForRole = (role: Role | null | undefined): string => {
  switch (role) {
    case Role.ADMIN:
      return "Admin";
    case Role.DISTRIBUTION:
      return "Vertrieb";
    default:
      return role || 'Unbekannt';
  }
}