import { Text, View } from "react-native";
import { CompanyState, Maybe, OrderState } from "../../../__generated__/graphql-types";

export default function OrderStateBadge(props: { state: Maybe<OrderState> | OrderState | undefined }) {
    if (props.state === OrderState.DRAFT) {
        return (<Text
            className={`p-1.5 bg-blue-100 text-blue-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
        >
            Entwurf
        </Text>)
    } else if (props.state === OrderState.NOT_PAYED) {
        return (
            <Text className={`p-1.5 bg-red-100 text-red-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}>
                Nicht bezahlt
            </Text>
        );
    } else if (props.state === OrderState.PAYED_NOT_PRINTED) {
        return (
            <Text className={`p-1.5 bg-yellow-100 text-yellow-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}>
                Bezahlt - nicht gedruckt
            </Text>
        );
    } else if (props.state === OrderState.PAYED_SHIPPED) {
        return (
            <Text
                className={`p-1.5 bg-teal-100 text-teal-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
                Bezahlt & Verschickt
            </Text>
        );
    } else if (props.state === OrderState.WAIT_CUSTOMER_REACTION) {
        return (
            <Text
                className={`p-1.5 bg-gray-100 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
                Warte auf Kunde
            </Text>
        );
    } else if (props.state === OrderState.IN_REVIEW) {
        return (
            <Text
                className={`p-1.5 bg-yellow-100 text-yellow-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
                In Prüfung
            </Text>
        );
    } else if (props.state === OrderState.REJECTED) {
        return (
            <Text
                className={`p-1.5 bg-red-100 text-red-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
                Abgelehnt
            </Text>
        );
    } else {
        return (
            <Text
            className={`p-1.5 bg-gray-200 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
                {props.state}
            </Text>
        );
    }
}
