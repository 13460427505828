import {
  WebUser,
} from "../../../store/web-slices/userSlice.web";
import {
  createHttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
import apolloLogger from "apollo-link-logger";
import { onError } from "@apollo/client/link/error";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { EXPO_PUBLIC_WEB_GRAPHQL_BACKEND_URL } from "@env";

export default function InitializeApolloWebClient(): Promise<
  ApolloClient<NormalizedCacheObject>
> {
  return new Promise(async (resolve, reject) => {

    console.log('Apollo connected: ', EXPO_PUBLIC_WEB_GRAPHQL_BACKEND_URL);

    const httpApolloLink = createHttpLink({
      uri: EXPO_PUBLIC_WEB_GRAPHQL_BACKEND_URL
    });

    // let { access_token } = useSelector(selectWebUser);

    //__typename wird aus dem Client Request entfernt
    const cleanTypeName = new ApolloLink((operation, forward) => {
      const omitTypename = (key: string, value: any) =>
        key === "__typename" ? undefined : value;

      if (operation.variables && !operation.getContext().hasUpload) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          omitTypename
        );
      }
      return forward(operation);
    });

    let access_token = "";
    let currentWebUser = {};
    try {
      // useSelector nicht möglich da der Store noch nicht initialisiert ist deswegen direkt aus AsyncStorage holen!
      let persistStore = await AsyncStorage.getItem("persist:root");
      let user = JSON.parse(JSON.parse(persistStore || "")?.webUser) as WebUser;
      access_token = user.access_token || "";
      currentWebUser = user.currentWebUser || {};
    } catch (error) {
      console.log("access_token wurde nicht gefunden! ");
    }

    // AsyncStorage.getAllKeys((err, keys) => {
    //   AsyncStorage.multiGet(keys, (error, stores) => {
    //     stores.map((result, i, store) => {
    //       console.log({ [store[i][0]]: store[i][1] });
    //       return true;
    //     });
    //   });
    // });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          currentUser: JSON.stringify(currentWebUser), // currentUser von App und Web im Backend
          authorization: access_token ? `Bearer ${access_token}` : "",
        },
      };
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
      console.error('ON ERROR ', graphQLErrors, networkError, operation, response);
      if (graphQLErrors)
        graphQLErrors.forEach((graphQLError) =>
          console.error(
            `[GraphQL error]: Message: ${graphQLError.message}, Location: ${graphQLError.locations}, Path: ${graphQLError.path}`
          )
        );
      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    // const logoutLink = onError(({ networkError }) => {
    //   if (networkError.statusCode === 401) logout();
    // })

    let client = new ApolloClient({
      cache: new InMemoryCache(),
      connectToDevTools: true,
      uri: "/graphql",
      credentials: "same-origin",
      defaultOptions: {
        watchQuery: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
        query: {
          fetchPolicy: "no-cache",
          errorPolicy: "all",
        },
      },
      link: ApolloLink.from([
        errorLink,
        authLink,
        apolloLogger,
        cleanTypeName,
        httpApolloLink,
      ]),
    });

    console.log("Apollo Client initialize.");
    resolve(client);
  });
}
