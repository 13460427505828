import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import {
  Company,
  useCreateVollmachtPdfMutation,
  useGetCompanyWebQuery,
  useUpdateCompanyMutation,
} from "../../../../../__generated__/graphql-types";
import { useParams } from "react-router-dom";
import { getPerformancePlaceTitle, transformDate } from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import {
  AntDesign,
  Feather,
  FontAwesome5,
  MaterialIcons,
  Octicons,
} from "@expo/vector-icons";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ClientTextInput from "../../../web-components/ClientTextInput";
import { UploadImage as UploadFile } from "../../../../../services/ImageUploadService";
import { useToasts } from "react-toast-notifications";
import ClientButton from "../../../web-components/ClientButton";
import { MySwal } from "../../../../../../WebApp";

export const VollmachtScreen = (props: any) => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  let { id, step } = useParams();
  let {
    loading: fetchCompanyLoading,
    error: fetchCompanyError,
    refetch: refetchCompany,
  } = useGetCompanyWebQuery({
    variables: { id: id || "" },
  });
  const [
    vollmachtMutation,
    { loading: createVollmachtLoading, error: createVollmachtError },
  ] = useCreateVollmachtPdfMutation();

  const [updateCompanyMutation] = useUpdateCompanyMutation();

  let navigate = useNavigate();
  const { addToast } = useToasts();

  useEffect(() => {
    if (id) {
      refetchCompany({ id }).then((a) => {
        setCompany(a.data?.getCompany as Company);
      });
    }
  }, [id]);

  if (!company || fetchCompanyLoading) {
    return <Text>Wird geladen..</Text>;
  }

  if (fetchCompanyError && fetchCompanyError.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {fetchCompanyError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  if (createVollmachtError && createVollmachtError.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {createVollmachtError.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  const createPdf = async () => {
    return await vollmachtMutation({
      variables: {
        createVollmachtPdfInput: {
          betreiberDetails: company.betreiberDetails,
          beraterKundendatenDetails: company.beraterKundendatenDetails,
          performancePlaces: company.performancePlaces,
        },
      },
    })
      .then(async (a) => {
        let  authority = (
          await UploadFile(
            a.data?.createVollmachtPdf || "",
            company._id,
            "sf4l8j60"
          )
        )?.url;

        updateCompanyMutation({
          variables: {
            id: company._id,
            updateCompanyInput: {
              documentLinks: {
                 authority,
              },
            },
          },
        })
          .then((a) => {
            addToast("Erfolgreich gespeichert. Vollmacht wurde angelegt.", {
              appearance: "success",
            });
          })
          .catch((e) => {
            addToast("Fehler " + e, { appearance: "error" });
            console.log("Fehler ", e);
          });
      })
      .catch((e) => {
        addToast("Fehler " + e, { appearance: "error" });
      });
  };

  const createVollmacht = async () => {
    if (company.documentLinks.adviceProtocol) {
      MySwal.fire({
        title: `Beratungsprotokoll vorhanden`,
        showDenyButton: true,
        html: "Wenn du ein neues Protokoll erstellen möchtest, wird das vorhandene Protokoll überschrieben. <br><strong>Bist du sicher, dass du fortfahren möchtest?</strong>",
        showCancelButton: true,
        confirmButtonColor: "#4f46e5",
        confirmButtonText: `Ja, neues Protokoll erstellen`,
        denyButtonText: `Protokoll anzeigen`,
        denyButtonColor: "black",
        cancelButtonText: "Abbrechen",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await createPdf();
          navigate(`/client/overview/${company._id}`);
        }
        if (result.isDenied) {
          window.open(company.documentLinks.adviceProtocol);
        }
      });
    } else {
      await createPdf();
      navigate(`/client/overview/${company._id}`);
    }
  };

  return (
    <>
      <View className="bg-white p-6 m-3 mr-11  rounded-xl lg:flex lg:flex-row lg:items-start lg:justify-between">
        <View>
          <Text className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Vollmacht für {company?.erfuellungsOrtDetails.performancePlaces[0]?.erfuellungsOrt}
          </Text>
          <Text className="text-sm muted text-gray-400 leading-6">
            Kundennummer: {company?.kundennummer}
          </Text>
        </View>
      </View>

      <div className="bg-white  overflow-hidden sm:rounded-lg mx-4">
        <div className="flex flex-row m-6">
          <div className="flex flex-row items-center">
            <h3 className="text-lg leading-6 font-bold text-gray-900 ">
              Informationen über die Vollmacht
            </h3>

            <ClientButton
              icon={<AntDesign name="pdffile1" size={24} color="white" />}
              onClick={createVollmacht}
              label="Vollmacht erstellen"
              iconLeft={true}
              className="ml-10"
            ></ClientButton>
          </div>
          <div></div>
        </div>
        <div className="border-t border-gray-200 px-2 py-5 sm:px-3">
          <dl className="gap-x-4 gap-y-8">
            <ClientTextInput
              disabled
              label="Hiermit bestätige ich (Anrede, Vorname, Nachname)"
              defaultValue={
                company.beraterKundendatenDetails.unterzeichnet_anrede +
                " " +
                company.beraterKundendatenDetails.unterzeichnet_vorname +
                " " +
                company.beraterKundendatenDetails.unterzeichnet_nachname
              }
            ></ClientTextInput>
            <ClientTextInput
              disabled
              label="tätig für den Gewerbetreibenden/Betreiber"
              mode="textarea"
              rows={3}
              defaultValue={
                company.betreiberDetails.firma +
                "\n" +
                company.betreiberDetails.strasse +
                "\n" +
                company.betreiberDetails.postleitzahl +
                " " +
                company.betreiberDetails.ort +
                " " +
                company.betreiberDetails.land
              }
            ></ClientTextInput>
            <ClientTextInput
              disabled
              label="in folgender Position beim Gewerbetreibenden/Betreiber beschäftigt"
              defaultValue={
                company.beraterKundendatenDetails.unterzeichnet_position || ""
              }
            ></ClientTextInput>
            <ClientTextInput
              disabled
              label="Vertretungsberechtige Person (Anrede, Vorname, Nachname)"
              defaultValue={
                company.betreiberDetails.vertretung_anrede +
                " " +
                company.betreiberDetails.vertretung_vorname +
                " " +
                company.betreiberDetails.vertretung_nachname
              }
            ></ClientTextInput>
            <ClientTextInput
              disabled
              label="Position"
              defaultValue={company.betreiberDetails.vertretung_position || ""}
            ></ClientTextInput>
            <ClientTextInput
              disabled
              label="bevollmächtigt worden zu sein, den Anzeigeauftrag für (Betrieb/Erfüllungsort)"
              mode="textarea"
              rows={3}
              defaultValue={
                company.performancePlaces[0].erfuellungsOrt +
                "\n" +
                company.performancePlaces[0].strasse +
                "\n" +
                company.performancePlaces[0].postleitzahl +
                " " +
                company.performancePlaces[0].ort +
                " " +
                company.performancePlaces[0].land
              }
            ></ClientTextInput>
          </dl>
        </div>
      </div>
    </>
  );
};
