import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  AddOrderPaymentInput,
  Order,
  OrderState,
  Role,
  useAddOrderPaymentMutation,
  useGetAllOrdersQuery,
  useSendMailMutation,
  useSendOrderConfirmationAndInvoiceMutation,
} from "../../../__generated__/graphql-types";
import {
  Feather,
} from "@expo/vector-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { IconButton, Tooltip } from "@mui/material";
import { transformDate } from "../../../helpers";
import NiceModal from "@ebay/nice-modal-react";
import { AddOrderPaymentModal } from "../web-modals/AddOrderPayment.modal";
import ClientButton from "../web-components/ClientButton";
import { ShipOrderPositionsModal } from "../web-modals/ShipOrderPositions.modal";
import OrderStateBadge from "../web-components/OrderStateBadge";
import { MySwal } from "../../../../WebApp";
import { selectWebUser } from "../../../store/web-slices/userSlice.web";
import { useSelector } from "react-redux";

export default function AdminAllOrdersScreen() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [addOrderPayment] = useAddOrderPaymentMutation();
  let [searchParams] = useSearchParams();
  const [sendOrderEmail] = useSendOrderConfirmationAndInvoiceMutation();
  let { currentWebUser } = useSelector(selectWebUser);

  const {
    data: orders,
    error,
    loading,
    refetch: refetchOrders,
  } = useGetAllOrdersQuery({
    variables: {
      state: searchParams.get('state'),
      orderNumbers: searchParams.get('orderNumbers') && searchParams.get('orderNumbers')?.split(",") || []
    }
  });

  const getHeaderText = (state: string | null) => {
    if (searchParams.get('headerText')) {
      return searchParams.get('headerText');
    }

    if (!state) {
      return "Bestellungen: Alle";
    }
    switch (state.toUpperCase()) {
      case OrderState.DRAFT:
        return "Bestellungen: Entwürfe"
      case OrderState.PAYED_SHIPPED:
        return "Bestellungen: Bezahlt & Verschickt"
      case OrderState.PAYED_NOT_PRINTED:
        return "Bestellungen:  Bezahlt & nicht gedruckt"
      case OrderState.NOT_PAYED:
        return "Bestellungen: Nicht bezahlt"
      case OrderState.WAIT_CUSTOMER_REACTION:
        return "Bestellungen: Warte auf Kundenreaktion"
      case OrderState.IN_REVIEW:
        return "Bestellungen: In Prüfung"
      case OrderState.REJECTED:
        return "Bestellungen: Abgelehnt";
      default:
        return state;
    }
  }

  const columns = useMemo<MRT_ColumnDef<Order>[]>(
    () => [
      {
        accessorFn: (row) => row,
        header: "Status",
        size: 270,
        maxSize: 270,
        Cell({ cell, column, row, table }) {
          return <OrderStateBadge state={row.original.state}></OrderStateBadge>
        },
      },
      {
        accessorFn: (row) => transformDate(row.createdAt, true),
        header: "Bestelldatum",
      },
      {
        accessorFn: (row) => row.orderNumber,
        header: "Bestellnummer",
      },
      {
        accessorFn: (row) => row.company?.kundennummer,
        Cell({ cell, column, row, table }) {
          return (<div>
            {row.original.company === null && <span>Nicht vorhanden</span>}
            {row.original.company !== null && <a className="text-pink-500 cursor-pointer font-bold underline" href={`/client/overview/${row.original.company?._id}`}>{row.original.company?.kundennummer}</a>}
          </div>)
        },
        header: "Vertrag?",
      },
      {
        accessorFn: (row) => row.summary?.grossAmount?.toFixed(2) + " €",
        header: "Summe € (Brutto)",
      },
      {
        header: "Lieferanschrift",
        Cell({ cell, column, row, table }) {
          return <Tooltip title={
            <div>
              <div>{row.original.deliveryAddress?.company}</div>
              <div>{row.original.deliveryAddress?.streetName}</div>
              <div>{row.original.deliveryAddress?.postalCode + " " + row.original.deliveryAddress?.city}</div>
              <div>{row.original.deliveryAddress?.country}</div>
            </div>
          } >
            <div className="border-b border-dashed cursor-pointer">{row.original.deliveryAddress?.company}</div>
          </Tooltip>
        },
      },
      {
        header: "Rechnungsanschrift",
        Cell({ cell, column, row, table }) {
          return <Tooltip title={
            <div>
              <div>{row.original.rechnungsadresse?.firma}</div>
              <div>{row.original.rechnungsadresse?.strasse}</div>
              <div>{row.original.rechnungsadresse?.postleitzahl + " " + row.original.rechnungsadresse?.ort}</div>
              <div>{row.original.rechnungsadresse?.land}</div>
            </div>
          } >
            <div className="border-b border-dashed cursor-pointer">{row.original.deliveryAddress?.company}</div>
          </Tooltip>
        }
      }
    ],
    []
  );

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white mt-2">
      <MaterialReactTable
        enableColumnFilterModes
        enableRowActions
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={orders?.getAllOrders || []}
        state={{ showSkeletons: loading }}
        columns={columns as any}
        initialState={{ density: "compact", pagination: { pageIndex: 0, pageSize: 100 } }}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => { },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Aktionen",
            size: 100, //make actions column wider
          },
        }}
        renderRowActions={({ row, table }) => (
          <View className="flex flex-row flex-nowrap ">
            <IconButton
              title={row.original.state === OrderState.DRAFT ? "Bestellung bearbeiten" : "Bestellung öffnen"}
              onClick={() => {
                navigate(`/client/orders/${row.original?._id}`);
              }}
            >
              {row.original.state === OrderState.DRAFT ?
                (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C274C" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>
                ) :
                (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C274C" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                )}
            </IconButton>

            {row.original.company && (
              <IconButton
                title="Unternehmen anzeigen"
                onClick={() => {
                  row?.original &&
                    row.original.company &&
                    navigate(`/client/overview/${row.original.company._id}`);
                }}
              >
                <Feather name="user" size={24} color="#1C274C" />
              </IconButton>
            )}

            {row.original.state === OrderState.NOT_PAYED && (
              <IconButton
                title="Rechnung erneut verschicken"
                onClick={async () => {
                  let swalResult = await MySwal.fire({
                    title: `ACHTUNG: Rechnung erneut verschicken`,
                    html: `Das Unternehmen ${row.original.rechnungsadresse?.firma} wird eine erneute Rechnung zugesandt.
                    <br><br>Möchtest du diese Aktion wirklich vornehmen?
                    <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                    showDenyButton: true,
                    width: 700,
                    confirmButtonText: `Ja, Rechnung senden!`,
                    denyButtonText: `Abbrechen`,
                  });

                  if (!swalResult.isConfirmed) {
                    return;
                  }

                  try {
                    await sendOrderEmail({
                      variables: {
                        input: {
                          orderId: row.original._id
                        }
                      }
                    });
                    addToast("Rechnung erfolgreich verschickt", { appearance: "success" });
                  } catch (error) {
                    addToast("FEHLER: " + error, {
                      appearance: "error",
                    });
                    console.error("FEHLER: ", error);
                  }
                  refetchOrders({
                    state: searchParams.get('state'),
                    orderNumbers: searchParams.get('orderNumbers') && searchParams.get('orderNumbers')?.split(",") || []
                  });
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#1C274C"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </IconButton>
            )}

            {row.original.state === OrderState.PAYED_NOT_PRINTED && (
              <IconButton
                title="Artikel verschicken"
                onClick={async () => {
                  let modalResult = (await NiceModal.show(
                    ShipOrderPositionsModal,
                    { order: row.original }
                  )) as {
                    resolved: boolean;
                  };

                  if (!modalResult.resolved) {
                    return;
                  }
                  refetchOrders({
                    state: searchParams.get('state'),
                    orderNumbers: searchParams.get('orderNumbers') && searchParams.get('orderNumbers')?.split(",") || []
                  });
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C274C" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                </svg>
              </IconButton>
            )}
          </View>
        )
        }
        renderTopToolbarCustomActions={(row) => (
          <div className="flex flex-row items-center">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mr-3">
              {getHeaderText(searchParams.get('state'))}
            </h2>
            <div className="flex flex-row ml-2">
              {searchParams?.get('filterLabels') && (
                searchParams.get('filterLabels')?.split(",").map(label => (
                  <div key={label} className="rounded-full p-2 mr-2 bg-pink-50 border font-medium text-sm border-pink-500 text-pink-500">
                    {label}
                  </div>
                ))
              )}
            </div>
            {(!searchParams.get('state') || searchParams.get('state')?.toUpperCase() === OrderState.NOT_PAYED) && currentWebUser?.role === Role.ADMIN && (
              <ClientButton
                disabled={!orders?.getAllOrders?.length}
                label="Zahlungseingang buchen +"
                iconLeft={true}
                onClick={async () => {
                  let modalResult = (await NiceModal.show(
                    AddOrderPaymentModal
                  )) as {
                    resolved: boolean;
                    values: AddOrderPaymentInput;
                  };

                  if (!modalResult.resolved) {
                    return;
                  }
                  console.log(modalResult);

                  try {
                    let createdOrderPayment = await addOrderPayment({
                      variables: { addOrderPaymentInput: modalResult.values },
                    });
                  } catch (error) {
                    addToast("FEHLER: " + error, {
                      appearance: "error",
                    });
                    console.error("FEHLER: ", error);
                  }
                  refetchOrders({
                    state: searchParams.get('state'),
                    orderNumbers: searchParams.get('orderNumbers') && searchParams.get('orderNumbers')?.split(",") || []
                  });
                }}
              ></ClientButton>
            )}
          </div>
        )}
      ></MaterialReactTable >
    </View >
  );
}
