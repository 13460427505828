import React, { useCallback, useRef, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { Form, Formik } from "formik";
import ClientButton from "../../../web-components/ClientButton";
import { EvilIcons } from "@expo/vector-icons";
import {
  Company,
  PerformancePlace,
  imagesDetails,
  Maybe,
  useUpdateCompanyMutation,
  useUpdatePerformancePlacesImagesMutation,
  UpdatePerformancePlacesImagesInput,
} from "../../../../../__generated__/graphql-types";
import { previousFormButton } from "../screens/ContractFormScreen";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);
import ImageGallery from "react-image-gallery";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import { useToasts } from "react-toast-notifications";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Card } from "../../../web-components/DnDCard";
import update from "immutability-helper";
import * as yup from "yup";
import { MySwal } from "../../../../../../WebApp";
import ErfuellungsOrteTabs from "../../../web-components/ErfuellungsOrteTabs";
import { Image } from "expo-image";
import NiceModal from "@ebay/nice-modal-react";
import { ImageUploadQrCodeModal } from "../../../web-modals/ImageUploadQrCode.modal";

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export default function BilderForm(props: {
  form: Company;
  contuineAndSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup.array().of(yup.object({
    logo: yup.string(),
    images: yup.array(),
  }));

  const [formData] = useState<Company>(props.form);
  const imageGalleryRef = useRef<ImageGallery>(null);
  const [updatePerformancePlacesImages] = useUpdatePerformancePlacesImagesMutation();
  const { addToast } = useToasts();
  const [currentErfuellungsOrtIndex, setCurrentErfuellungsOrt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [cards, setCards] = useState(
    formData.performancePlaces[0]?.imagesDetails.images?.map(
      (value, index) => ({
        id: index,
        text: value,
      })
    ) || []
  );

  const handleChange = (
    event: React.SyntheticEvent,
    newIndex: number,
    values: PerformancePlace["imagesDetails"][]
  ) => {
    setCurrentErfuellungsOrt(newIndex);
    let images = getCurrentPerformancePlace(values, newIndex).images?.map((value: string, index: number) => ({ id: index, text: value, })) || [];
    setCards(images);
  };

  const getCurrentPerformancePlace = (
    values: imagesDetails[],
    currentErfuellungsOrtIndex: number
  ): imagesDetails => {
    return values[currentErfuellungsOrtIndex];
  };

  const uploadImageWidget = (props: {
    maxImages: number;
    onUpload: (url: string[]) => void;
  }) => {
    let itemCount = 0;
    let uploadCount = 0;
    let urls: string[] = [];
    let myUploadWidget = window.cloudinary.openUploadWidget(
      {
        cloudName: "youtwome",
        uploadPreset: "sf4l8j60",
        folder: formData._id,
        tags: ["myname"],
        maxImageWidth: 900,
        maxFiles: props.maxImages,
        sources: ["local", "url", "camera"],
        multiple: true,
        theme: "white",
        secure: true,
        text: {
          // https://upload-widget.cloudinary.com/2.7.4/global/text.json
        },
      },
      (error: any, result: any) => {
        if (result.event === "upload-added") {
          itemCount++;
        }
        if (!error && result.event === "success") {
          let url = result?.info?.url || '';

          //FORCE HTTPS!
          if (url.startsWith("http")) {
            if (url.indexOf("cloudinary") > -1) {
              url = url.replace("http", "https");
            }
          }

          urls.push(url);
          uploadCount++;
          if (itemCount === uploadCount) {
            //Erst callback wenn alle Bilder hochgeladen worden sind um einzelne callbacks zu vermeiden
            props.onUpload(urls);
          }
        }
      }
    );
    myUploadWidget.open();
  };

  const uploadImage = () => {
    uploadImageWidget({
      maxImages: 10,
      onUpload: (urls: string[]) => {
        console.log(urls);
      },
    });
  };

  const getCurrentImage = (images: Maybe<string[]> | undefined) => {
    let index = imageGalleryRef.current?.getCurrentIndex() ?? 0;
    return images && images[index];
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);

  return (
    <Formik
      initialValues={formData.performancePlaces.map((a) => a.imagesDetails)}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (placeImages) => {
        setIsLoading(true);
        try {
          const inputs: UpdatePerformancePlacesImagesInput[] = placeImages.map((images, index) => ({
            performancePlaceId: formData.performancePlaces[index]._id,
            imagesDetails: images,
          }));

          let { data } = await updatePerformancePlacesImages({ variables: { updatePerformancePlacesImagesInputs: inputs } })
          props.contuineAndSave();
          addToast("Erfolgreich gespeichert", { appearance: "success" });
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        }
        finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, resetForm, setFieldValue, setValues }) => (
        <Form>
          {/* {JSON.stringify(values, null, "\t")}
          {JSON.stringify(errors, null, "\t")} */}

          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}

            <ClientButton
              type="submit"
              onClick={async () => {
                let signatureQrCodeModalResult = (await NiceModal.show(
                  ImageUploadQrCodeModal,
                  {
                    companyId: formData._id
                  }
                )) as {
                  uploaded: boolean;
                };

                if (!signatureQrCodeModalResult?.uploaded) {
                  return;
                }
                window.location.reload();
              }}
              loading={isLoading}
              icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
              </svg>
              }
              mode="secondary"
              label="Bilder hochladen"
            ></ClientButton>

            <ClientButton
              type="submit"
              onClick={() => ""}
              loading={isLoading}
              icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
              }
              label="Speichern & Zur Übersicht"
            ></ClientButton>
          </View>

          <ErfuellungsOrteTabs
            performancePlaces={formData.performancePlaces}
            onChange={(event, newIndex) => {
              handleChange(event, newIndex, values);
            }}
          ></ErfuellungsOrteTabs>

          <AdminFormHeader>Logo</AdminFormHeader>
          <View className="grid lg:grid-cols-2 grid-cols-1 pt-3 pb-3 pl-3">
            <View className="max-w-200px lg:max-w-500px border border-dashed border-gray-200 p-11 mr-3">
              {!getCurrentPerformancePlace(values, currentErfuellungsOrtIndex)?.logo && (
                <TouchableOpacity
                  className="items-center cursor-pointer"
                  onPress={uploadImage}
                >
                  <EvilIcons name="image" size={150} color="#E5E7EB" />
                </TouchableOpacity>
              )}

              {(getCurrentPerformancePlace(values, currentErfuellungsOrtIndex)?.logo) && (
                <Image
                  contentFit="contain"
                  style={{
                    height: "300px",
                    width: "300px",
                  }}
                  className="mx-auto"
                  source={getCurrentPerformancePlace(values, currentErfuellungsOrtIndex).logo}
                />
              )}
            </View>
            <View className="mt-3 sm:mt-none">
              <button
                onClick={async () => {
                  await uploadImageWidget({
                    maxImages: 1,
                    onUpload: async (urls: string[]) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].logo`, urls[0]);
                    },
                  });
                }}
                type="button"
                className="max-w-200px lg:max-w-100% py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-blue-100 border border-transparent font-semibold text-blue-500 hover:text-white hover:bg-blue-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
              >
                HINZUFÜGEN
              </button>
              <button
                type="button"
                onClick={async () => {
                  let result = await MySwal.fire({
                    title: `Logo entfernen? \n`,
                    showDenyButton: true,
                    confirmButtonText: `Ja, Bild entfernen!`,
                    html: `<img class="mx-auto" src="${getCurrentPerformancePlace(values, currentErfuellungsOrtIndex).logo}" width="150" height="150">`,
                    denyButtonText: `Abbrechen`,
                  })

                  if (!result.isConfirmed) {
                    return;
                  }
                  setFieldValue(`[${currentErfuellungsOrtIndex}].logo`, "");
                }}
                className="max-w-200px lg:max-w-100% py-3 mt-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-red-100 border border-transparent font-semibold text-red-500 hover:text-white hover:bg-red-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-red-500 focus:ring-offset-2 transition-all text-sm "
              >
                LOGO ENTFERNEN
              </button>
            </View>
          </View>

          <AdminFormHeader>Bilder</AdminFormHeader>
          <View className="grid lg:grid-cols-2 grid-cols-1 pt-3 pb-3 pl-3">
            <View className="max-w-200px lg:max-w-500px border border-dashed border-gray-200 p-11 mr-3">
              {!getCurrentPerformancePlace(values, currentErfuellungsOrtIndex)
                .images?.length && (
                  <TouchableOpacity
                    className="items-center cursor-pointer"
                    onPress={uploadImage}
                  >
                    <EvilIcons name="image" size={150} color="#E5E7EB" />
                  </TouchableOpacity>
                )}

              {(getCurrentPerformancePlace(values, currentErfuellungsOrtIndex)?.images?.length || -1) > 0 && (
                <ImageGallery
                  ref={imageGalleryRef}
                  items={getCurrentPerformancePlace(
                    values,
                    currentErfuellungsOrtIndex
                  ).images?.map((img: string) => ({ original: img, thumbnail: img }))}
                  showFullscreenButton
                  autoPlay={false}
                  slideInterval={1000}
                  showPlayButton={false}
                  showBullets={true}
                  showIndex={true}
                />
              )}
            </View>
            <View className="mt-3 sm:mt-none">
              <button
                onClick={async () => {
                  await uploadImageWidget({
                    maxImages: 10,
                    onUpload: async (urls: string[]) => {
                      let images = [
                        ...urls,
                        ...(getCurrentPerformancePlace(
                          values,
                          currentErfuellungsOrtIndex
                        ).images || []),
                      ];
                      setFieldValue(
                        `[${currentErfuellungsOrtIndex}].images`,
                        images
                      );
                      setCards(
                        images.map((value, index) => ({
                          id: index,
                          text: value,
                        }))
                      );
                    },
                  });
                }}
                type="button"
                className="max-w-200px lg:max-w-100% py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-blue-100 border border-transparent font-semibold text-blue-500 hover:text-white hover:bg-blue-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
              >
                HINZUFÜGEN
              </button>
              <button
                type="button"
                onClick={async () => {
                  let result = await MySwal.fire({
                    title: `Bild entfernen? \n`,
                    showDenyButton: true,
                    confirmButtonText: `Ja, Bild entfernen!`,
                    html: `<img class="mx-auto" src="${getCurrentImage(
                      getCurrentPerformancePlace(
                        values,
                        currentErfuellungsOrtIndex
                      ).images
                    )}" width="150" height="150">`,
                    denyButtonText: `Abbrechen`,
                  })

                  if (!result.isConfirmed) {
                    return;
                  }

                  let imgToDelete = getCurrentPerformancePlace(
                    values,
                    currentErfuellungsOrtIndex
                  ).images?.find(
                    (url: string) =>
                      url ===
                      getCurrentImage(
                        getCurrentPerformancePlace(
                          values,
                          currentErfuellungsOrtIndex
                        ).images
                      )
                  );
                  if (imgToDelete) {
                    let newImages = getCurrentPerformancePlace(values, currentErfuellungsOrtIndex).images?.filter((img: string) => img !== imgToDelete) || [];
                    setFieldValue(`[${currentErfuellungsOrtIndex}].images`, newImages);
                    setCards(newImages.map((value: string, index: number) => ({ id: index, text: value, })));
                  }
                }}
                className="max-w-200px lg:max-w-100% py-3 mt-3 px-4 inline-flex justify-center items-center gap-2 rounded-md bg-red-100 border border-transparent font-semibold text-red-500 hover:text-white hover:bg-red-500 focus:outline-none focus:ring-2 ring-offset-white focus:ring-red-500 focus:ring-offset-2 transition-all text-sm "
              >
                {`AKTUELLES BILD ENTFERNEN`}
              </button>
            </View>
          </View>

          <AdminFormHeader>Bilder Reihenfolge</AdminFormHeader>
          {
            getCurrentImage(
              getCurrentPerformancePlace(values, currentErfuellungsOrtIndex)?.images
            ) ? (
              <View className="p-3">
                <DndProvider backend={HTML5Backend}>
                  <div className="flex flex-row flex-wrap">
                    {cards.map((card: any, index: number) => (
                      <Card
                        key={card.id}
                        index={index}
                        id={card.id}
                        text={card.text}
                        moveCard={(dragIndex, hoverIndex) => {
                          moveCard(dragIndex, hoverIndex);
                          let newOrder = update(cards, {
                            $splice: [
                              [dragIndex, 1],
                              [hoverIndex, 0, cards[dragIndex] as Item],
                            ],
                          });
                          setCards(newOrder);
                          setFieldValue(
                            `[${currentErfuellungsOrtIndex}].images`,
                            newOrder.map((a: any) => a.text)
                          );
                        }}
                      />
                    ))}
                  </div>
                </DndProvider>
              </View>
            ) : (
              <Text className="p-3">Keine Bilder vorhanden!</Text>
            )}
        </Form>
      )}
    </Formik>
  );
}
