import { View, Text } from "react-native";
import { selectWebUser, WebUser } from "../../../store/web-slices/userSlice.web";
import { useSelector } from "react-redux";
import { ResponsivePie } from "@nivo/pie";
import { classNames, getDescriptionForCompanyState, getDescriptionForOrdersState, getDescriptionForRole } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { isNullableType } from "graphql";
import { CompanyState, OrderState } from "../../../__generated__/graphql-enum-maps";
import { useGetUserDashboardDataQuery, useGetEmployeesQuery, useGetEmployeeStatisticInfoLazyQuery, GetEmployeeStatisticInfoQueryResult, GetEmployeeStatisticInfoQuery, GetEmployeesQuery, Order } from "../../../__generated__/graphql-types";

export default function AdminDashboard() {
  let { currentWebUser } = useSelector(selectWebUser);
  let { data, loading, error } = useGetUserDashboardDataQuery();
  let navigate = useNavigate();
  let employees = useGetEmployeesQuery();
  const [selectedEmployee, setSelectedEmployee] = useState<GetEmployeesQuery["getEmployees"][0] | null>(null);
  const [selectedStatisticDate, setSelectedStatisticDate] = useState<{ month: number, year: number } | null>(null);

  const [employeeStatistics, setEmployeeStatistics] = useState<GetEmployeeStatisticInfoQuery["getEmployeeStatisticInfo"] | null>(null);
  const [statisticMode, setStatisticMode] = useState<"contracts" | "orders" | null>(null);

  const [getEmployeeStatisticInfo, { loading: employeesLoading }] = useGetEmployeeStatisticInfoLazyQuery();

  const stats = [
    {
      id: 1, name: 'Verträge', stat: data?.getUserDashboardData?.companyDashboardChartData?.reduce((sum, current) => sum + current.count, 0), href: '/client/contracts', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(236, 72, 153)" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
      </svg>
    },
    {
      id: 2, name: 'Angebote', stat: data?.getUserDashboardData?.couponsCount, href: '', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(236, 72, 153)" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
      </svg>
    },
    {
      id: 3, name: 'Umsatz - bezahlt', stat: data?.getUserDashboardData?.payedSalesVolume + ' €', href: '/client/orders/all-orders', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(236, 72, 153)" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
    },
    {
      id: 4, name: 'Umsatz - nicht bezahlt', stat: data?.getUserDashboardData?.notPayedSalesVolume + ' €', href: '/client/orders/all-orders', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(236, 72, 153)" className="w-7 h-7">
        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </svg>
    },
  ];

  if (loading) {
    return <Text>Wird geladen...</Text>
  }

  const getCompanyChartColors = (states: CompanyState[]) => {
    let colors = [];
    for (let state of states) {
      if (state === CompanyState.ACTIVE) {
        colors.push("rgb(15, 118, 110)");
      }
      if (state === CompanyState.APPROVED) {
        colors.push("rgb(77, 124, 15)");
      }
      if (state === CompanyState.DRAFT) {
        colors.push("rgb(219, 234, 254)");
      }
      if (state === CompanyState.INACTIVE) {
        colors.push("rgb(255, 237, 213)");
      }
      if (state === CompanyState.PRINTED_SENDED) {
        colors.push("#d946ef");
      }
      if (state === CompanyState.IN_REVIEW) {
        colors.push("rgb(254, 249, 195)");
      }
      if (state === CompanyState.REJECTED) {
        colors.push("rgb(185, 28, 28)");
      }
      if (state === CompanyState.WAIT_CUSTOMER_REACTION) {
        colors.push("rgb(55, 65, 81)");
      }
    }
    return colors;
  }

  const getOrdersChartColors = (states: OrderState[]) => {
    let colors = [];
    for (let state of states) {
      if (state === OrderState.PAYED_NOT_PRINTED) {
        colors.push("rgb(77, 124, 15)");
      }
      if (state === OrderState.PAYED_SHIPPED) {
        colors.push("rgb(15, 118, 110)");
      }
      if (state === OrderState.DRAFT) {
        colors.push("rgb(219, 234, 254)");
      }
      if (state === OrderState.NOT_PAYED) {
        colors.push("rgb(185, 28, 28)");
      }
      if (state === OrderState.IN_REVIEW) {
        colors.push("rgb(254, 249, 195)");
      }
      if (state === OrderState.REJECTED) {
        colors.push("rgb(185, 28, 28)");
      }
      if (state === OrderState.WAIT_CUSTOMER_REACTION) {
        colors.push("rgb(55, 65, 81)");
      }
    }
    return colors;
  }

  const companiesDataChart = data?.getUserDashboardData?.companyDashboardChartData?.map((item, index) => ({
    "id": getDescriptionForCompanyState(item.state),
    "label": getDescriptionForCompanyState(item.state) + " (" + item.count + ")",
    "value": item.count,
    "state": item.state
  }));

  const ordersDataChart = data?.getUserDashboardData?.ordersDashboardChartData?.map((item, index) => ({
    "id": getDescriptionForOrdersState(item.state),
    "label": getDescriptionForOrdersState(item.state) + " (" + item.count + ")",
    "value": item.count,
    "state": item.state
  }));


  const getOrderStatisticSummaryItem = (header: string, orderState?: OrderState | null) => {
    const filterPredicate = (order: { _id: string, state?: OrderState | null, summary?: { netAmount?: number | null, vat?: number | null, grossAmount?: number | null } | null }) => {
      return orderState ? order.state === orderState : true;
    };
    return <div className="p-3">
      <div className="font-semibold text-sm text-slate-900">{header}</div>
      <div className="text-sm text-slate-600">{employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.orders?.filter(filterPredicate).length || 0} Bestellungen</div>
      <div className="flex flex-row ">
        <div className="text-sm text-slate-600">{employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.orders?.filter(filterPredicate).reduce((sum, current) => sum + (current.summary?.grossAmount || 0), 0).toFixed(2) || 0}€ Brutto</div>
        <div className="text-sm text-slate-600 italic ml-1">({employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.orders?.filter(filterPredicate).reduce((sum, current) => sum + (current.summary?.netAmount || 0), 0).toFixed(2) || 0}€ Netto)</div>
      </div>
    </div>;
  }

  const getContractsLink = (companyState?: CompanyState | null): string | undefined => {
    const filterPredicate = (company: { _id: string, state: CompanyState, kundennummer?: string | null }) => {
      return companyState ? company.state === companyState : true;
    };
    return `/client/contracts?filterLabels=${selectedEmployee?.firstName + " " + selectedEmployee?.lastName},${selectedStatisticDate?.month}/${selectedStatisticDate?.year}&customerNumbers=${employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.contracts?.filter(filterPredicate)?.map(a => a.kundennummer)}&headerText=Verträge`
  }

  const getOrdersLink = (orderState?: OrderState | null): string | undefined => {
    const filterPredicate = (order: { _id: string, state?: OrderState | null, summary?: { netAmount?: number | null, vat?: number | null, grossAmount?: number | null } | null }) => {
      return orderState ? order.state === orderState : true;
    };
    return `/client/orders/all-orders?filterLabels=${selectedEmployee?.firstName + " " + selectedEmployee?.lastName},${selectedStatisticDate?.month}/${selectedStatisticDate?.year}&orderNumbers=${employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.orders?.filter(filterPredicate)?.map(a => a.orderNumber)}&headerText=Bestellungen`
  }

  const createContractStatisticItem = ({ label, icon, companyState, backgroundColorClass }: { label: string, icon: React.ReactElement, companyState?: CompanyState | null, backgroundColorClass: string }): React.ReactElement => {
    const filterPredicate = (company: { _id: string, state: CompanyState, kundennummer?: string | null }) => {
      return companyState ? company.state === companyState : true;
    };

    const count = employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.contracts?.filter(filterPredicate)?.length || 0;

    return <div className={classNames("flex flex-row bg-gray-50 items-center rounded-full", count ? "hover:bg-gray-100 cursor-pointer" : "")}
      onClick={() => {
        if (count) {
          window.open(getContractsLink(companyState));
        }
      }}>
      <div className={`rounded-full p-3 w-12 ml-1.5 ${backgroundColorClass}`}>
        {icon}
      </div>
      <div className="p-2">
        <div className="font-semibold text-sm text-slate-900 ">{label}</div>
        <div className="text-sm text-slate-600">{count}</div>
      </div>
      {count > 0 && (
        <div className="ml-auto mr-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(107 114 128)" className="w-5 h-5">
            <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </div>
      )}
    </div>;
  }

  const createOrderStatisticItem = ({ label, icon, orderState, backgroundColorClass }: { label: string, icon: React.ReactElement, orderState?: OrderState | null, backgroundColorClass: string }): React.ReactElement => {
    const filterPredicate = (order: { _id: string, state?: OrderState | null, summary?: { netAmount?: number | null, vat?: number | null, grossAmount?: number | null } | null }) => {
      return orderState ? order.state === orderState : true;
    };

    const count = employeeStatistics?.find(a => a.month === selectedStatisticDate?.month && a.year === selectedStatisticDate?.year)?.orders?.filter(filterPredicate)?.length || 0;

    return <div className={classNames("flex flex-row bg-gray-50 items-center rounded-full", count ? "hover:bg-gray-100 cursor-pointer" : "")}
      onClick={() => {
        if (count) {
          window.open(getOrdersLink(orderState))
        }
      }}>
      <div className={`rounded-full p-3 w-12 ml-2 ${backgroundColorClass}`}>
        {icon}
      </div>
      {getOrderStatisticSummaryItem(label, orderState)}
      {count > 0 && (
        <div className="ml-auto mr-4 ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(107 114 128)" className="w-5 h-5">
            <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
          </svg>
        </div>
      )}
    </div>
  }


  return (
    <>
      <View className="bg-white shadow-md shadow-gray-300 p-6 mx-3 ">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Hallo, {currentWebUser?.firstName}!
        </h2>
        <Text className="text-xs muted text-gray-400 leading-6">
          MA-Nummer: {currentWebUser?.employeeNumber}
        </Text>
        <Text className="text-xs muted text-gray-400 leading-6">
          Rolle: {currentWebUser?.role}
        </Text>
      </View>

      <div className="mx-3">

        <div>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-md shadow-gray-300 overflow-hidden"
              >
                <dt>
                  <div className="absolute bg-pink-100 rounded-md p-3">
                    {item.icon}
                  </div>
                  <p className="ml-16 text-sm font-normal text-gray-500 truncate">{item.name}</p>
                </dt>
                <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                  <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                  {item.href && (
                    <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                      <div className="text-sm">
                        <a href={item.href} className="font-medium text-indigo-600 hover:text-indigo-500">
                          {' '}
                          Zur Übersicht<span className="sr-only"> {item.name} stats</span>
                        </a>
                      </div>
                    </div>
                  )}
                </dd>
              </div>
            ))}

            <div style={{ height: 450 }} className="col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-md shadow-gray-300 overflow-hidden">
              <span className="text-2xl font-semibold text-gray-900">Übersicht Verträge</span>
              {data?.getUserDashboardData?.companyDashboardChartData?.length && (
                <ResponsivePie
                  colors={getCompanyChartColors(data?.getUserDashboardData.companyDashboardChartData.map(a => a.state))}
                  colorBy="index"
                  data={companiesDataChart || []}
                  theme={{ legends: { text: { fontSize: 13 } } }}
                  margin={{ top: 60, bottom: 60, left: 160 }}
                  innerRadius={0.5}
                  padAngle={4}
                  onMouseEnter={(_datum, event) => {
                    event.currentTarget.style.cursor = "pointer";
                  }}
                  onClick={(datum) => {
                    console.log(datum)
                    navigate(`/client/contracts?state=${(datum as any).data.state}`);
                  }}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  borderColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        0.2
                      ]
                    ]
                  }}
                  arcLabelsComponent={({ datum, label, style }) => (
                    <span
                      style={{
                        fontSize: 30,
                        fontWeight: 800,
                      }}
                    >
                      {label}
                    </span>
                  )}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        2
                      ]
                    ]
                  }}
                  legends={[
                    {
                      anchor: 'left',
                      direction: 'column',
                      itemsSpacing: 10,
                      itemWidth: 150,
                      itemHeight: 28,
                      translateX: -100,
                      itemTextColor: 'rgb(71, 85, 105)',
                      itemDirection: 'left-to-right',
                      symbolSize: 24,
                      onMouseEnter: (datum, event) => {
                        event.currentTarget.style.cursor = "pointer";
                      },
                      onClick: (datum, event) => {
                        navigate(`/client/contracts?state=${(datum as any).data.data.state}`);
                      },
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: 'black',
                          },
                        }
                      ]
                    }
                  ]}
                />
              )}
            </div>
            <div style={{ height: 450 }} className="col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-md shadow-gray-300 overflow-hidden">
              <span className="text-2xl font-semibold text-gray-900">Übersicht Bestellungen</span>
              {data?.getUserDashboardData?.ordersDashboardChartData?.length && (
                <ResponsivePie
                  colors={getOrdersChartColors(data?.getUserDashboardData?.ordersDashboardChartData.map(a => a.state))}
                  colorBy="index"
                  data={ordersDataChart || []}
                  theme={{ legends: { text: { fontSize: 13 } } }}
                  margin={{ top: 60, bottom: 60, left: 160 }}
                  innerRadius={0.5}
                  padAngle={4}
                  onMouseEnter={(_datum, event) => {
                    event.currentTarget.style.cursor = "pointer";
                  }}
                  onClick={(datum) => {
                    console.log(datum)
                    navigate(`/client/orders/all-orders?state=${(datum as any).data.state}`);
                  }}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  arcLabelsComponent={({ datum, label, style }) => (
                    <span
                      style={{
                        fontSize: 30,
                        fontWeight: 800,
                      }}
                    >
                      {label}
                    </span>
                  )}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={2}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'darker',
                        2
                      ]
                    ]
                  }}
                  legends={[
                    {
                      anchor: 'left',
                      direction: 'column',
                      itemsSpacing: 10,
                      itemWidth: 150,
                      translateX: -100,
                      itemHeight: 28,
                      itemTextColor: 'rgb(71, 85, 105)',
                      itemDirection: 'left-to-right',
                      symbolSize: 24,
                      onMouseEnter: (datum, event) => {
                        event.currentTarget.style.cursor = "pointer";
                      },
                      onClick: (datum, event) => {
                        navigate(`/client/orders/all-orders?state=${(datum as any).data.data.state}`);
                      },
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: 'black',
                          },
                        }
                      ]
                    }
                  ]}
                />
              )}
            </div>

            {currentWebUser?.role === "ADMIN" && (
              <div className="col-span-2 relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-md shadow-gray-300 overflow-hidden">
                <div className="flex flex-row justify-between">
                  <span className="mt-2 text-2xl font-semibold text-gray-900">Statistik</span>
                  <div className="flex flex-row gap-1 mt-1">
                    <Autocomplete
                      disablePortal={false}
                      options={employees?.data?.getEmployees || []}
                      size="small"
                      noOptionsText="Keine Mitarbeiter gefunden"
                      loadingText={"Mitarbeiter werden geladen..."}
                      loading={employeesLoading}

                      sx={{ width: 240 }}
                      value={selectedEmployee}
                      onChange={async (event, employee) => {
                        setSelectedStatisticDate(null);
                        setSelectedEmployee(employee);
                        setStatisticMode(null);
                        setEmployeeStatistics(null);
                        let statistics = await getEmployeeStatisticInfo({
                          variables: {
                            employeeId: employee?._id
                          }
                        });
                        setEmployeeStatistics(statistics?.data?.getEmployeeStatisticInfo);
                      }}
                      getOptionLabel={(employee) => `${employee.firstName} ${employee.lastName} (${getDescriptionForRole(employee.role)})`}
                      renderInput={(params) => <TextField {...params} label="Mitarbeiter auswählen" />}
                    />

                    <Autocomplete
                      disablePortal={false}
                      disabled={!selectedEmployee}
                      noOptionsText="Keine Daten gefunden"
                      loadingText={"Daten werden geladen..."}
                      disableClearable
                      value={selectedStatisticDate}
                      onChange={async (event, date) => {
                        if (date) {
                          setSelectedStatisticDate(date);
                        } else {
                          setStatisticMode(null);
                        }
                      }}
                      loading={employeesLoading}
                      options={employeeStatistics || []}
                      size="small"
                      sx={{ width: 210 }}
                      getOptionLabel={(employeeStatistic) => `${employeeStatistic.month + "/" + employeeStatistic.year}`}
                      renderInput={(params) => <TextField {...params} label="Monat/Jahr" />}
                    />

                    <ToggleButtonGroup
                      color="primary"
                      disabled={!selectedEmployee || !selectedStatisticDate}
                      size="small"
                      exclusive
                      value={statisticMode}
                      onChange={(event, value) => {
                        setStatisticMode(value);
                      }}
                      aria-label="Platform"
                    >
                      <ToggleButton value="contracts">Verträge</ToggleButton>
                      <ToggleButton value="orders">Bestellungen</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>

                {(!selectedEmployee || !selectedStatisticDate || !statisticMode) && (
                  <div className="flex flex-col items-center my-16 ">
                    <div className="font-semibold text-black">Analyse Verträge/Bestellungen</div>
                    <div className=" text-gray-400 font-light">Wähle oben den Mitarbeiter, Datum und die gewünschte Ansicht aus.</div>
                  </div>
                )}

                {statisticMode === "contracts" && (
                  <div className="grid grid-cols-2 gap-3 mt-4">

                    {createContractStatisticItem({
                      label: 'Insgesamt',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(236, 72, 153)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                      </svg>,
                      companyState: null,
                      backgroundColorClass: "bg-pink-100"
                    })}

                    {createContractStatisticItem({
                      label: 'Entwürfe',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(29, 78, 216)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>,
                      companyState: CompanyState.DRAFT,
                      backgroundColorClass: "bg-blue-100"
                    })}

                    {createContractStatisticItem({
                      label: 'Kundenreaktion',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(55, 65, 81)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>,
                      companyState: CompanyState.WAIT_CUSTOMER_REACTION,
                      backgroundColorClass: "bg-gray-200"
                    })}

                    {createContractStatisticItem({
                      label: 'In Prüfung',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(161, 98, 7)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path strokeLinecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>,
                      companyState: CompanyState.IN_REVIEW,
                      backgroundColorClass: "bg-yellow-100"
                    })}


                    {createContractStatisticItem({
                      label: 'Genehmigt',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(77, 124, 15)" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                      </svg>,
                      companyState: CompanyState.APPROVED,
                      backgroundColorClass: "bg-lime-100"
                    })}


                    {createContractStatisticItem({
                      label: 'Abgelehnt',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(185, 28, 28)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>,
                      companyState: CompanyState.REJECTED,
                      backgroundColorClass: "bg-red-100"
                    })}

                    {createContractStatisticItem({
                      label: 'Inaktiv',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(185, 28, 28)" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                      </svg>,
                      companyState: CompanyState.INACTIVE,
                      backgroundColorClass: "bg-orange-100"
                    })}

                    {createContractStatisticItem({
                      label: 'Aktiv',
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(15, 118, 110)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.746 3.746 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 011.043-3.296 3.746 3.746 0 011.043-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                      </svg>,
                      companyState: CompanyState.ACTIVE,
                      backgroundColorClass: "bg-teal-100"
                    })}

                  </div>
                )}

                {statisticMode === "orders" && (
                  <div className="grid grid-cols-2 gap-3 mt-4">

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(236, 72, 153)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                      </svg>,
                      label: "Insgesamt",
                      orderState: null,
                      backgroundColorClass: "bg-pink-100"
                    })}

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(29, 78, 216)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>,
                      label: "Entwürfe",
                      orderState: OrderState.DRAFT,
                      backgroundColorClass: "bg-blue-100"
                    })}

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(55, 65, 81)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>,
                      label: "Kundenreaktion",
                      orderState: OrderState.WAIT_CUSTOMER_REACTION,
                      backgroundColorClass: "bg-gray-200"
                    })}

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(161, 98, 7)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                        <path strokeLinecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>,
                      label: "In Prüfung",
                      orderState: OrderState.IN_REVIEW,
                      backgroundColorClass: "bg-yellow-200"
                    })}


                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="rgb(185, 28, 28)" className="w-6 h-6">
                        <path strokeLinecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>,
                      label: "Abgelehnt",
                      orderState: OrderState.REJECTED,
                      backgroundColorClass: "bg-red-200"
                    })}


                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(185, 28, 28)" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                      </svg>,
                      label: "Nicht bezahlt",
                      orderState: OrderState.NOT_PAYED,
                      backgroundColorClass: "bg-orange-200"
                    })}

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(129, 140, 248)" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 100 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>,
                      label: "Bezahlt - nicht gedruckt",
                      orderState: OrderState.PAYED_NOT_PRINTED,
                      backgroundColorClass: "bg-indigo-100"
                    })}

                    {createOrderStatisticItem({
                      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(15, 118, 110)" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                      </svg>,
                      label: "Bezahlt & Verschickt",
                      orderState: OrderState.PAYED_SHIPPED,
                      backgroundColorClass: "bg-teal-100"
                    })}
                  </div>
                )}
              </div>
            )}
          </dl>
        </div >
      </div >
    </>
  );
}

