import { Reducer, combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { AppUser, appUserSliceNativeReducer } from './native-slices/userSlice.native';
import { WebUser, webUserSliceNativeReducer } from './web-slices/userSlice.web';

// https://blog.logrocket.com/persist-state-redux-persist-redux-toolkit-react
// https://github.com/rt2zz/redux-persist#storage-engines

const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2
}

let reducers = {
    webUser: webUserSliceNativeReducer,
    appUser: appUserSliceNativeReducer
};

if (!reducers || !Object.keys(reducers).length) {
    console.error('Es wurden keine Reducer für den Store geladen!');
}

const rootReducer = combineReducers(reducers)

const persistedReducer = persistReducer(persistConfig, <any>rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true, // process.env.NODE_ENV !== 'production', 
    middleware: [thunk]
})

export const persistor = persistStore(store)

export type IRootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch
