export enum TrueFalseEnum {
  YES = "Ja",
  NO = "Nein"
}

export enum Role {
  ADMIN = 'ADMIN',
  DISTRIBUTION = 'DISTRIBUTION'
}

export enum CompanyState {
  DRAFT = "DRAFT", // Entwurf
  WAIT_CUSTOMER_REACTION = "WAIT_CUSTOMER_REACTION", // Warte auf Kundenreaktion
  IN_REVIEW = "IN_REVIEW", // In Überprüfung
  APPROVED = "APPROVED", // Genehmigt
  PRINTED_SENDED = "PRINTED_SENDED", // Gedruckt & Verschickt
  REJECTED = "REJECTED", // Abgelehnt
  INACTIVE = "INACTIVE", // Inaktiv
  ACTIVE = "ACTIVE", // Aktiv
}

export enum SignRequestType {
  CONTRACT = "CONTRACT",
  CONTRACT_ON_SIDE = "CONTRACT_ON_SIDE",
  ORDER = "ORDER",
  ORDER_ON_SIDE = "ORDER_ON_SIDE"
}

export enum OrderState {
  DRAFT = "DRAFT", // Entwurf
  WAIT_CUSTOMER_REACTION = "WAIT_CUSTOMER_REACTION", // Warte auf Kundenreaktion
  IN_REVIEW = "IN_REVIEW", // In Prüfung
  REJECTED = "REJECTED", // Abgelehnt
  NOT_PAYED = "NOT_PAYED", // Nicht bezahlt
  PAYED_NOT_PRINTED = "PAYED_NOT_PRINTED", // Bezahlt - nicht gedruckt
  PAYED_SHIPPED = "PAYED_SHIPPED", // Bezahlt & Verschickt 
}

export enum CanRedeemReason {
  HOLIDAY = "HOLIDAY",
  MISSING_SUBSCRIPTION = "MISSING_SUBSCRIPTION",
  NOT_LOGGED_IN = "NOT_LOGGED_IN",
  ALREADY_REDEEMED = "ALREADY_REDEEMED",
}

export enum StripeSubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID"
}

export enum CompanyFilterSortOrder {
  NEWEST = 'NEWEST',
  ALPHABETICAL_ASC = 'ALPHABETICAL_ASC',
  ALPHABETICAL_DESC = 'ALPHABETICAL_DESC',
  DISTANCE = 'DISTANCE',
  MAXSAVINGS = 'MAXSAVINGS'
}

export enum OrderDurationType {
  DAYS = "DAYS",
  MONTHS = "MONTHS",
}