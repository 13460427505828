import { AntDesign } from "@expo/vector-icons";
import { Text, View } from "react-native";
import CustomText from "./CustomText";
import { useEffect, useState } from "react";

export interface StepInfo {
  index: number;
  name: string;
  status: string;
}

export const initSteps = [
  {
    index: 1,
    name: "Betreiber",
    status: "upcoming",
  },
  {
    index: 2,
    name: "Kommunikationsanschrift",
    status: "upcoming",
  },
  {
    index: 3,
    name: "Angebotseinstellungen",
    status: "upcoming",
  },
  {
    index: 4,
    name: "Erfüllungsort",
    status: "upcoming",
  },
  {
    index: 5,
    name: "Berater und Kundendaten",
    status: "upcoming",
  },
  {
    index: 6,
    name: "Öffnungszeiten",
    status: "upcoming",
  },
  {
    index: 7,
    name: "Ausstattungsmerkmale",
    status: "upcoming",
  },
  {
    index: 8,
    name: "Vertragsvorschau",
    status: "upcoming",
  },
  {
    index: 9,
    name: "Zusammenfassung",
    status: "upcoming",
  },
  {
    index: 10,
    name: "Logo / Bilder",
    status: "upcoming",
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Stepper(props: {
  step: number | undefined;
  onStepClick: (step: StepInfo | undefined) => void;
  allowStepManual: boolean;
}) {
  const [currentStep, setCurrentStep] = useState(Number(props.step));
  const [steps, setSteps] = useState<StepInfo[]>(initSteps);

  useEffect(() => {
    if (!props.step || props.step > steps.length || !currentStep) {
      setCurrentStep(1);
      return;
    }
    let currStep = steps.find((step) => step.index === props.step);
    if (currStep) {
      currStep.status = "current";
    }
    let previousSteps = steps.filter((step) => step.index < props.step);
    if (previousSteps && previousSteps.length) {
      previousSteps.forEach((step) => (step.status = "complete"));
    }
    let futureSteps = steps.filter((step) => step.index > props.step);
    if (futureSteps && futureSteps.length) {
      futureSteps.forEach((step) => (step.status = "upcoming"));
    }
    setSteps([...steps]);
    setCurrentStep(props.step);
  }, [props.step]);

  return (
    <View className="p-4 bg-white rounded-xl -mr-10 border border-gray-100 ">
      <CustomText className="text-sm mb-2">
        Vertrag bearbeiten <Text>({currentStep}/{steps.length})</Text>
      </CustomText>

      <nav>
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? "pb-3" : "",
                "relative"
              )}
            >
              {step.status === "complete" ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-pink-500"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    onClick={() => {
                      props.onStepClick && props.onStepClick(step);
                      setCurrentStep(step.index)
                    }}
                    className="relative flex items-start group cursor-pointer"
                  >
                    <span className="h-9 flex items-center">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-pink-500 rounded-full group-hover:bg-pink-600">
                        <AntDesign name="check" size={24} color="white" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase mt-2.5">
                        {step.name}
                      </span>
                    </span>
                  </a>
                </>
              ) : step.status === "current" ? (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    onClick={() => {
                      if (props.allowStepManual) {
                        props.onStepClick && props.onStepClick(step);
                        setCurrentStep(step.index)
                      }
                    }}
                    className={classNames("relative flex items-start group", props.allowStepManual ? "cursor-pointer" : "cursor-not-allowed")}
                    aria-current="step"
                  >
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-pink-500 rounded-full">
                        <span className="h-2.5 w-2.5 bg-pink-500 rounded-full" />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-sm  tracking-wide uppercase font-bold text-pink-500  mt-2.5">
                        {step.name}
                      </span>
                    </span>
                  </a>
                </>
              ) : (
                <>
                  {stepIdx !== steps.length - 1 ? (
                    <div
                      className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300"
                      aria-hidden="true"
                    />
                  ) : null}
                  <a
                    onClick={() => {
                      if (props.allowStepManual) {
                        props.onStepClick && props.onStepClick(step);
                        setCurrentStep(step.index)
                      }
                    }}
                    className={classNames("relative flex items-start group", props.allowStepManual ? "cursor-pointer" : "cursor-not-allowed")}>
                    <span className="h-9 flex items-center" aria-hidden="true">
                      <span className="relative z-10 w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full">
                        <span className="h-2.5 w-2.5 bg-transparent rounded-full " />
                      </span>
                    </span>
                    <span className="ml-4 min-w-0 flex flex-col">
                      <span className="text-xs font-semibold tracking-wide uppercase text-gray-500  mt-2.5">
                        {step.name}
                      </span>
                    </span>
                  </a>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </View>
  );
}
