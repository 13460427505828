import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import ClientTextInput from "../web-components/ClientTextInput";
import {
  AddOrderPaymentInput,
  Company,
  GetNotPayedOrdersQuery,
  useGetNotPayedOrdersQuery,
} from "../../../__generated__/graphql-types";
import { classNames, formatNumberToCurrency, toDate } from "../../../helpers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Text } from "react-native";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props}></Slide>;
});

export const AddOrderPaymentModal = NiceModal.create(
  (props: {  }) => {
    const modal = useModal();

    let schema = yup.object({
      order_id: yup.string(),
      purposeTransfer: yup.string().required(),
      paymentDate: yup.date().required(),
    });

    const [amount, setAmount] = useState<string | undefined>(undefined);

    let {
      data,
      error,
      loading: loadingNotPayedOrders,
    } = useGetNotPayedOrdersQuery();

    return (
      <Formik
        initialValues={{ paymentDate: new Date() } as AddOrderPaymentInput}
        validationSchema={schema}
        validateOnMount
        onSubmit={(erfuellungsort) => {
          console.log("NEW FORM -> ", erfuellungsort);
        }}
      >
        {({ isValid, values, errors, setFieldValue, touched }) => (
          <Dialog
            TransitionComponent={Transition}
            fullWidth
            open={modal.visible}
            onClose={() => {
              modal.resolve({ resolved: false });
              modal.hide();
            }}
            TransitionProps={{
              onExited: () => {
                modal.resolve({ resolved: false });
                modal.remove();
              },
            }}
          >
            <DialogTitle id="alert-dialog-slide-title">
              Zahlungseingang verbuchen (VR-Werdenfels)
            </DialogTitle>
            <DialogContent>
              {/* {JSON.stringify(values, null, "\t")}
              {JSON.stringify(errors, null, "\t")} */}
              <div className="ml-3 mt-5">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                  Datum Zahlungseingang
                </label>
                <Field name="paymentDate">
                  {({ field }: { field: any }) => (
                    <DatePicker
                      locale="de"
                      dateFormat="P"
                      autoComplete="off"
                      popperPlacement="top-start"
                      name="paymentDate"
                      className={classNames(
                        `block appearance-none w-full  bg-white border border-slate-300 px-4 py-2 pr-8 rounded-lg leading-tight focus:outline-none `
                      )}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      selected={toDate(values.paymentDate)}
                      onChange={(newDate) => {
                        setFieldValue("paymentDate", newDate);
                      }}
                      customInput={
                        <input
                          readOnly
                          contentEditable={false}
                          className={classNames(
                            `caret-transparent cursor-pointer block appearance-none w-full hover:border-pink-300 bg-white border border-slate-300 px-4 py-2 pr-8 leading-tight rounded-lg focus:outline-none`
                          )}
                        />
                      }
                    ></DatePicker>
                  )}
                </Field>
                {touched["paymentDate"] && errors["paymentDate"] ? (
                  <div
                    style={{ fontSize: 9 }}
                    className="block uppercase tracking-wide text-red-500 font-bold mt-1 ml-0.5"
                  >
                    Erforderlich
                  </div>
                ) : null}
              </div>

              <Form>
                <Field
                  component={ClientTextInput}
                  name="purposeTransfer"
                  label="Verwendungszweck (VR-Bank)"
                  type="text"
                  mode="text"
                ></Field>

                <div>
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 ml-3 mt-4 border-slate-300">
                    Bestellung (Verwendungszweck)
                  </label>
                  <Autocomplete
                    className="ml-3 cursor-pointer"
                    id="country-customized-option-demo"
                    options={data?.getNotPayedOrders || []}
                    size="small"
                    loading={loadingNotPayedOrders}
                    loadingText="Bestellungen werden geladen..."
                    noOptionsText="Bestellung nicht gefunden."
                    disablePortal={false}
                    onChange={(event, selectedOrder) => {
                      console.log("selectedOrder ", selectedOrder);
                      if (selectedOrder) {
                        setFieldValue("order_id", selectedOrder._id);
                        setAmount(
                          formatNumberToCurrency(
                            selectedOrder?.summary?.grossAmount
                          )
                        );
                      } else {
                        setFieldValue("order_id", "");
                        setFieldValue("amount", "");
                        setAmount(undefined);
                      }
                    }}
                    getOptionLabel={(
                      order: GetNotPayedOrdersQuery["getNotPayedOrders"][0]
                    ) =>
                      `${order.orderNumber} - ${formatNumberToCurrency(
                        order.summary?.grossAmount
                      )}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Bestellung suchen..." />
                    )}
                  />
                </div>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  modal.resolve({ resolved: false });
                  modal.hide();
                }}
                color="error"
              >
                Abbrechen
              </Button>
              <Button
                onClick={async () => {
                  if (!isValid) {
                    return;
                  }
                  modal.resolve({ resolved: true, values });
                  modal.hide();
                }}
                disabled={!isValid || !values.order_id}
                color="primary"
              >
                Zahlungseingang verbuchen (+ {amount}) 
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }
);
