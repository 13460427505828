import { Octicons, AntDesign } from "@expo/vector-icons";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { View, Text } from "react-native";
import {
  Company,
  CompanyOpeningHours,
  OpeningHoursDay,
} from "../../../../../__generated__/graphql-types";
import { textvorlage_2_extras } from "../../../../../constants";
import {
  classNames,
  sliceId,
  toDate,
  transformDate,
} from "../../../../../helpers";
import { createCouponElement } from "../../../../company/CompanyDetailScreen";
import ClientButton from "../../../web-components/ClientButton";
import { initSteps } from "../../../web-components/Stepper";
import ImageGallery from "react-image-gallery";
import { useNavigate } from "react-router-dom";

export default function ZusammenfassungForm(props: {
  form: Company;
  contuineAndSave: () => void;
  onBackClick: () => void;
}) {
  const navigate = useNavigate();
  const renderField = (label: string, value: string) => (
    <div className={classNames("border-t border-gray-200 py-5 px-4 sm:p-0")}>
      <dl className="sm:divide-y sm:divide-gray-200">
        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-semibold text-slate-800 uppercase">
            {label}
          </dt>
          <dd className="mt-1 text-sm text-gray-500 sm:mt-0 sm:col-span-2">
            {value}
          </dd>
        </div>
      </dl>
    </div>
  );

  const renderFields = (fields: any) => {
    return fields.map((field: any, index: any) => (
      <React.Fragment key={index}>
        {renderField(field.label, field.value || "", index != 0)}
      </React.Fragment>
    ));
  };

  const kundendaten = () => {
    const fields = [
      {
        label: "NEUKUNDE ODER BESTANDSKUNDE",
        value: props.form.kundentyp,
      },
    ];
    return renderFields(fields);
  };

  const betreiber = () => {
    const fields = [
      { label: "Firma", value: props.form.betreiberDetails?.firma },
      { label: "Strasse", value: props.form.betreiberDetails?.strasse },
      { label: "Ort", value: props.form.betreiberDetails?.ort },
      {
        label: "Postleitzahl",
        value: props.form.betreiberDetails?.postleitzahl,
      },
      { label: "Land", value: props.form.betreiberDetails?.land },
      { label: "Telefon", value: props.form.betreiberDetails?.telefon },
      { label: "Mobil", value: props.form.betreiberDetails?.mobil },
      { label: "E-Mail", value: props.form.betreiberDetails?.email },
    ];
    return renderFields(fields);
  };

  const betreiber_vertretung = () => {
    const fields = [
      {
        label: "Position",
        value: props.form.betreiberDetails?.vertretung_position,
      },
      {
        label: "Anrede",
        value: props.form.betreiberDetails?.vertretung_anrede,
      },
      {
        label: "Vorname",
        value: props.form.betreiberDetails?.vertretung_vorname,
      },
      {
        label: "Nachname",
        value: props.form.betreiberDetails?.vertretung_nachname,
      },
    ];
    return renderFields(fields);
  };

  const erfuellungsort = () => {
    let fields = [];
    for (let erfuellungsOrt of props.form.performancePlaces) {
      fields.push({
        label: "Erfüllungsort",
        value: erfuellungsOrt.erfuellungsOrt,
      });
      fields.push({
        label: "Strasse",
        value: erfuellungsOrt.strasse,
      });
      fields.push({
        label: "Postleitzahl",
        value: erfuellungsOrt.postleitzahl,
      });
      fields.push({
        label: "Ort",
        value: erfuellungsOrt.ort,
      });
      fields.push({
        label: "Land",
        value: erfuellungsOrt.land,
      });
    }
    return renderFields(fields);
  };

  const erfuellungsort_kontakt = () => {
    const fields = [
      {
        label: "Telefon",
        value: props.form.performancePlaces?.telefon,
      },
      {
        label: "Mobil",
        value: props.form.performancePlaces?.mobil,
      },
      {
        label: "E-Mail",
        value: props.form.performancePlaces?.email,
      },
    ];
    return renderFields(fields);
  };

  const erfuellungsort_links = () => {
    const fields = [
      {
        label: "Website",
        value: props.form.erfuellungsOrtDetails?.website,
      },
      {
        label: "Preisliste / Speisekarte",
        value: props.form.erfuellungsOrtDetails?.speisekarte,
      },
      {
        label: "Instagram",
        value: props.form.erfuellungsOrtDetails?.instagram,
      },
      {
        label: "Facebook",
        value: props.form.erfuellungsOrtDetails?.facebook,
      },
      {
        label: "Tiktok",
        value: props.form.erfuellungsOrtDetails?.tiktok,
      },
    ];
    return renderFields(fields);
  };

  const kommunikationsanschrift_adresse = () => {
    const fields = [
      {
        label: "Strasse",
        value: props.form.kommunikationsanschriftDetails?.strasse,
      },
      {
        label: "Plz",
        value: props.form.kommunikationsanschriftDetails?.postleitzahl,
      },
      {
        label: "Ort",
        value: props.form.kommunikationsanschriftDetails?.ort,
      },
      {
        label: "Land",
        value: props.form.kommunikationsanschriftDetails?.land,
      },
    ];
    return renderFields(fields);
  };

  const kommunikationsanschrift_kontakt = () => {
    const fields = [
      {
        label: "Telefon",
        value: props.form.kommunikationsanschriftDetails?.telefon,
      },
      {
        label: "Mobil",
        value: props.form.kommunikationsanschriftDetails?.mobil,
      },
      {
        label: "E-Mail",
        value: props.form.kommunikationsanschriftDetails?.email,
      },
    ];
    return renderFields(fields);
  };

  const kommunikationsanschrift_ansprechpartner = () => {
    const fields = [
      {
        label: "Anrede",
        value: props.form.kommunikationsanschriftDetails?.anrede,
      },
      {
        label: "Vorname",
        value: props.form.kommunikationsanschriftDetails?.vorname,
      },
      {
        label: "Nachname",
        value: props.form.kommunikationsanschriftDetails?.nachname,
      },
    ];
    return renderFields(fields);
  };

  const angebotseinstellungen_coupon_1 = () => {
    const fields = [
      {
        label: "Kategorie",
        value:
          props.form.angebotseinstellungenDetails?.coupon_1?.category
            ?.description,
      },
      {
        label: "Max Wert 2:1 Einlösung",
        value: props.form.angebotseinstellungenDetails?.coupon_1?.maximumAmount,
      },
      {
        label: "Aktiv?",
        value: props.form.angebotseinstellungenDetails?.coupon_1?.isActive
          ? "JA"
          : "NEIN",
      },
    ];
    return renderFields(fields);
  };

  const angebotseinstellungen_coupon_2 = () => {
    const fields = [
      {
        label: "Kategorie",
        value:
          props.form.angebotseinstellungenDetails?.coupon_2?.category
            ?.description,
      },
      {
        label: "Max Wert 2:1 Einlösung",
        value: props.form.angebotseinstellungenDetails?.coupon_2?.maximumAmount,
      },
      {
        label: "Aktiv?",
        value: props.form.angebotseinstellungenDetails?.coupon_2?.isActive
          ? "JA"
          : "NEIN",
      },
    ];
    return renderFields(fields);
  };

  const beraterUndKundendaten = () => {
    const fields = [
      {
        label: "Name des Mitarbeiters",
        value: props.form.beraterKundendatenDetails?.mitarbeiter_name,
      },
      {
        label: "Mitarbeiter-Nr.",
        value: sliceId(
          props.form.beraterKundendatenDetails?.mitarbeiter_nummer
        ),
      },
      {
        label: "Telefonist",
        value: props.form.beraterKundendatenDetails?.mitarbeiter_telefonist,
      },
      {
        label: "Ort der Vertragsschliessung",
        value: props.form.beraterKundendatenDetails?.ort_vertragsschliessung,
      },
    ];
    return renderFields(fields);
  };

  const beraterUndKundendaten_gesprochen = () => {
    const fields = [
      {
        label: "Gesprochen mit (Anrede)",
        value: props.form.beraterKundendatenDetails?.gesprochen_mit_anrede,
      },
      {
        label: "Gesprochen mit (Vorname)",
        value: props.form.beraterKundendatenDetails?.gesprochen_mit_vorname,
      },
      {
        label: "Gesprochen mit (Nachname)",
        value: props.form.beraterKundendatenDetails?.gesprochen_mit_nachname,
      },
    ];
    return renderFields(fields);
  };

  const beraterUndKundendaten_unterzeichnet = () => {
    const fields = [
      {
        label: "Unterzeichnet von (Position)",
        value: props.form.beraterKundendatenDetails?.unterzeichnet_position,
      },
      {
        label: "Unterzeichnet von (Anrede)",
        value: props.form.beraterKundendatenDetails?.unterzeichnet_anrede,
      },
      {
        label: "Unterzeichnet von (Vorname)",
        value: props.form.beraterKundendatenDetails?.unterzeichnet_vorname,
      },
      {
        label: "Unterzeichnet von (Nachname)",
        value: props.form.beraterKundendatenDetails?.unterzeichnet_nachname,
      },
    ];
    return renderFields(fields);
  };

  const getOpeningHourText = (day: keyof CompanyOpeningHours) => {
    let item: OpeningHoursDay = props.form.openingHours[day];
    return (
      <View className="flex flex-row items-stretch ">
        <Text className="basis-1/3 font-semibold text-slate-800 uppercase">
          {day}
        </Text>
        {item?.is_open && (
          <Text className="text-sm text-gray-500 font-light">
            {item?.start_time ? item?.start_time + " Uhr" : "k.A."} -{" "}
            {item?.end_time ? item?.end_time + " Uhr" : "k.A."}
          </Text>
        )}
        {!item?.is_open && (
          <Text className="text-sm text-red-600 font-semibold tracking-wider ">
            Geschlossen
          </Text>
        )}
      </View>
    );
  };

  const tabs = initSteps
    .map((step, index) => ({
      name: step.name,
      index,
      current: index === 0,
    }))
    .filter((a) => a.index !== 11 && a.index !== 12);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  function createHeader(text: string): React.ReactNode {
    return (
      <h3 className="text-lg leading-6 font-medium text-pink-500">{text}</h3>
    );
  }

  function createSubheader(text: string): React.ReactNode {
    return <p className="mt-1 max-w-2xl text-sm text-gray-500">{text}</p>;
  }
  return (
    <Formik
      initialValues={props.form || {}}
      validateOnMount
      onSubmit={() => {
        props.contuineAndSave();
      }}
    >
      {({ isValid, values, errors, setFieldValue }) => (
        <Form>
          <View className="flex flex-row ml-auto mb-5 justify-end">
            {/* {previousFormButton(props.onBackClick)} */}
            <ClientButton
              type="submit"
              onClick={() => {
                navigate(`/client/orders/newOrder?companyId=${props.form._id}`);
              }}
              disabled={!isValid}
              label="Bestellung +"
            ></ClientButton>
            <ClientButton
              type="submit"
              mode="secondary"
              onClick={() => {
                navigate(`/client/overview/${props.form._id}`);
              }}
              disabled={!isValid}
              label="Zur Übersicht"
            ></ClientButton>
          </View>

          <div className="mb-3">
            <nav
              className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 flex-row flex-wrap"
              aria-label="Tabs"
            >
              {tabs.map((tab, tabIdx) => (
                <a
                  key={tab.name}
                  onClick={() => setCurrentTabIndex(tabIdx)}
                  className={classNames(
                    tabIdx === currentTabIndex
                      ? "text-gray-900 font-bold"
                      : "text-gray-500 hover:text-gray-700  hover:bg-pink-50 hover:cursor-pointer ",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "min-w-15rem group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center focus:z-10"
                  )}
                >
                  <span
                    className={classNames(
                      tabIdx === currentTabIndex ? "font-bold" : ""
                    )}
                  >
                    <View className="flex flex-row  items-center">
                      <Text className="font-bold text-lg mr-1">
                        {tabIdx + 1}.
                      </Text>
                      <Text
                        className={classNames(
                          tabIdx === currentTabIndex ? "font-semibold " : ""
                        )}
                      >
                        {tab.name}
                      </Text>
                    </View>
                  </span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      tabIdx === currentTabIndex
                        ? "bg-pink-500"
                        : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </a>
              ))}
            </nav>
          </div>

          {/* 1. Kundendaten */}
          {currentTabIndex === 0 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Kundendaten")}
                {createSubheader("Informationen über den Kunden")}
              </div>

              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  {kundendaten()}
                </dl>
              </div>
            </div>
          )}

          {/* 2. Betreiber */}
          {currentTabIndex === 1 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Betreiber (1/2)")}
                {createSubheader("Informationen über den aktuellen Betreiber")}
              </div>
              {betreiber()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Betreiber (2/2)")}
                {createSubheader("Vertretungsberechtige Person")}
              </div>
              {betreiber_vertretung()}
            </div>
          )}

          {/* 3. Erfüllungsort */}
          {currentTabIndex === 2 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Erfüllungsort (1/3)")}
                {createSubheader("Informationen über den Erfüllungsort")}
              </div>
              {erfuellungsort()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Erfüllungsort (2/3)")}
                {createSubheader("Kontakt")}
              </div>

              {erfuellungsort_kontakt()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Erfüllungsort (3/3)")}
                {createSubheader(
                  "Links: Social Media, Website, Preisliste/Speisekarte, E-Mail"
                )}
              </div>
              {erfuellungsort_links()}
            </div>
          )}

          {/* 4. Kommunikationsanschrift */}
          {currentTabIndex === 3 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Kommunikationsanschrift (1/3)")}
                {createSubheader("Adresse")}
              </div>
              {kommunikationsanschrift_adresse()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Erfüllungsort (2/3)")}
                {createSubheader("Kontakt")}
              </div>
              {kommunikationsanschrift_kontakt()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Erfüllungsort (3/3)")}
                {createSubheader("Ansprechpartner")}
              </div>
              {kommunikationsanschrift_ansprechpartner()}
            </div>
          )}

          {/* 5. Angebotseinstellungen */}
          {currentTabIndex === 4 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Angebotseinstellungen")}
                {createSubheader("Kategorie")}
              </div>

              <div className="grid grid-cols-2 mr-3 mb-4">
                <div className="col p-8">
                  {angebotseinstellungen_coupon_1()}
                  {createCouponElement(props.form?.angebotseinstellungenDetails?.coupon_1, { buttonType: "def" })}
                </div>
                <div className="col p-8">
                  {angebotseinstellungen_coupon_2()}
                  {createCouponElement(props.form?.angebotseinstellungenDetails?.coupon_2, { buttonType: "slide" })}
                </div>
              </div>
            </div>
          )}

          {/* 6. Berater und Kundendaten */}
          {currentTabIndex === 5 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Berater und Kundendaten (1/3)")}
                {createSubheader("Informationen zum Berater")}
              </div>
              {beraterUndKundendaten()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Berater und Kundendaten (2/3)")}
                {createSubheader("Gesprochen mit")}
              </div>
              {beraterUndKundendaten_gesprochen()}
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Berater und Kundendaten (3/3)")}
                {createSubheader("Unterzeichnet von")}
              </div>
              {beraterUndKundendaten_unterzeichnet()}
            </div>
          )}

          {/* 7. Öffnungszeiten */}
          {currentTabIndex === 6 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Öffnungszeiten")}
                {createSubheader("Informationen zu den Öffnungszeiten")}
              </div>
              {renderField("Öffnungszeiten vorhanden?", props.form.openingHours?.showCustomOpHours ? "JA" : "NEIN")}

              {props.form.openingHours?.showCustomOpHours && (
                <>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="divide-y divide-gray-200"></dl>
                  </div>
                  <View className="py-5 px-6">
                    {getOpeningHourText("montag")}
                    {getOpeningHourText("dienstag")}
                    {getOpeningHourText("mittwoch")}
                    {getOpeningHourText("donnerstag")}
                    {getOpeningHourText("freitag")}
                    {getOpeningHourText("samstag")}
                    {getOpeningHourText("sonntag")}
                  </View>
                </>
              )}

              {!props.form.openingHours?.showCustomOpHours &&
                renderField(
                  "Link zu Öffnungszeiten",
                  props.form.openingHours?.linkOpeningHours
                )}
            </div>
          )}

          {/* 8. Ausstattungsmerkmale 2 */}
          {currentTabIndex === 8 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Ausstattungsmerkmale")}
                {createSubheader("Extras")}
              </div>

              <View className="grid grid-cols-5 gap-1">
                {props.form.textvorlageTeil2Details &&
                  Object.keys(props.form.textvorlageTeil2Details)
                    .filter((d, i) => i != 0)
                    .map((extra) => {
                      let item = textvorlage_2_extras.find(
                        (a) => a.formName === extra
                      );

                      let value =
                        props.form.textvorlageTeil2Details[item?.formName];
                      return (
                        <View
                          className={classNames(
                            "flex justify-between items-center  border border-gray-100 rounded-md p-3 min-w",
                            value === true
                              ? "border-green-500 border-2"
                              : "border-red-500 border-2"
                          )}
                        >
                          <View className="items-center">
                            {item?.icon}
                            <Text className="text-sm font-medium">
                              {item?.name}
                            </Text>
                          </View>

                          {value && (
                            <View className="flex flex-row items-center">
                              <Octicons
                                name="check"
                                size={24}
                                color={"#14b8a6"}
                              />
                              <Text className="ml-1">Verfügbar</Text>
                            </View>
                          )}

                          {!value && (
                            <View className="flex flex-row items-center">
                              <AntDesign
                                name="close"
                                size={24}
                                color={"#f43f5e"}
                              />
                              <Text>Nicht verfügbar</Text>
                            </View>
                          )}
                        </View>
                      );
                    })}
              </View>
            </div>
          )}

          {/* 9. Bilder */}
          {currentTabIndex === 9 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Logo")}
                {createSubheader("Hochgeladenes Logo")}
              </div>
              <View className="w-1/2 p-6">
                {props.form.imagesDetails?.logo && (
                  <img src={props.form.imagesDetails.logo}></img>
                )}
              </View>

              <div className="px-4 py-5 sm:px-6">
                {createHeader("Bilder")}
                {createSubheader("Hochgeladene Bilder")}
              </div>

              <View className="w-1/2 p-6">
                {props.form.imagesDetails?.bilder &&
                  props.form.imagesDetails?.bilder?.length > 0 && (
                    <ImageGallery
                      items={props.form.imagesDetails?.bilder.map((a) => ({
                        original: a,
                        thumbnail: a,
                      }))}
                      showFullscreenButton
                      autoPlay={false}
                      slideInterval={1000}
                      showPlayButton={false}
                      showBullets={true}
                      showIndex={true}
                    />
                  )}
              </View>
            </div>
          )}

          {/* 10. Unterschriften */}
          {currentTabIndex === 10 && (
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                {createHeader("Unterschriften")}
                {createSubheader(
                  "Unterschriften in Druckbuchstaben und in Schreibschrift"
                )}
              </div>
              <View className="w-1/2 p-6">
                {props.form.signs?.signatureLetters && (
                  <View className="border border-gray-100">
                    <img
                      src={props.form.signs?.signatureLetters}
                    ></img>
                  </View>
                )}
                {props.form.signs?.signatureSign && (
                  <View className="border border-b border-gray-100 mt-2">
                    <img
                      src={props.form.signs?.signatureSign}
                    ></img>
                  </View>
                )}
              </View>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
}
