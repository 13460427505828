import * as React from "react";

export default function HouseSVG(props: any) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="80"
    height="80"
    viewBox="0 0 64 64"
  >
    <linearGradient
      id="GgB4DrbdisjPOMNxvlzLta_44013_gr1"
      x1="48"
      x2="48"
      y1="41.583"
      y2="50.252"
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset="0" stopColor="#6dc7ff"></stop>
      <stop offset="1" stopColor="#e6abff"></stop>
    </linearGradient>
    <path
      fill="url(#GgB4DrbdisjPOMNxvlzLta_44013_gr1)"
      d="M50,42h-4c-0.552,0-1,0.448-1,1v7h6v-7C51,42.448,50.552,42,50,42z"
    ></path>
    <linearGradient
      id="GgB4DrbdisjPOMNxvlzLtb_44013_gr2"
      x1="26"
      x2="26"
      y1="11.833"
      y2="52.17"
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset="0" stopColor="#1a6dff"></stop>
      <stop offset="1" stopColor="#c822ff"></stop>
    </linearGradient>
    <path
      fill="url(#GgB4DrbdisjPOMNxvlzLtb_44013_gr2)"
      d="M25 41H27V45H25z"
    ></path>
    <linearGradient
      id="GgB4DrbdisjPOMNxvlzLtc_44013_gr3"
      x1="25"
      x2="25"
      y1="11.833"
      y2="52.17"
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset="0" stopColor="#1a6dff"></stop>
      <stop offset="1" stopColor="#c822ff"></stop>
    </linearGradient>
    <path
      fill="url(#GgB4DrbdisjPOMNxvlzLtc_44013_gr3)"
      d="M19 30H31V32H19z"
    ></path>
    <linearGradient
      id="GgB4DrbdisjPOMNxvlzLtd_44013_gr4"
      x1="32"
      x2="32"
      y1="11.833"
      y2="52.17"
      gradientUnits="userSpaceOnUse"
      spreadMethod="reflect"
    >
      <stop offset="0" stopColor="#1a6dff"></stop>
      <stop offset="1" stopColor="#c822ff"></stop>
    </linearGradient>
    <path
      fill="url(#GgB4DrbdisjPOMNxvlzLtd_44013_gr4)"
      d="M56,32v-2c0-1.103-0.897-2-2-2H42v-2c1.103,0,2-0.897,2-2v-2c0-1.103-0.897-2-2-2h-0.382 l-3.447-6.895C37.829,12.424,37.144,12,36.381,12H13.619c-0.763,0-1.448,0.424-1.791,1.106L8.382,20H8c-1.103,0-2,0.897-2,2v2 c0,1.103,0.897,2,2,2v24c0,1.103,0.897,2,2,2h9h12h9h2h12c1.103,0,2-0.897,2-2V38c1.103,0,2-0.897,2-2v-2C58,32.897,57.103,32,56,32 z M54,30v2H42v-2H54z M13.619,14h22.762l3.001,6H10.618L13.619,14z M8,22h34v2H8V22z M40,26v6v4v2v4h-4v2h4v2h-9v-9 c0-1.654-1.346-3-3-3h-6c-1.654,0-3,1.346-3,3v9h-9v-2h4v-2h-4V26H40z M10,50v-2h9v2H10z M21,50V37c0-0.552,0.448-1,1-1h6 c0.552,0,1,0.448,1,1v13H21z M31,50v-2h9v2H31z M54,50H42V38h12V50z M56,36H42v-2h14V36z"
    ></path>
  </svg>
  );
}
