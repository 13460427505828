import { Octicons } from "@expo/vector-icons";
import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import { View } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  useUpdateCompanyMutation,
} from "../../../../../__generated__/graphql-types";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientInputWrapper from "../../../web-components/ClientInputWrapper";
import ClientTextInput from "../../../web-components/ClientTextInput";
import { contuineFormButton, previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import { POSITIONEN_VERTRETUNGSBERECHTIGT } from "../../../web-components/dropdown_data";
import { selectWebUser } from "../../../../../store/web-slices/userSlice.web";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";

export default function BeraterUndKundendatenForm(props: {
  form: Company;
  contuineAndSave: () => void;
  contuineWithoutSave: () => void;
  onBackClick: () => void;
}) {
  let { currentWebUser } = useSelector(selectWebUser);
  let schema = yup.object({
    mitarbeiter_telefonist: yup.string(),
    ort_vertragsschliessung: yup.string().required(),
    gesprochen_mit_anrede: yup.string().required(),
    gesprochen_mit_vorname: yup.string().required(),
    gesprochen_mit_nachname: yup.string().required(),
    authorityRequired: yup.boolean().nullable(),
    unterzeichnet_anrede: yup.string().required(),
    unterzeichnet_position: yup.string().required(),
    unterzeichnet_vorname: yup.string().required(),
    unterzeichnet_nachname: yup.string().required(),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formData] = useState<Company>(props.form);
  const updateCompanyMutation = useUpdateCompanyMutation()[0];
  const { addToast } = useToasts();

  useEffect(() => {
    formData.beraterKundendatenDetails.authorityRequired = formData.beraterKundendatenDetails.authorityRequired !== null
      ? formData.beraterKundendatenDetails.authorityRequired
      : formData.betreiberDetails.rechtsform !== "Einzelunternehmen"
  }, [formData])

  return (
    <Formik
      initialValues={formData.beraterKundendatenDetails || {}}
      validationSchema={schema}
      validateOnMount
      onSubmit={async (newberaterKundendatenDetailsForm) => {
        try {
          setIsLoading(true);
          console.log("NEW FORM -> ", newberaterKundendatenDetailsForm);
          let response = await updateCompanyMutation({
            variables: {
              ...formData,
              id: formData._id,
              updateCompanyInput: {
                beraterKundendatenDetails: {
                  ...formData["beraterKundendatenDetails"],
                  ...newberaterKundendatenDetailsForm,
                },
              },
            },
          });
          if (response.data?.updateCompany) {
            props.contuineAndSave();
            addToast("Erfolgreich gespeichert!", { appearance: "success" });
          } else {
            addToast("Daten konnten nicht gespeichert werden! #5 response.data?.updateCompany ist leer!", { appearance: "error" });
          }
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, setFieldValue, submitForm }) => (
        <Form>
          {/* {JSON.stringify(values, null, 2)}
          {JSON.stringify(errors, null, 2)} */}

          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}

            {contuineFormButton({
              formIsReadonly: props.form.isReadonly,
              disabled: !isValid,
              isLoading,
              submitForm,
              contuineWithoutSaving: props.contuineWithoutSave
            })}
          </View>

          <ClientInputWrapper>
            <ClientTextInput
              label="Name des Mitarbeiters"
              disabled
              mode="text"
              defaultValue={formData.createdBy?.firstName + " " + formData.createdBy?.lastName}>
            </ClientTextInput>

            <ClientTextInput
              label="Mitarbeiter-Nr."
              disabled
              mode="text"
              defaultValue={currentWebUser?.employeeNumber}>
            </ClientTextInput>

            <Field
              component={ClientTextInput}
              name="mitarbeiter_telefonist"
              disabled={props.form.isReadonly}
              label="Telefonist"
              mode="text"
            ></Field>
          </ClientInputWrapper>

          <Field
            component={ClientTextInput}
            name="ort_vertragsschliessung"
            label="Ort der Vertragsschließung"
            disabled={props.form.isReadonly}
            mode="textAndDropdown"
            onClick={(value: string) =>
              setFieldValue("ort_vertragsschliessung", value)
            }
            dropdownData={[
              props.form.betreiberDetails?.ort
            ]}
            addPleaseSelectOption={false}
          ></Field>

          <AdminFormHeader>Gesprochen mit...</AdminFormHeader>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              name="gesprochen_mit_anrede"
              label="Gesprochen mit (Anrede)"
              mode="textAndDropdown"
              disabled={props.form.isReadonly}
              onClick={(value: string) =>
                setFieldValue("gesprochen_mit_anrede", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_anrede,
                props.form.kommunikationsanschriftDetails.anrede,
              ]}
              addPleaseSelectOption={false}
            ></Field>
          </ClientInputWrapper>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="gesprochen_mit_vorname"
              label="Gesprochen mit (Vorname)"
              grow
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("gesprochen_mit_vorname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_vorname,
                props.form.kommunikationsanschriftDetails.vorname,
              ]}
              addPleaseSelectOption={false}
            ></Field>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="gesprochen_mit_nachname"
              label="Gesprochen mit (Nachname)"
              mode="textAndDropdown"
              grow
              onClick={(value: string) =>
                setFieldValue("gesprochen_mit_nachname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_nachname,
                props.form.kommunikationsanschriftDetails.nachname,
              ]}
              addPleaseSelectOption={false}
            ></Field>
          </ClientInputWrapper>
          <AdminFormHeader>Unterzeichnet von...</AdminFormHeader>
          <div className="flex flex-row mt-10 items-center">
            <Checkbox
              id="check"
              checked={values.authorityRequired}
              disabled={props.form.isReadonly}
              onChange={(e) => {
                setFieldValue('authorityRequired', !values.authorityRequired)
              }}
            />
            <label htmlFor="check">Vollmacht benötigt</label>
          </div>
          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="unterzeichnet_anrede"
              label="Unterzeichnet von (Anrede)"
              grow
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("unterzeichnet_anrede", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_anrede,
                props.form.kommunikationsanschriftDetails.anrede,
              ]}
              addPleaseSelectOption={false}
            ></Field>
          </ClientInputWrapper>

          <ClientInputWrapper>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="unterzeichnet_vorname"
              label="Unterzeichnet von (Vorname)"
              grow
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("unterzeichnet_vorname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_vorname,
                props.form.kommunikationsanschriftDetails.vorname,
              ]}
              addPleaseSelectOption={false}
            ></Field>
            <Field
              component={ClientTextInput}
              disabled={props.form.isReadonly}
              name="unterzeichnet_nachname"
              label="Unterzeichnet von (Nachname)"
              grow
              mode="textAndDropdown"
              onClick={(value: string) =>
                setFieldValue("unterzeichnet_nachname", value)
              }
              dropdownData={[
                props.form.betreiberDetails?.vertretung_nachname,
                props.form.kommunikationsanschriftDetails.nachname,
              ]}
            ></Field>
          </ClientInputWrapper>
          <Field
            component={ClientTextInput}
            disabled={props.form.isReadonly}
            name="unterzeichnet_position"
            label="Unterzeichnet von (Position)"
            grow
            mode="textAndDropdown"
            onClick={(value: string) =>
              setFieldValue("unterzeichnet_position", value)
            }
            dropdownData={POSITIONEN_VERTRETUNGSBERECHTIGT}
            addPleaseSelectOption={false}
          ></Field>
        </Form>
      )}
    </Formik>
  );
}
