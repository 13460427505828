import { SimpleLineIcons } from "@expo/vector-icons";
import { Formik, Form } from "formik";
import React, { createRef, useState, useEffect } from "react";
import { View, TouchableHighlight, Text, TouchableOpacity } from "react-native";
import { useToasts } from "react-toast-notifications";
import { UploadImages } from "../../../services/ImageUploadService";
import { transformDate } from "../../../helpers";
import ClientButton from "./ClientButton";
import SignatureCanvas from "react-signature-canvas";
import { Maybe } from "../../../__generated__/graphql-types";
import { Platform } from 'react-native';
import { Checkbox } from "@mui/material";
import { pink } from "@mui/material/colors";

interface SignPad {
  showPad: boolean;
  disabled?: boolean;
  signLink?: Maybe<string> | string | undefined;
  uploadFolderName?: string,
  description?: string;
  signDate?: Date | null;
}

interface SignPadsInput {
  lettersPad: SignPad;
  signPad: SignPad;
  submitButtonConfig: {
    show: boolean;
    submitButtonText?: string;
  }
}

export interface SignPadsResult {
  signatureLettersLink?: string;
  signatureSignLink?: string;
}

export default function SignPads(props: {
  signPadsInput: SignPadsInput;
  onSubmit: (result: SignPadsResult) => void;
}) {
  const signaturePadLetters = createRef<SignaturePad>();
  const signaturePadSign = createRef<SignaturePad>();
  const [signaturePadRefLettersIsEmpty, setSignaturePadRefLettersIsEmpty] = useState(true);
  const [signaturePadRefSignIsEmpty, setSignaturePadRefSignIsEmpty] = useState(true);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [rulesAccepted, setRulesAccepted] = useState(false);

  const clearSignaturePadLetters = () => {
    const signaturePad = signaturePadLetters.current;

    if (signaturePad) {
      signaturePad.clear();
    }
    setSignaturePadRefLettersIsEmpty(true);
  };

  const clearSignaturePadSign = () => {
    const signaturePad = signaturePadSign.current;

    if (signaturePad) {
      signaturePad.clear();
    }
    setSignaturePadRefSignIsEmpty(true);
  };

  useEffect(() => {
    if (props.signPadsInput.lettersPad.showPad && props.signPadsInput.lettersPad.signLink) {
      signaturePadLetters?.current?.fromDataURL(props.signPadsInput.lettersPad.signLink);
      signaturePadLetters.current?.off();
      setSignaturePadRefLettersIsEmpty(false);
    }

    if (props.signPadsInput.signPad.showPad && props.signPadsInput.signPad.signLink) {
      signaturePadSign?.current?.fromDataURL(props.signPadsInput.signPad.signLink);
      signaturePadSign.current?.off();
      setSignaturePadRefSignIsEmpty(false);
    }
  }, [props.signPadsInput]);

  useEffect(() => {
    if (props.signPadsInput.signPad.disabled) {
      signaturePadSign.current?.off();
    }
    if (props.signPadsInput.lettersPad.disabled) {
      signaturePadLetters.current?.off();
    }
  }, [props.signPadsInput.signPad]);

  const uploadSignature = async (base64: string, folder: string) => {
    try {
      const result = await UploadImages([
        {
          base64,
          folder,
          upload_preset: "sf4l8j60",
        },
      ]);
      return result[0].url;
    } catch (error) {
      throw new Error(`Fehler beim Hochladen der Unterschrift: ${error}`);
    }
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);

    try {
      const lettersBase64 = signaturePadLetters.current?.toDataURL();
      const signBase64 = signaturePadSign.current?.toDataURL();

      if (!lettersBase64 || !signBase64) {
        addToast("Unterschrift fehlt!", {
          appearance: "error",
        });
        return;
      }

      let result: SignPadsResult = {};

      if (props.signPadsInput.lettersPad.showPad) {
        result.signatureLettersLink = await uploadSignature(
          lettersBase64,
          props.signPadsInput.lettersPad.uploadFolderName!
        );
      }

      if (props.signPadsInput.signPad.showPad) {
        result.signatureSignLink = await uploadSignature(
          signBase64,
          props.signPadsInput.signPad.uploadFolderName!
        );
      }
      props.onSubmit(result);
      // addToast("Erfolgreich gespeichert", { appearance: "success" });
    } catch (error) {
      addToast("Fehler beim Speichern: " + error, {
        appearance: "error",
      });
      console.error("Fehler beim Speichern: " + error);
    } finally {
      setIsLoading(false);
    }
  }

  const isTouchScreenDevice = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <Formik
      initialValues={props.signPadsInput || {}}
      validateOnMount
      onSubmit={onSubmitHandler}
    >
      {({ isValid, values, errors, resetForm, submitForm }) => (
        <Form>
          {/* {JSON.stringify(values, null, 2)}
          {JSON.stringify(errors, null, 2)} */}

          <View className="flex flex-col">
            {props.signPadsInput.lettersPad.showPad && (
              <View className="items-center">
                <View className="border border-gray-200 shadow-md bg-white-100 w-full lg:w-7/12 max-w-600px rounded-md flex flex-col justify-between">
                  <SignatureCanvas
                    ref={signaturePadLetters}
                    penColor="black"
                    canvasProps={{ height: "350px", style: { backgroundColor: "white" } }}
                    clearOnResize={false}
                    onEnd={() => setSignaturePadRefLettersIsEmpty(false)}
                  />

                  {!props.signPadsInput.lettersPad.signLink && !props.signPadsInput.lettersPad.disabled && (
                    <TouchableHighlight
                      className="right-0 absolute mt-1 mr-1"
                      activeOpacity={0.6}
                      underlayColor="#DDDDDD"
                      onPressOut={clearSignaturePadLetters}
                    >
                      <SimpleLineIcons name="refresh" size={24} color="#60A5FA" />
                    </TouchableHighlight>
                  )}

                  <View className="justify-between flex flex-row mt-2 text-gray-400 m-2 select-none">
                    <Text className="text-pink-500 font-bold text-xs">
                      {props.signPadsInput.lettersPad.description}
                    </Text>
                    <Text className="text-pink-500 font-bold text-xs">
                      {transformDate(props.signPadsInput.lettersPad.signDate || new Date(), false)}
                    </Text>
                  </View>
                </View>
              </View>
            )}

            {props.signPadsInput.signPad.showPad && (
              <View className="mt-4 items-center">
                <View className="border border-gray-200 bg-white shadow-md w-full lg:w-7/12 max-w-600px rounded-md flex flex-col justify-between">
                  <SignatureCanvas
                    ref={signaturePadSign}
                    clearOnResize={false}
                    canvasProps={{ height: "350px", style: { backgroundColor: "white" } }}
                    penColor="black"
                    onEnd={() => setSignaturePadRefSignIsEmpty(false)}
                  />
                  {!props.signPadsInput.signPad.signLink && !props.signPadsInput.signPad.disabled && (
                    <TouchableHighlight
                      className="right-0 absolute mt-1 mr-1"
                      activeOpacity={0.6}
                      underlayColor="#DDDDDD"
                      onPressOut={clearSignaturePadSign}
                    >
                      <SimpleLineIcons name="refresh" size={24} color="#60A5FA" />
                    </TouchableHighlight>)}
                  <View className="justify-between flex flex-row mt-2 text-gray-400 m-2 select-none">
                    <Text className="text-pink-500 font-bold text-xs">
                      {props.signPadsInput.signPad.description}
                    </Text>
                    <Text className="text-pink-500 font-bold text-xs">
                      {transformDate(props.signPadsInput.signPad.signDate || new Date(), false)}
                    </Text>
                  </View>
                </View>

                <div className="flex flex-rowjustify-between w-full lg:w-7/12 max-w-600px my-5 items-center">
                  <Checkbox
                    sx={{
                      color: pink[500],
                      '&.Mui-checked': {
                        color: pink[500],
                      },
                    }}
                    onChange={(e) => {
                      setRulesAccepted(!rulesAccepted);
                    }}
                  />
                  <div>Ich akzeptiere die <span className="text-pink-500 font-bold cursor-pointer" onClick={() => { window.open("http://youtwome.de/werbekunden") }}>AGB</span> und <span className="text-pink-500 font-bold cursor-pointer" onClick={() => { window.open("http://youtwome.de/datenschutz") }}>Datenschutzerklärung</span></div>
                </div>

                <div className="mt-4">
                  {(!props.signPadsInput.lettersPad.signLink && !props.signPadsInput.signPad.signLink && props.signPadsInput.submitButtonConfig?.show === true) && <ClientButton
                    type="submit"
                    onClick={submitForm}
                    disabled={signaturePadRefLettersIsEmpty || signaturePadRefSignIsEmpty || !rulesAccepted}
                    label={props.signPadsInput.submitButtonConfig.submitButtonText || "Unterschriften absenden"}
                  ></ClientButton>}
                </div>
              </View>
            )}
          </View>
        </Form>
      )}
    </Formik>
  );
}
