import React, { useMemo, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import InputAdornment from '@mui/material/InputAdornment';
import "react-datepicker/dist/react-datepicker.css";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { classNames, createPdfViewer, formatNumberToCurrency } from "../../../helpers";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { Feather } from "@expo/vector-icons";
import { useToasts } from "react-toast-notifications";
import { Order, OrderPosition, useCompleteOrderMutation, useCreateShipmentLabelMutation, useGenerateCouponCodeMutation } from "../../../__generated__/graphql-types";
import LoadingCircleSVG from "../admin/svg-icons/loading-circle-svg";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props}></Slide>;
});

export interface PrintQueueItem {
  rowIndex: number;
  isCurrent?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  base64?: string;
  printed?: boolean;
  position?: OrderPosition;
  shipped?: boolean;
};

export const ShipOrderPositionsModal = NiceModal.create(
  (props: { order: Order }) => {
    console.log(props.order)
    const modal = useModal();
    const { addToast } = useToasts();
    const steps = [
      'Rechnung drucken & verpacken',
      'Gutscheine generieren & verpacken',
      'Sendungsnummer eingeben & Bestellung abschließen',
    ];

    let [generateCouponCode] = useGenerateCouponCodeMutation();
    let [completeOrder] = useCompleteOrderMutation();
    let [createShipmentLabel] = useCreateShipmentLabelMutation();

    const [printQueueResults, setPrintQueueResults] = useState<PrintQueueItem[]>(
      props.order?.positions
        ?.filter(a => !a.orderProduct?.isShipmentArticle)
        ?.map((position, index) => ({
          rowIndex: index,
          isCurrent: false,
          isLoading: false,
          hasError: false,
          base64: undefined,
          printed: false,
          position,
          shipped: false
        })) || []
    );
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [invoiceDownloaded, setInvoiceDownloaded] = useState<boolean>(false);
    const [shipmentTrackingNumber, setShipmentTrackingNumber] = useState<string>('');

    const columns = useMemo<MRT_ColumnDef<OrderPosition>[]>(
      () => [
        {
          header: "Status",
          enableEditing: false,
          Cell({ cell, column, row, table }) {

            let queueItem = printQueueResults.find(a => a.rowIndex === row.index);
            if (!queueItem) {
              return;
            }

            if (!queueItem.base64 && !queueItem.isLoading) {
              return <Text
                className={`p-1.5 bg-slate-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide`}
              >
                Ausstehend
              </Text>
            }


            if (queueItem.isLoading) {
              return <Text
                className={`p-1.5 bg-slate-500 text-white font-semibold rounded-md text-xs uppercase tracking-wide`}
              >
                Codes werden generiert...
              </Text>
            }

            if (queueItem.hasError) {
              return <Text
                className={`p-1.5 bg-red-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide`}
              >
                Fehler
              </Text>
            }

            if (queueItem.isCurrent && !queueItem.shipped && queueItem.base64) {
              return <Text
                className={`p-1.5 bg-orange-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide`}
              >
                Nicht verpackt / gedruckt
              </Text>
            }

            if (queueItem.shipped) {
              return <Text
                className={`p-1.5 bg-teal-600 text-white font-semibold rounded-md text-xs uppercase tracking-wide`}
              >
                Verpackt
              </Text>
            }
          },
        },
        {
          accessorFn: (row) => row.orderProduct?.description,
          enableEditing: false,
          header: "Beschreibung",
        },
        {
          accessorFn: (row) => row.quantity,
          enableEditing: false,
          header: "Menge",
        },
        {
          accessorFn: (row) => formatNumberToCurrency(row.priceNet),
          enableEditing: false,
          header: "Preis (Netto)",
        },
      ],
      []
    );

    const isDisabled = (): boolean | undefined => {
      if (currentStep === 0) {
        return !invoiceDownloaded;
      }
      if (currentStep === 1) {
        return printQueueResults.filter(a => a.isLoading || a.hasError || !a.base64 || !a.shipped || !a.printed)?.length > 0;
      }
      if (currentStep === 2) {
        return !shipmentTrackingNumber;
      }
    }

    return (
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth={"xl"}
        open={modal.visible}
        onClose={() => {
          modal.resolve({ resolved: false });
          modal.hide();
        }}
        TransitionProps={{
          onExited: () => {
            modal.resolve({ resolved: false });
            modal.remove();
          },
        }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          Bestellung #{props.order.orderNumber} ({printQueueResults.filter(a => a.shipped)?.length}/{printQueueResults.length})
        </DialogTitle>
        <DialogContent>
          {/* {JSON.stringify(values.positions, null, "\t")}
        {JSON.stringify(errors, null, "\t")} */}
          <div>

            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={currentStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {currentStep === 0 && (
              <div className="bg-gray-50 mt-4 rounded-xl">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Rechnung #{props.order.orderNumber}</h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-500">
                    <p>Lade die Rechnung herunter, drucke sie aus und lege sie anschließend in das Paket.</p>
                  </div>
                  <div className="mt-5">
                    <div className="flex flex-row">
                      <button
                        type="button"
                        onClick={() => {
                          window.open(props.order.documents?.orderInvoice);
                          setInvoiceDownloaded(true);
                        }}
                        className={classNames(invoiceDownloaded ? "text-gray-400" : "text-gray-700", "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-pink-500 sm:text-sm")}
                      >
                        Rechnung herunterladen
                      </button>


                      {/* <button
                        type="button"
                        onClick={async () => {
                          await createShipmentLabel({
                            variables: {
                              createShipmentLabelInput: {
                                orderId: props.order._id
                              }
                            }
                          });
                        }}
                        className={classNames(invoiceDownloaded ? "text-gray-400" : "text-gray-700", "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md bg-white hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-pink-500 sm:text-sm")}
                      >
                        Label erzeugen
                      </button> */}

                      {invoiceDownloaded &&
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#006D5B" className="ml-2 mt-2 w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 1 && (
              <>
                <MaterialReactTable
                  enableColumnFilterModes
                  enableRowActions
                  localization={MRT_Localization_DE}
                  positionToolbarAlertBanner="bottom"
                  enableFullScreenToggle
                  enableEditing
                  editingMode="table"
                  data={props.order.positions?.filter(a => !a.orderProduct?.isShipmentArticle) || []}
                  state={{ showSkeletons: props.order === null }}
                  columns={columns}
                  initialState={{ density: "compact" }}
                  onEditingCellChange={(cell) => {
                    console.log(cell);
                    // wird aufgerufen wenn das custom autocomplete Artikelbezeichnung ausgewählt wird
                    if (!cell) {
                      return;
                    }
                  }}
                  muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                      borderRadius: "20",
                    },
                  }}
                  displayColumnDefOptions={{
                    "mrt-row-actions": {
                      header: "Aktionen",
                      size: 100, //make actions column wider
                    },
                  }}
                  renderRowActions={({ row, table }) => (
                    <View className="flex flex-row flex-nowrap gap-1">

                      {printQueueResults.find(a => a.rowIndex === row!.index)?.isLoading && (
                        <div className="m-1">
                          <LoadingCircleSVG />
                        </div>
                      )}

                      {((!printQueueResults.find(a => a.rowIndex === row!.index)?.printed
                        && printQueueResults.filter(a => a.isLoading).length === 0)
                        && printQueueResults.find(a => a.isCurrent) === undefined) && (
                          <IconButton title="Drucken" onClick={async () => {
                            let temp = [...printQueueResults]
                            let queueItem = temp.find(a => a.rowIndex === row!.index);

                            try {
                              if (queueItem) {
                                queueItem.isLoading = true;
                                queueItem.hasError = false;
                              } else {
                                queueItem = {
                                  rowIndex: row.index,
                                  isLoading: true,
                                  isCurrent: true,
                                  hasError: false
                                }
                                printQueueResults.push(queueItem);
                              }

                              var result = await generateCouponCode({
                                variables: {
                                  generateCouponCodeInput: {
                                    orderId: props.order._id,
                                    orderPositionId: row.original._id as string,
                                  }
                                }
                              });

                              queueItem.isCurrent = true;
                              queueItem.isLoading = false;
                              queueItem.base64 = result.data?.generateCouponCode;
                              queueItem.printed = true;
                            } catch (error) {
                              addToast("FEHLER: " + error, { appearance: "error" });
                              queueItem!.printed = false;
                              queueItem!.isLoading = false;
                              queueItem!.hasError = true;
                              console.error(error);
                            }

                            setPrintQueueResults(temp);
                          }}>
                            <Feather name="printer" size={24} color="gray" />
                          </IconButton>
                        )}

                      {(printQueueResults.find(a => a.rowIndex === row!.index)?.printed && !printQueueResults.find(a => a.rowIndex === row!.index)?.shipped) && (
                        <IconButton title="Bestätigen gedruckt & eingepackt"
                          onClick={() => {
                            let temp = [...printQueueResults];
                            const queueItem = temp.find(a => a.rowIndex === row!.index);
                            queueItem.shipped = true;
                            queueItem.isCurrent = false;
                            setPrintQueueResults(temp);
                          }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m8.25 3v6.75m0 0l-3-3m3 3l3-3M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                          </svg>
                        </IconButton>
                      )}

                      {printQueueResults.find(a => a.rowIndex === row!.index)?.shipped && (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#006D5B" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                      )}
                    </View>
                  )}
                ></MaterialReactTable>

                {printQueueResults.find(a => a.isCurrent) && (
                  <>
                    <Tabs
                      value={0}
                      variant="scrollable"
                      className="shadow-sm bg-gray-50 select-none rounded-lg mt-3"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab
                        key={"tab-0"}
                        label={`${printQueueResults.find(a => a.isCurrent)?.position?.orderProduct?.description} x ${printQueueResults.find(a => a.isCurrent)?.position?.quantity}`}
                      >
                      </Tab>
                    </Tabs>

                    <div
                      role="tabpanel"
                      id={`tab-0`}
                      aria-labelledby={`tab-0`}
                    >
                      {printQueueResults.find(a => a.isCurrent)?.base64 && createPdfViewer(printQueueResults.find(a => a.isCurrent)!.base64)}
                    </div>
                  </>
                )}
              </>
            )}
          </div>

          {currentStep === 2 && (
            <div className="mt-2">

              <div className="bg-gray-50 mt-4 rounded-xl">
                <div className="px-4 py-5 sm:p-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">DHL Sendung erfassen</h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-900">
                    <p>Erstelle eine <a className="text-blue-400 underline" href="https://geschaeftskunden.dhl.de/content/vls/vc/ShipmentDetails" target="_blank">Sendung</a> und trage die Sendungsnummer unten ein.</p>
                  </div>
                  <div className="mt-5 text-sm text-gray-900 bg-white rounded-3xl w-80 p-4">
                    <div>{props.order.deliveryAddress?.company}</div>
                    <div>{props.order.deliveryAddress?.streetName} {props.order.deliveryAddress?.streetNameExtra}</div>
                    <div>{props.order.deliveryAddress?.postalCode} {props.order.deliveryAddress?.city}</div>
                    <div>{props.order.deliveryAddress?.country}</div>
                  </div>
                  <div className="mt-5">
                    <TextField
                      label="Sendungsnummer DHL"
                      size="small"
                      onChange={(e) => {
                        setShipmentTrackingNumber((e.target as any).value);
                      }}
                      defaultValue={shipmentTrackingNumber}
                      className="w-2/6 bg-white"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">DHL: </InputAdornment>
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        </DialogContent>
        <DialogActions>

          <Button
            onClick={() => {
              modal.resolve({ resolved: true });
              modal.hide();
            }}
            color="error"
          >
            Abbrechen
          </Button>

          {currentStep > 0 &&
            <Button
              onClick={() => {
                setCurrentStep(currentStep - 1);
              }}
              color="secondary"
            >
              Zurück
            </Button>
          }

          <Button
            onClick={async () => {
              if (currentStep === 0 || currentStep === 1) {
                setCurrentStep(currentStep + 1);
              }

              if (currentStep === 2) {
                try {
                  await completeOrder({
                    variables: {
                      completeOrderInput: {
                        orderId: props.order._id,
                        shipmentTrackingNumber
                      }
                    }
                  });
                  modal.resolve({ resolved: true });
                  modal.hide();
                } catch (error) {
                  addToast("FEHLER: " + error);
                  return;
                }
              }
            }}
            disabled={isDisabled()}
            color="primary"
          >
            {(currentStep === 0 || currentStep === 1) && "Weiter"}
            {currentStep === 2 && "Bestellung abschließen"}

          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);