import { Alert, Platform, RefreshControl, Text, TouchableOpacity, View } from "react-native";
import { NavigationProp } from "@react-navigation/native";
import { CompanyListItem, useGetUserFavoritesIdsQuery } from "../../__generated__/graphql-types";
import CompaniesListItem from "./CompaniesListItem";
import { Image } from "expo-image";
import { ScrollView, FlatList } from "react-native-gesture-handler";
import { useCallback, useEffect, useState } from "react";
import { classNames } from "../../helpers";
import { EvilIcons, FontAwesome6, MaterialCommunityIcons } from "@expo/vector-icons";
import { CompaniesFilterOptions, CompaniesFilterSortOrder, getCompanySortOrderDescription } from "../app/services/FilterHelpers";

interface CompaniesListProps {
  companies: CompanyListItem[] | null | undefined;
  remainingCount?: number | null | undefined;
  totalCount?: number | null | undefined;
  enablePagination?: boolean;
  navigation: NavigationProp<any, any>
  loading: boolean;
  loadMoreData?: (page: number) => Promise<void>;
  showHeader?: boolean;
  backgroundColor?: string;
  fullHeight?: boolean;
}
export default function (props: CompaniesListProps) {
  const { data: favorites, loading: loadingFavorites } = useGetUserFavoritesIdsQuery();
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState<CompanyListItem[] | null | undefined>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setCompanies(props?.companies);
    setRefreshing(!refreshing);
    if (page === 0) {
      setRefreshKey(refreshKey + 1);
    }
  }, [props?.companies])

  useEffect(() => {
    setRefreshing(!refreshing);
    setRefreshKey(refreshKey + 1);
  }, [props?.totalCount])

  if (loadingFavorites) {
    return <View><Image source={require("../../../assets/animated/loading.gif")} style={{ height: 50, width: 50 }} /></View>
  }

  return (
    <View className={classNames(props.backgroundColor ? props.backgroundColor : "bg-gray-50")}>
      {props.loading ? (
        <View style={{ marginHorizontal: "auto" }} className="z-10 rounded-3xl px-4">
          <Image source={require("../../../assets/animated/loading.gif")} style={{ marginHorizontal: "auto", height: 50, width: 50 }} />
        </View>
      ) : (
        <View className="mx-4">
          {props.showHeader !== false && (
            <View className="rounded-2xl py-5 ml-1">
              <Text className="text-slate-700 font-bold" style={{ fontSize: 24 }} >{props.totalCount ? `${props.totalCount} Suchergebni${props.totalCount === 0 ? "s" : "sse"}` : 'Keine Ergebnisse'}
              </Text>
            </View>)}
          <View className={classNames("flex flex-col gap-5", props.fullHeight ? "" : "mb-80")} key={refreshKey}>
            {companies && companies?.length ? (
              <FlatList
                data={companies}
                keyExtractor={(performancePlace, index) => performancePlace._id?.toString()}
                // extraData={companies}
                refreshing={refreshing}
                ListFooterComponent={(props.remainingCount || 0) > 0 ?
                  <View className="mx-auto">
                    <Image source={require("../../../assets/animated/loading.gif")} style={{
                      height: 50,
                      width: 50,
                      marginBottom: Platform.OS === "android" ? 20 : 0
                    }} />
                  </View> :
                  <Text className="text-center my-6 text-gray-400">Keine weiteren Erlebnisse</Text>}
                onEndReachedThreshold={1}
                removeClippedSubviews={false}
                maxToRenderPerBatch={10}
                onEndReached={async ({ distanceFromEnd }) => {
                  if (distanceFromEnd < 0) {
                    return;
                  }
                  if (props.enablePagination && props.remainingCount && props.remainingCount > 0) {
                    if (!props.loadMoreData) {
                      console.warn('props.loadMoreData wurde nicht gesetzt obwohl props.enablePagination true ist.')
                    } else {
                      props.loadMoreData && await props.loadMoreData(page + 1);
                      setPage(page => page + 1);
                    }
                  }
                }}
                renderItem={({ item: performancePlace, index }) => (
                  <View className="py-2" key={performancePlace._id! + index}>
                    <CompaniesListItem
                      isFavorite={favorites?.getUserFavoritesIds?.includes(performancePlace?._id || '') || false}
                      navigation={props.navigation}
                      performancePlace={performancePlace}
                      showCloseIcon={false}
                      itemKey={performancePlace._id! + "_" + index}>
                    </CompaniesListItem>
                  </View>
                )}
              >
              </FlatList>
            )
              :
              <Text>:(</Text>
            }
          </View>
        </View>
      )}
    </View>
  );
};