import { Feather } from "@expo/vector-icons";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { View, Text } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  useCreateSignRequestMutation,
  SignRequestType,
  useCreateContractDocumentsMutation,
  DocumentLinks,
  useGetSignRequestByCompanyIdLazyQuery,
  useUpdateCompanyMutation,
  Maybe,
  useRepeatSignRequestMutation,
  useGetSignRequestLazyQuery,
  SignRequest,
  useUpdateCompanyStateMutation,
  CompanyState,
  OrderRequiredInformation,
  Company,
  useUpdateSignRequestMutation,
} from "../../../../../__generated__/graphql-types";
import ClientButton from "../../../web-components/ClientButton";
import { previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import { classNames, transformDate } from "../../../../../helpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ContractDocumentsPreview from "../../../web-components/ContractDocumentsPreview";
import { TextField } from "@mui/material";
import LoadingScreen from "../../../web-components/LoadingScreen";
import { SignatureQrCodeModal } from "../../../web-modals/SignatureQrCode.modal";
import NiceModal from "@ebay/nice-modal-react";
import { SignatureReviewModal } from "../../../web-modals/SignatureReview.modal";
import { useNavigate } from "react-router";

export const MySwal = withReactContent(Swal);

export default function VertragsvorschauScreen(props: {
  form: Company;
  contuineAndSave: () => void;
  onBackClick: () => void;
}) {

  let schema = yup.object({
    orderRequiredInformation: yup.object({
      orderRequired: yup.boolean(),
      reasonRejected: yup.string()
    })
  });

  const [formData] = useState<Company>(props.form);
  const [contractDocuments, setContractDocuments] = useState<DocumentLinks | undefined>();
  const [signRequestSended, setSignRequestSended] = useState<boolean>(false);
  const [showUploadButton, setShowUploadButton] = useState<boolean>(false);
  const [isReadonly, setIsReadonly] = useState<boolean>(false);
  const [repeatEmail, setRepeatEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const [createSignRequest] = useCreateSignRequestMutation();
  const [repeatSignRequest] = useRepeatSignRequestMutation();
  const { addToast } = useToasts();
  const [getSignRequestByCompanyId, { data: signRequest, loading: signRequestLoading }] = useGetSignRequestByCompanyIdLazyQuery();
  const [getSignRequest] = useGetSignRequestLazyQuery();
  const [updateState] = useUpdateCompanyStateMutation();
  const [createContractDocuments] = useCreateContractDocumentsMutation();
  const [updateSignRequest] = useUpdateSignRequestMutation();
  const [updateCompanyMutation] = useUpdateCompanyMutation();
  const navigate = useNavigate();

  const updateOrderRequired = async (orderRequiredInformation: Maybe<OrderRequiredInformation> | undefined) => {
    try {
      setIsLoading(true);
      if (orderRequiredInformation === undefined || orderRequiredInformation === null) {
        addToast("FEHLER: Auswahl 'Bestellung erforderlich' wurde nicht ausgewählt ", { appearance: "warning" });
        setIsLoading(false);
        return;
      }
      await updateCompanyMutation({
        variables: {
          id: formData._id,
          updateCompanyInput: {
            orderRequiredInformation
          },
        },
      });
    } catch (error) {
      addToast("FEHLER: Auswahl 'Bestellung erforderlich' wurde nicht gespeichert " + error, { appearance: "error" });
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (formData.documentLinks.contract && formData.documentLinks.adviceProtocol) {
        setIsReadonly(true);
        setContractDocuments(formData.documentLinks);
      } else {
        getSignRequestByCompanyId({ variables: { getSignRequestByCompanyIdInput: { companyId: formData._id } } })
          .then(async signRequest => {
            try {
              const signRequestData = signRequest?.data?.getSignRequestByCompanyId;

              if (signRequestData && signRequestData.requestType !== SignRequestType.ContractOnSide && signRequestData.signed) {
                setContractDocuments(formData.documentLinks);
              } else if (signRequestData && !signRequestData.signed && formData.state === CompanyState.WAIT_CUSTOMER_REACTION) {
                setRepeatEmail(signRequestData.email);
              } else {
                const docs = await createContractDocuments({
                  variables: {
                    createContractDocumentsInput: {
                      companyId: formData._id,
                      upload: false
                    },
                  },
                });
                setContractDocuments(docs?.data?.createContractDocuments);
              }
            }
            catch (error) {
              addToast("FEHLER: " + error, {
                appearance: "error",
              });
              console.error("FEHLER: ", error);
            }

          })
      }
    })();
  }, [formData]);

  if (!formData || signRequestLoading) {
    return <LoadingScreen>Vorschau wird geladen...</LoadingScreen>
  }

  if (formData?.state === CompanyState.WAIT_CUSTOMER_REACTION && signRequest?.getSignRequestByCompanyId && !signRequest?.getSignRequestByCompanyId.signed && signRequest.getSignRequestByCompanyId.requestType !== SignRequestType.ContractOnSide) {
    return (
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6 flex flex-col">
          <div className="ml-auto">
            <ClientButton
              type="submit"
              loading={isLoading}
              onClick={async () => {
                try {
                  setIsLoading(true);
                  props.contuineAndSave();
                } catch (error) {
                  addToast("Fehler " + error, { appearance: "error" });
                  console.log("Fehler ", error);
                } finally {
                  setIsLoading(false);
                }
              }}
              label="Bilder hinzufügen"
            ></ClientButton>
          </div>
          <h3 className="text-lg leading-6 font-bold text-pink-500">Offene Kundenreaktion!</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Es wurde eine Anfrage für die Unterschriften am {transformDate(signRequest?.getSignRequestByCompanyId.createdAt)} geschickt.</p>
            <p>Der Kunde hat noch <span className="font-bold">nicht</span> unterschrieben!</p>
          </div>
          <form className="mt-5 sm:flex sm:items-center">
            <div className="">
              <TextField
                className="w-80"
                type="email"
                defaultValue={repeatEmail}
                onChange={(event) => setRepeatEmail((event.target as any).value)}
                label="E-Mail"
                variant="outlined"
                size="small"
                placeholder="E-Mail für die Erinnerung" />
              <ClientButton
                mode="primary"
                onClick={async () => {
                  if (!repeatEmail) {
                    addToast("E-Mail für Erinnerung wurde nicht eingetragen. Erinnerung kann nicht gesendet werden.", { appearance: "warning" });
                    return;
                  }

                  try {
                    await repeatSignRequest({
                      variables: {
                        repeatSignRequestInput: {
                          _id: signRequest?.getSignRequestByCompanyId._id,
                          email: repeatEmail,
                          isRepeat: true
                        },
                      },
                    });
                    addToast(`Erinnerung an ${repeatEmail} erfolgreich verschickt!`)
                  } catch (error) {
                    addToast("FEHLER: Erinnerung konnte nicht verschickt werden! " + error, { appearance: "error" });
                  }
                }}
                label="E-Mail erneut senden"
                className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-pink-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
              </ClientButton>
            </div>
          </form>
        </div>
      </div>
    )
  }

  if (signRequestSended) {
    return (
      <div className="flex flex-col">
        <div className="ml-auto">
          <ClientButton
            type="submit"
            loading={isLoading}
            onClick={async () => {
              try {
                setIsLoading(true);
                props.contuineAndSave();
              } catch (error) {
                addToast("Fehler " + error, { appearance: "error" });
                console.log("Fehler ", error);
              } finally {
                setIsLoading(false);
              }
            }}
            label="Bilder hinzufügen"
          ></ClientButton>
        </div>
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-bold text-pink-500">E-Mail gesendet!</h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>Es wurde eine Anfrage für die Unterschriften an <span className="font-bold">{formData.kommunikationsanschriftDetails.email}</span>  gesendet.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const isValid = (values: Company): boolean => {
    if (!contractDocuments) {
      return false;
    }
    if (values.orderRequiredInformation?.orderRequired === true) {
      return true;
    }
    if (values.orderRequiredInformation?.orderRequired === false && values.orderRequiredInformation.reasonRejected?.length) {
      return true;
    }
    return false;
  }

  return (
    <Formik
      initialValues={formData || {}}
      validationSchema={schema}
      validateOnMount
      onSubmit={() => { }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}

            {!showUploadButton && !isReadonly && (
              <>
                <div className="mr-1">
                  <ClientButton
                    type="submit"
                    loading={isLoading}
                    disabled={!isValid(values)}
                    onClick={async () => {
                      try {
                        setIsLoading(true);
                        let swalResult = await MySwal.fire({
                          title: `Unterschrift anfordern \n`,
                          html: `Ein Link wird an die E-Mail-Adresse <strong>${formData.kommunikationsanschriftDetails.email}</strong> gesendet, über den der Kunde aufgefordert wird, die Unterschrift bereitzustellen.
                      <br><br>Bitte beachte, dass der Vertrag inaktiv bleibt, solange der Kunde nicht unterschrieben hat!`,
                          showDenyButton: true,
                          width: 700,
                          confirmButtonText: `Ja, E-Mail senden!`,
                          denyButtonText: `Abbrechen`,
                        });

                        if (!swalResult.isConfirmed) {
                          return;
                        }

                        if (!formData.kommunikationsanschriftDetails.email) {
                          addToast(
                            "E-Mail für Kommunkation wurde nicht gefunden. Link kann nicht gesendet werden.",
                            { appearance: "warning" }
                          );
                          return;
                        }

                        await createContractDocuments({
                          variables: {
                            createContractDocumentsInput: {
                              companyId: formData._id,
                              upload: true
                            }
                          },
                        });

                        await createSignRequest({
                          variables: {
                            createSignRequestInput: {
                              company: formData,
                              requestType: SignRequestType.Contract,
                              email: formData.kommunikationsanschriftDetails.email,
                            },
                          },
                        });
                        setSignRequestSended(true);
                        if (values.orderRequiredInformation?.orderRequired) {
                          navigate(`/client/orders/newOrder?companyId=${props.form._id}`);
                        } else {
                          props.contuineAndSave();
                        }
                      } catch (error) {
                        addToast(
                          "FEHLER: Link konnte nicht per E-Mail gesendet werden." +
                          error,
                          { appearance: "error" }
                        );
                      } finally {
                        setIsLoading(false);
                      }
                    }}
                    mode="secondary"
                    label={`Unterschrift per E-Mail (${formData.kommunikationsanschriftDetails.email})`}
                  ></ClientButton>
                </div>

                <ClientButton
                  type="submit"
                  loading={isLoading}
                  onClick={async () => {
                    try {
                      setIsLoading(true);

                      let signatureQrCodeModalResult = (await NiceModal.show(
                        SignatureQrCodeModal,
                        {
                          requestType: SignRequestType.ContractOnSide,
                          company: values
                        }
                      )) as {
                        resolved: boolean;
                        signRequestId: string;
                      };

                      if (!signatureQrCodeModalResult?.resolved) {
                        return;
                      }

                      let signRequest = await getSignRequest({
                        variables: {
                          getSignRequestInput: {
                            requestId: signatureQrCodeModalResult.signRequestId
                          }
                        }
                      });

                      if (!signRequest.data?.getSignRequest) {
                        addToast("FEHLER: Anfrage konnte nicht bearbeitet werden!. Bitte versuche es erneut!")
                        return;
                      }

                      let signatureReviewModelResult = (await NiceModal.show(SignatureReviewModal, {
                        signRequest: signRequest.data?.getSignRequest as SignRequest
                      }
                      )) as {
                        resolved: boolean;
                        signsAccepted: boolean;
                      };

                      if (!signatureReviewModelResult.resolved) {
                        return;
                      }

                      if (signatureReviewModelResult.signsAccepted) {
                        await updateCompanyMutation({
                          variables: {
                            id: values._id,
                            updateCompanyInput: {
                              signs: {
                                signatureLetters: signRequest.data?.getSignRequest.signs.signatureLetters,
                                signatureSign: signRequest.data?.getSignRequest.signs.signatureSign
                              }
                            }
                          }
                        });

                        await updateSignRequest({
                          variables: {
                            id: signRequest.data?.getSignRequest?._id,
                            updateSignRequestInput: {
                              signsAccepted: true,
                            },
                          },
                        });

                        let docs = await createContractDocuments({
                          variables: {
                            createContractDocumentsInput: {
                              companyId: formData._id,
                              upload: false
                            },
                          },
                        });
                        setContractDocuments(docs?.data?.createContractDocuments);
                        setShowUploadButton(true);
                      } else {
                        await updateSignRequest({
                          variables: {
                            id: values._id,
                            updateSignRequestInput: {
                              signsAccepted: false
                            }
                          }
                        });
                        addToast("Du hast die Unterschriften abgelehnt. Beantrage die Unterschriften erneut per QR-Code oder E-Mail.", { appearance: "info" });
                      }

                    } catch (error) {
                      addToast("Fehler " + error, { appearance: "error" });
                      console.log("Fehler ", error);
                    } finally {
                      setIsLoading(false);
                    }
                  }}
                  disabled={!isValid(values)}
                  label="Jetzt Unterschreiben"
                ></ClientButton>
              </>
            )}
            {(showUploadButton && !isReadonly) && (
              <ClientButton
                onClick={async () => {
                  try {
                    try {
                      let docs = await createContractDocuments({
                        variables: {
                          createContractDocumentsInput: {
                            companyId: formData._id,
                            upload: true
                          },
                        },
                      });
                    } catch (error) {
                      throw error;
                    }

                    await updateState({
                      variables: {
                        id: values._id,
                        state: CompanyState.IN_REVIEW
                      }
                    });

                    await updateOrderRequired(values.orderRequiredInformation);
                    addToast("Dein Vertrag wird nun geprüft.", { appearance: "success" });

                    if (values.orderRequiredInformation?.orderRequired) {
                      navigate(`/client/orders/newOrder?companyId=${props.form._id}`);
                    } else {
                      props.contuineAndSave();
                    }
                  } catch (error) {
                    addToast("FEHLER: " + error, { appearance: "error" });
                  }
                }}
                label="Vertrag hochladen"
              ></ClientButton>
            )}

            {isReadonly && (
              <ClientButton
                type="button"
                mode={"secondary"}
                onClick={async () => {
                  props.contuineAndSave()
                }}
                icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
                }
                label={"Weiter"}
              ></ClientButton>
            )}
          </View>

          <span className="text-base font-semibold text-gray-900" onClick={() => console.log(values)}>Ist eine Bestellung erforderlich?</span>

          <div>
            <div className="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
              <>
                <div className={classNames(isReadonly ? "" : "cursor-pointer hover:border-pink-400 hover:border-2 hover:p-3.5", "flex-1 flex border border-gray-100 p-4 rounded-lg", values.orderRequiredInformation?.orderRequired === true ? "border-2 border-pink-500" : "")}
                  onClick={() => {
                    if (!isReadonly) {
                      setFieldValue('orderRequiredInformation.orderRequired', true);
                    }
                  }}>
                  <div className="flex flex-col">
                    <span className="block text-base font-semibold text-gray-900">
                      Ja
                    </span>
                    <span className="mt-1 flex items-center text-xs text-gray-500">
                      Eine Bestellung ist erforderlich.
                    </span>
                  </div>
                  {values.orderRequiredInformation?.orderRequired === true && (
                    <div className="ml-auto">
                      <Feather name="check-circle" size={24} color="#ec4899" />
                    </div>)}
                </div>

                <div className={classNames(isReadonly ? "" : "cursor-pointer hover:border-pink-400 hover:border-2 hover:p-3.5", "flex-1 flex border border-gray-100 p-4 rounded-lg", values.orderRequiredInformation?.orderRequired === false ? "border-2 border-pink-500" : "")}
                  onClick={() => {
                    if (!isReadonly) {
                      setFieldValue('orderRequiredInformation.orderRequired', false);
                    }
                  }}>
                  <div className="flex flex-col">
                    <span className="block text-base font-semibold text-gray-900">
                      Nein
                    </span>
                    <span className="mt-1 flex items-center text-xs text-gray-500">
                      Eine Bestellung ist nicht erforderlich.
                    </span>
                  </div>
                  {values.orderRequiredInformation?.orderRequired === false && (
                    <div className="ml-auto">
                      <Feather name="check-circle" size={24} color="#ec4899" />
                    </div>)}
                </div>

                {values.orderRequiredInformation?.orderRequired === false && (
                  <div className="border border-gray-200 focus:border-pink-500">
                    <textarea
                      disabled={isReadonly}
                      rows={3}
                      className="block w-full border-0 border-b border-transparent focus:outline-none p-3 pb-2 focus:ring-0 focus:border-pink-500 sm:text-sm"
                      placeholder="Grund für die Ablehnung..."
                      defaultValue={values.orderRequiredInformation?.reasonRejected || ''}
                      onChange={(e) => { setFieldValue('orderRequiredInformation.reasonRejected', (e.currentTarget as any).value); }}
                    />
                  </div>
                )}
              </>
            </div>
          </div>

          <ContractDocumentsPreview contractDocuments={contractDocuments} authorityRequired={formData.beraterKundendatenDetails.authorityRequired}></ContractDocumentsPreview>

        </Form>
      )}
    </Formik>
  );
}