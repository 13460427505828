import {
  Text,
  View,
} from "react-native";
import { classNames } from "../../../helpers";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { EvilIcons } from "@expo/vector-icons";
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
registerLocale("de", de);

function ClientTextInput(props: {
  disabled?: boolean;
  placeholder?: string | undefined;
  noDataText?: string | undefined;
  type?: "text" | "number" | "password";
  label?: string;
  viewClassName?: string;
  grow?: boolean;
  shrink?: boolean;
  inputClassName?: string;
  mode?: "text" | "textarea" | "textAndDropdown" | "dropdown" | "date";
  rows?: number;
  maxLength?: number;
  description?: string;
  onClick?: (value: string) => void;
  onDescriptionClick?: () => void;
  dropdownData?: string[] | Object[];
  displayKey?: string;
  selectKey?: string;
  addPleaseSelectOption?: boolean;
  field?: any;
  form?: any;
  defaultValue?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dropdownData, setDropdownData] = useState<string[] | Object[]>();
  const [field, meta, helpers] = props.field?.name
    ? useField(props.field?.name)
    : [undefined];

  const [popperIsOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const handleToggleDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setIsOpen(!popperIsOpen);
  };

  const handleSelectOption = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  const hasError = () => {
    return (
      props.field &&
      field &&
      props.form.errors[field.name] &&
      props.form.touched[field.name]
    );
  };

  const handleChange = (event: any) => {
    props.onChange && props.onChange(event);
    const selectedOption = JSON.parse(event.target.value);
    helpers?.setValue(selectedOption);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    setAnchorEl(null);
  };

  const handleBlur = () => {
    helpers?.setTouched(true);
  };

  const getSelectInput = () => {
    if (props.mode === "dropdown" || props.mode === "textAndDropdown") {
      // Array<String> ['1', '2']
      if (
        props.dropdownData &&
        props.dropdownData.length &&
        typeof props.dropdownData[0] === "string"
      ) {
        return (
          <>
            <select
              key={props.field?.name}
              id={props.field?.name}
              name={props.field?.name}
              {...props.field}
              disabled={props.disabled}
              {...props}
              onClick={(event) => {
                console.log(' event select item 1 ', event)
                props.onClick && props.onClick((event.target as any).value)
                event.preventDefault();
                event.stopPropagation();
              }}
              className={classNames(
                props.inputClassName ? props.inputClassName : "",
                `block appearance-none  bg-white border border-slate-300 px-4 py-2 pr-8 rounded-lg leading-tight focus:outline-none focus:shadow-outline w-full`,
                props.disabled ? "" : "hover:border-pink-300 cursor-pointer"
              )}
            >
              {dropdownData &&
                dropdownData.map((value) => (
                  <option className="text-gray-700">{value as string}</option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </>
        );
      }
      // Array<Object> [{a: '1'}, {a: '2'}]
      else if (
        props.dropdownData &&
        props.dropdownData.length &&
        typeof props.dropdownData[0] === "object"
      ) {
        return (
          <>
            {/* <Text>{JSON.stringify(props.field.value)}</Text> */}
            <select
              key={props.field?.name}
              id={props.field?.name}
              name={props.field?.name}
              {...props.field}
              disabled={props.disabled}
              {...props}
              value={JSON.stringify(props.field?.value)}
              className={classNames(
                `block appearance-none w-full  bg-white border border-slate-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline`,
                props.disabled
                  ? "cursor-not-allowed"
                  : "hover:border-pink-300 cursor-pointer"
              )}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {dropdownData &&
                dropdownData.map((dropdownValue: Object) => (
                  <option
                    key={props.displayKey}
                    value={JSON.stringify(dropdownValue)}
                  >
                    {dropdownValue[props.displayKey]}
                  </option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </>
        );
      }
      // Enum export enum KundenTyp { NEUKUNDE = "NEUKUNDE", GLOBAL = "GLOBAL / UNTERNEHMEN" }
      else if (
        !Array.isArray(props.dropdownData) &&
        typeof props.dropdownData === "object"
      ) {
        return (
          <>
            <select
              key={props.field?.name}
              id={props.field?.name}
              name={props.field?.name}
              {...props.field}
              disabled={props.disabled}
              {...props}
              value={JSON.stringify(props.field?.value)}
              className={classNames(
                `cursor-pointer block appearance-none w-full  bg-white border border-slate-300 px-4 py-2 pr-8 rounded  leading-tight focus:outline-none focus:shadow-outline`,
                props.disabled ? "" : "hover:border-pink-300 cursor-pointer"
              )}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {dropdownData &&
                Object.keys(props.dropdownData).map((key) => (
                  <option
                    key={key}
                    value={JSON.stringify(key)}
                    className="text-gray-700 "
                  >
                    {props.dropdownData[key]}
                  </option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </>
        );
      } else if (props.dropdownData && props.dropdownData.length === 0) {
        return (
          <Text className="font-medium text-sm text-gray-400 font-sans border border-slate-300 bg-slate-50 rounded-lg block w-full p-2.5 leading-tight focus:outline-none ">
            {props.noDataText || "Keine Daten"}
          </Text>
        );
      } else {
        <Text>
          {`DROPDOWN DATA für ${props.label
            } ist ungültig!. Ungültiger TYP " + ${typeof props.dropdownData}`}
        </Text>;
      }
    }
  };

  useEffect(() => {
    if (
      props &&
      (props.mode === "dropdown" || props.mode === "textAndDropdown") &&
      props.dropdownData &&
      props.dropdownData.length > 0
    ) {
      if (props.addPleaseSelectOption) {
        if (props.dropdownData && Array.isArray(props.dropdownData)) {
          if (typeof props.dropdownData[0] === "string") {
            setDropdownData([
              "Bitte auswählen",
              ...(props.dropdownData as string[]),
            ]);
          } else if (typeof props.dropdownData[0] === "object") {
            setDropdownData([
              { [props.displayKey]: "Bitte auswählen" },
              ...props.dropdownData,
            ]);
          } else {
            console.error(
              "DROPDOWN DATA für " +
              props.label +
              " ist ungültig!. Ungültiger TYP " +
              typeof props.dropdownData
            );
          }
        }
      } else {
        setDropdownData(props.dropdownData);
      }
    } else {
      setDropdownData([]);
    }
    if (!props.field) {
      console.log(
        "Es wurde kein FIELD angebunden an " + props.label + " " + props.mode
      );
    }
  }, [props.dropdownData]);

  return (
    <View
      className={classNames(
        props.viewClassName || "mt-5 ml-3",
        props.grow ? "grow" : "",
        props.shrink ? "shrink" : ""
      )}
    >
      {props.label && (
        <label
          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
          htmlFor={props.field?.name || props.label}
        >
          {props.label}
        </label>
      )}

      <View
        className={classNames(
          `flex flex-row items-center`,
          props.mode === "textAndDropdown"
            ? "dropdown-container-box static"
            : ""
        )}
      >
        {(props.mode === "text" || props.mode === "textAndDropdown") && (
          <View className={classNames("w-full static")}>
            <View className="flex flex-row">
              <input
                key={props.field?.name}
                className={classNames(
                  ` static font-sans border border-stone-300 text-gray-900 text-sm rounded-md block w-full p-2.5 leading-tight focus:outline-none `,
                  props.mode === "textAndDropdown"
                    ? "border-r-1 rounded-r-none"
                    : "",
                  props.inputClassName,
                  props.disabled
                    ? "bg-white cursor-not-allowed"
                    : "focus:border-pink-300 hover:border-pink-300",
                  hasError() ? "border-red-400 hover:border-red-500" : ""
                )}
                style={{ maxHeight: "41px", color: props.disabled ? "#00000061" : "" }}
                defaultValue={props.defaultValue}
                id={props.field?.name}
                name={props.field?.name}
                {...props.field}
                type={props.type || "text"}
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                disabled={props.disabled}
              ></input>
              {props.mode === "textAndDropdown" && (
                <>
                  <div
                    className={classNames(
                      "border border-slate-300 border-l-0 px-0.5 pt-0.5 rounded-r-lg",
                      props.disabled || !dropdownData?.length
                        ? "cursor-not-allowed bg-gray-50"
                        : " cursor-pointer"
                    )}
                    onClick={(e) => {
                      if (!props.disabled && dropdownData?.length) {
                        handleToggleDropdown(e);
                      }
                    }}
                  >
                    <EvilIcons
                      name={popperIsOpen ? "chevron-up" : "chevron-down"}
                      size={32}
                      color={
                        props.disabled || !dropdownData?.length
                          ? "#e3e3e3"
                          : "black"
                      }
                    />
                  </div>

                  {/* <ClickAwayListener onClickAway={handleClickAway}>
                    <Popper open={popperIsOpen} anchorEl={anchorEl} transition style={{zIndex: 999999999999}}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={300}>
                          <ul
                            className="absolute bg-white shadow-lg p-1 rounded-xl border border-gray-200"
                            style={{ minWidth: "200px", right: -17 }}
                          >
                            {dropdownData?.map((option, index) => (
                              <li
                                {...props.field}
                                className={classNames(
                                  props.disabled
                                    ? "cursor-not-allowed"
                                    : "hover:bg-pink-500 hover:text-white p-2 cursor-pointer font-semibold rounded-lg"
                                )}
                                key={index}
                                onClick={() => {
                                  handleSelectOption(option);
                                  // @ts-ignore
                                  props.onClick(option);
                                }}
                              >
                                <span className="text-sm">
                                  {option as string}</span>
                              </li>
                            ))}
                          </ul>
                        </Fade>
                      )}
                    </Popper>
                  </ClickAwayListener> */}

                  <div hidden={!popperIsOpen} onBlur={() => setIsOpen(false)}>
                    <div className="border border-gray-50 bg-gray-100">
                      {dropdownData?.map((option, index) => (
                        <div
                          key={index}
                          className="hover:bg-pink-500 hover:text-white p-2 cursor-pointer font-semibold rounded-lg"
                          onClick={() =>  {
                            handleSelectOption(option);
                            // @ts-ignore
                            props.onClick(option)
                          }}
                        >
                          {option as string}
                        </div>
                      ))}
                    </div>


                    {/* <Menu
                      id={props.field}
                      anchorEl={anchorEl}
                      open={popperIsOpen}
                      style={{ borderRadius: 30 }}
                      onClose={() => {
                        setAnchorEl(null)
                      }}
                    >
                      {dropdownData?.map((option, index) => (
                        <li
                          {...props.field}
                          className={classNames(
                            props.disabled
                              ? "cursor-not-allowed"
                              : "hover:bg-pink-500 hover:text-white p-0 cursor-pointer font-semibold rounded-lg"
                          )}
                          key={index}
                        >
                          <MenuItem onClick={() => {
                            handleSelectOption(option);
                            // @ts-ignore
                            props.onClick(option)
                          }}
                          >{option as string}</MenuItem>
                        </li>
                      ))}
                    </Menu> */}
                  </div>
                </>
              )}
            </View>

            {hasError() ? (
              <div
                style={{ fontSize: 9 }}
                className="block uppercase tracking-wide text-red-500 font-bold mt-1 ml-0.5"
              >
                {field && props.form?.errors[field.name]}
              </div>
            ) : null}
          </View>
        )}

        {props.mode === "textarea" && (
          <View className="w-full">
            <textarea
              key={props.field?.name}
              className={classNames(
                ` font-sans border border-slate-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 leading-tight focus:outline-none form-control`,
                props.inputClassName,
                props.disabled
                  ? "bg-gray-50 cursor-not-allowed"
                  : "focus:border-pink-300 hover:border-pink-300",
                hasError() ? "border-red-400 hover:border-red-500" : ""
              )}
              id={props.field?.name}
              name={props.field?.name}
              {...props.field}
              type={props.type || "text"}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              disabled={props.disabled}
              rows={props.rows || 6}
            ></textarea>
            {hasError() ? (
              <div
                style={{ fontSize: 9 }}
                className="block uppercase tracking-wide text-red-500 font-bold mt-1 ml-0.5"
              >
                {field && props.form?.errors[field.name]}
              </div>
            ) : null}
          </View>
        )}

        {props.mode === "dropdown" && (
          <div
            className={classNames(
              "inline-block relative",
              props.inputClassName
            )}
          >
            <View>
              {getSelectInput()}
              {props.field &&
                field &&
                props.form.errors[field.name] &&
                props.form.touched[field.name] ? (
                <div
                  style={{ fontSize: 9 }}
                  className="block uppercase tracking-wide text-red-500 font-bold mt-1 ml-0.5"
                >
                  {props.form.errors[field.name]}
                </div>
              ) : null}
            </View>
          </div>
        )}

        {props.mode === "date" && (
          <div
            className={classNames(
              "inline-block relative",
              props.inputClassName
            )}
          >
            <View>
              <DatePicker
                key={props.field?.name}
                locale="de"
                dateFormat="P"
                popperPlacement="top"
                selected={
                  (props?.field.value && new Date(props.field.value)) || null
                }
                id={props.field?.name}
                name={props.field?.name}
                disabled={props.disabled}
                {...props.field}
                customInput={
                  <input
                    disabled={props.disabled}
                    onChange={(e) => console.log(e)}
                    contentEditable={false}
                    className={classNames(
                      `block appearance-none w-full  bg-white border border-slate-300 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline`,
                      props.disabled
                        ? ""
                        : "hover:border-pink-300 cursor-pointer"
                    )}
                  />
                }
              />
              {props.field &&
                field &&
                props.form.errors[field.name] &&
                props.form.touched[field.name] ? (
                <div
                  style={{ fontSize: 9 }}
                  className="block uppercase tracking-wide text-red-500 font-bold mt-1 ml-0.5"
                >
                  {props.form.errors[field.name]}
                </div>
              ) : null}
            </View>
          </div>
        )}
      </View>

      {props.description && (
        <Text
          className="ml-auto font-light text-xs text-gray-400"
          onPress={() =>
            props?.onDescriptionClick && props?.onDescriptionClick()
          }
        >
          {props.description}
        </Text>
      )}
    </View>
  );
}

ClientTextInput.defaultProps = {
  mode: "text",
  type: "text",
  addPleaseSelectOption: true,
  maxLength: 9999
};

export default ClientTextInput;
