import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  CompanyState,
  GetAllSignRequestsQuery,
  OrderState,
  SignRequest,
  SignRequestType,
  useCreateContractDocumentsMutation,
  useCreateOrderDocumentsMutation,
  useCreateSignRequestMutation,
  useDisableSignRequestMutation,
  useGetAllSignRequestsQuery,
  useRepeatSignRequestMutation,
  useUpdateCompanyMutation,
  useUpdateCompanyStateMutation,
  useUpdateOrderMutation,
  useUpdateOrderStateMutation,
  useUpdateSignRequestMutation,
} from "../../../__generated__/graphql-types";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  decisionTableIcon,
  enumToArray,
  transformDate,
} from "../../../helpers";
import { IconButton, typographyClasses } from "@mui/material";
import { MySwal } from "../../../../WebApp";
import { SignatureReviewModal } from "../web-modals/SignatureReview.modal";
import NiceModal from "@ebay/nice-modal-react";
import { Feather } from "@expo/vector-icons";

export default function AdminSignRequestsReactions() {
  const columns = useMemo<
    MRT_ColumnDef<GetAllSignRequestsQuery["getAllSignRequests"][0]>[]
  >(
    () => [
      {
        accessorFn: (row) => row.requestType,
        header: "Typ",
        minSize: 20,
        filterSelectOptions: enumToArray(SignRequestType),
        filterVariant: "multi-select",
        Cell({ cell, column, row, table }) {
          let value = cell.getValue<SignRequestType>();
          return (
            <Text
              className={`p-1.5 bg-gray-200 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
            >
              {value === SignRequestType.Contract && "Vertrag"}
              {value === SignRequestType.ContractOnSide && "Vertrag vor Ort"}
              {value === SignRequestType.Order && "Bestellung"}
              {value === SignRequestType.OrderOnSide && "Bestellung vor Ort"}
            </Text>
          );
        },
      },
      {
        accessorFn: (row) => row.signed,
        header: "Unterschrieben?",
        size: 10,
        Cell({ cell, column, row, table }) {
          if (row.original.disabled) {
            return (
              <Text
                className={`p-1.5 bg-gray-200 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                {`Deaktiviert: ${row.original.disabledReason}`}
              </Text>
            );
          } else if (row.original.signed && row.original.signedAt) {
            return (
              <Text
                className={`p-1.5 bg-teal-100 text-teal-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                {`Ja, ${transformDate(row.original.signedAt)}`}
              </Text>
            );
          } else {
            return (
              <Text className={`p-1.5 bg-red-100 text-red-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}>
                Nein
              </Text>
            );
          }
        },
      },
      {
        accessorFn: (row) => row.signsAccepted,
        header: "Akzeptiert/Abgelehnt?",
        size: 10,
        Cell({ cell, column, row, table }) {
          if (row.original.signsAccepted) {
            return (
              <Text
                className={`p-1.5 bg-teal-100 text-teal-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                {`Akzeptiert, am ${transformDate(
                  row.original.signsAcceptedAt
                )}`}
              </Text>
            );
          } else if (row.original.signsAccepted === false) {
            return (
              <Text
                className={`p-1.5 bg-yellow-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                Abgelehnt
              </Text>
            );
          } else if (row.original.signed === false) {
            return (
              <Text
                className={`p-1.5 bg-gray-100 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                Nicht unterschrieben
              </Text>
            );
          }
          else if (row.original.signed === true) {
            return (
              <Text
                className={`p-1.5 bg-gray-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center`}
              >
                Nicht überprüft
              </Text>
            );
          }
        },
      },
      {
        accessorFn: (row) => transformDate(row.createdAt),
        header: "1. Anfrage am"
      },
      {
        accessorFn: (row) =>
          row.reminderSendedAt ? transformDate(row.reminderSendedAt) : "-",
        header: "Letzte Erinnerung am",
      },
      {
        accessorFn: (row) => row.reminderMailCounter,
        header: "Anzahl Erinnerungen",
      },
      {
        accessorFn: (row) =>
          row.requestType === SignRequestType.Order
            ? row.order?.rechnungsadresse?.firma
            : row.company?.betreiberDetails.firma,
        header: "Rechnungsadresse / Betreiber",
      },
    ],
    []
  );

  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    data: signRequests,
    error,
    loading,
    refetch: refetchRequests,
  } = useGetAllSignRequestsQuery();

  const [repeatSignRequest] = useRepeatSignRequestMutation();
  const [updateCompany] = useUpdateCompanyMutation();
  const [createContractDocuments] = useCreateContractDocumentsMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [createOrderDocuments] = useCreateOrderDocumentsMutation();
  const [updateSignRequest] = useUpdateSignRequestMutation();
  const [disableSignRequest] = useDisableSignRequestMutation();
  const [updateCompanyState] = useUpdateCompanyStateMutation();
  const [updateOrderState] = useUpdateOrderStateMutation();

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white mt-2">
      <MaterialReactTable
        enableRowActions
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={signRequests?.getAllSignRequests || []}
        state={{ showSkeletons: loading }}
        columns={columns as any}
        initialState={{ density: "compact" }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Aktionen",
            size: 100, //make actions column wider
          },
        }}
        renderRowActions={({ row, table }) => (
          <View className="flex flex-row flex-nowrap gap-1">
            {row.original.signed &&
              row.original.signsAccepted === null &&
              row.original.requestType !== SignRequestType.OrderOnSide &&
              row.original.requestType !== SignRequestType.ContractOnSide && (
                <IconButton
                  title="Unterschriften überprüfen"
                  onClick={async () => {
                    let signatureReviewModelResult = (await NiceModal.show(
                      SignatureReviewModal,
                      {
                        signRequest: row.original,
                      }
                    )) as {
                      resolved: boolean;
                      signsAccepted: boolean;
                    };

                    if (!signatureReviewModelResult.resolved) {
                      return;
                    }

                    if (signatureReviewModelResult.signsAccepted) {
                      if (row.original.requestType === SignRequestType.Contract) {
                        await updateCompany({
                          variables: {
                            id: row.original.company!._id,
                            updateCompanyInput: {
                              signs: row.original.signs,
                            },
                          },
                        });
                        await createContractDocuments({
                          variables: {
                            createContractDocumentsInput: {
                              companyId: row.original.company!._id,
                              upload: true,
                            },
                          },
                        });
                      } else if (row.original.requestType === SignRequestType.Order) {
                        await updateOrder({
                          variables: {
                            id: row.original.order!._id,
                            updateOrderInput: {
                              signs: row.original.signs,
                            },
                          },
                        });

                        await createOrderDocuments({
                          variables: {
                            createOrderDocumentsInput: {
                              orderId: row.original.order!._id,
                              upload: true,
                            },
                          },
                        });
                      }

                      await updateSignRequest({
                        variables: {
                          id: row.original._id,
                          updateSignRequestInput: {
                            signsAccepted: true,
                          },
                        },
                      });

                    } else {
                      if (row.original.requestType === SignRequestType.Contract) {
                        await updateCompanyState({
                          variables: {
                            id: row.original.company!._id,
                            state: CompanyState.REJECTED,
                            rejectedInformation: {
                              isContractRelevant: true,
                              reason: "Unterschriften abgelehnt!",
                            },
                          },
                        });
                      } else if (row.original.requestType === SignRequestType.Order) {
                        await updateOrderState({
                          variables: {
                            orderId: row.original.order!._id,
                            state: OrderState.REJECTED,
                          },
                        });
                      }

                      await updateSignRequest({
                        variables: {
                          id: row.original._id,
                          updateSignRequestInput: {
                            signsAccepted: false
                          }
                        }
                      });

                      addToast("Du hast die Unterschriften abgelehnt. Beantrage die Unterschriften erneut per QR-Code oder E-Mail.", { appearance: "info" });
                    }
                    await refetchRequests();
                  }}
                >
                  {decisionTableIcon()}
                </IconButton>
              )}

            {(row.original.requestType === SignRequestType.Order ||
              row.original.requestType === SignRequestType.OrderOnSide) && (
                <IconButton
                  title="Bestellung ansehen"
                  onClick={() => {
                    navigate(`/client/orders/${row.original?.order?._id}`);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#1C274C"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </IconButton>
              )}

            {(row.original.requestType === SignRequestType.Contract ||
              row.original.requestType === SignRequestType.ContractOnSide) && (
                <IconButton
                  title="Unternehmen anzeigen"
                  onClick={() => {
                    row?.original &&
                      row.original.company &&
                      navigate(`/client/overview/${row.original.company._id}`);
                  }}
                >
                  <Feather name="user" size={24} color="#1C274C" />
                </IconButton>
              )}

            {(!row.original.signed && !row.original.disabled) && (
              <IconButton
                title={`Erinnerung schicken an ${row.original.email}`}
                onClick={async () => {
                  try {
                    await repeatSignRequest({
                      variables: {
                        repeatSignRequestInput: {
                          _id: row.original._id,
                          isRepeat: true,
                          email: row.original.email,
                        },
                      },
                    });
                    await refetchRequests();
                    addToast(
                      `Erinnerung an ${row.original.email} erfolgreich verschickt!`
                    );
                  } catch (error) {
                    addToast(
                      "FEHLER: Erinnerung konnte nicht verschickt werden! " +
                      error,
                      { appearance: "error" }
                    );
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#1C274C"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </IconButton>
            )}

            {(!row.original.signed && !row.original.disabled) &&
              <IconButton
                title="Anfrage deaktivieren"
                onClick={async () => {
                  try {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Anfrage deaktivieren`,
                      html: `Das Unternehmen kann dann nicht mehr unterschreiben. Der Vertrag oder die Bestellung müssen manuell dann entfernt werden.
                      <br><br>Möchtest du diese Änderung wirklich vornehmen?
                      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      input: "text",
                      inputPlaceholder: 'Grund:',
                      inputAttributes: {
                        autocapitalize: "off",
                      },
                      width: 700,
                      confirmButtonText: `Ja, Anfrage deaktivieren!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    if (!swalResult.value) {
                      addToast("Ein Grund ist erforderlich! " + error, { appearance: "error", })
                      return;
                    }

                    try {
                      await disableSignRequest({
                        variables: {
                          id: row.original._id,
                          disableSignRequestInput: {
                            disabled: true,
                            disabledReason: swalResult.value
                          }
                        }
                      });
                    } catch (error) {
                      addToast("FEHLER: " + error, { appearance: "error", })
                    }
                    addToast("Anfrage erfolgreich deaktiviert!", { appearance: "success" });
                    await refetchRequests();
                  } catch (error) {
                    addToast("FEHLER: Anfrage konnte nicht deaktiviert werden." + error, { appearance: "error", })
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#dc2626" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </IconButton>
            }
          </View>
        )}
        renderTopToolbarCustomActions={() => (
          <div className="flex flex-row items-center">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Unterschriften - Kundenreaktionen
            </h2>

            <div className="ml-3"></div>
          </div>
        )}
      ></MaterialReactTable>
    </View>
  );
}
