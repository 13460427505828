import { AntDesign, Entypo, FontAwesome, FontAwesome5, Fontisto, Ionicons, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import { ReactElement } from "react";
import { PropertyFeatures } from "./__generated__/graphql-types";

export type AsyncFunction = () => Promise<void>;
export type SyncFunction = () => void;

export interface CompanyExtraOption {
  formName: keyof PropertyFeatures;
  name: string;
  icon: ReactElement;
}

export const textvorlage_2_extras = [
  {
    name: "Parkmöglichkeit",
    formName: "parking",
    icon: <FontAwesome5 name="parking" size={22} color="#ec4899" />,
  },
  {
    name: "Biergarten/Terasse",
    formName: "beer_garden_terrace",
    icon: <Ionicons name="beer-outline" size={22} color="#ec4899" />,
  },
  {
    name: "Vegetarisches",
    formName: "vegetarian",
    icon: (
      <MaterialCommunityIcons name="cow-off" size={22} color="#ec4899" />
    ),
  },
  {
    name: "Spielplatz: Innen",
    formName: "playground_indoor",
    icon: <SimpleLineIcons name="game-controller" size={22} color="#ec4899" />,
  },
  {
    name: "Spielplatz: Außen",
    formName: "playground_outdoor",
    icon: <MaterialCommunityIcons name="slide" size={22} color="#ec4899" />,
  },
  {
    name: "Toiletten",
    formName: "accessible",
    icon: <FontAwesome name="wheelchair" size={25} color="#ec4899" />,
  },
  {
    name: "Hunde erlaubt",
    formName: "dogs_allowed",
    icon: <FontAwesome5 name="dog" size={22} color="#ec4899" />,
  },
  {
    name: "Heimservice",
    formName: "home_delivery",
    icon: <MaterialIcons name="delivery-dining" size={22} color="#ec4899" />,
  },
  {
    name: "Festsaal",
    formName: "event_hall",
    icon: (
      <MaterialCommunityIcons name="dance-ballroom" size={22} color="#ec4899" />
    ),
  },
  {
    name: "Partyservice",
    formName: "party_service",
    icon: (
      <MaterialCommunityIcons name="party-popper" size={22} color="#ec4899" />
    ),
  },
  {
    name: "Kontaktlos bezahlen",
    formName: "nfc_supported",
    icon: <MaterialCommunityIcons name="nfc" size={22} color="#ec4899" />,
  },
  {
    name: "EC-Karte",
    formName: "debit_card_payment",
    icon: <AntDesign name="creditcard" size={22} color="#ec4899" />,
  },
  {
    name: "Kreditkarte",
    formName: "credit_card_payment",
    icon: <FontAwesome name="cc-visa" size={22} color="#ec4899" />,
  },
  {
    name: "PayPal",
    formName: "paypal",
    icon: <SimpleLineIcons name="paypal" size={22} color="#ec4899" />,
  },
  {
    name: "Apple Pay",
    formName: "apple_pay",
    icon: <FontAwesome5 name="apple-pay" size={22} color="#ec4899" />,
  },
  {
    name: "Google Pay",
    formName: "google_pay",
    icon: <FontAwesome5 name="google-pay" size={22} color="#ec4899" />,
  },
  {
    name: "Vegan",
    formName: "vegan",
    icon: <Entypo name="flower" size={22} color="#ec4899" />,
  },
  {
    name: "WLAN",
    formName: "wifi",
    icon: <AntDesign name="wifi" size={22} color="#ec4899" />,
  },
  {
    name: "E-Bike Ladestation",
    formName: "e_bike_rental",
    icon: <MaterialIcons name="electric-bike" size={22} color="#ec4899" />,
  },
  {
    name: "E-Auto Lademöglichkeit",
    formName: "e_car_rental",
    icon: <MaterialIcons name="electric-car" size={22} color="#ec4899" />,
  },
  {
    name: "Halal",
    formName: "halal",
    icon: (
      <MaterialCommunityIcons name="food-halal" size={22} color="#ec4899" />
    ),
  },
  {
    name: "BIO",
    formName: "bio",
    icon: <MaterialCommunityIcons name="bio" size={22} color="#ec4899" />,
  },
  {
    name: "Übernachtung",
    formName: "accommodation",
    icon: <MaterialCommunityIcons name="sleep" size={22} color="#ec4899" />,
  },
] as CompanyExtraOption[];
