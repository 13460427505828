import React, { useState } from "react";
import { Text } from "react-native";
import { base64ToBlob, createPdfViewer } from "../../../helpers";
import { Box, Tabs, Tab } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DocumentLinks, Maybe, OrderDocuments } from "../../../__generated__/graphql-types";
import LoadingCircleSVG from "../admin/svg-icons/loading-circle-svg";
import LoadingScreen from "./LoadingScreen";

export const MySwal = withReactContent(Swal);

export default function OrderDocumentsPreview(props: {
    orderDocuments: Maybe<OrderDocuments> | OrderDocuments | undefined;
    height?: number
}) {
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div>
            {!props.orderDocuments && <LoadingScreen>Dokumente werden erstellt.. Bitte warten..</LoadingScreen>}

            <Box className="w-full bg-white rounded-xl">
                <Box className="border-b border-gray-100">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        className="p-1 shadow-sm bg-white select-none rounded-lg"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Rechnung"
                            id={`simple-tab-${0}`}
                            iconPosition="end"
                            icon={
                                !props.orderDocuments ? (
                                    <div className="ml-3">
                                        <LoadingCircleSVG />
                                    </div>
                                ) : undefined
                            }
                        ></Tab>
                        <Tab
                            label="Bestellformular"
                            id={`simple-tab-${1}`}
                            iconPosition="end"
                            icon={
                                !props.orderDocuments ? (
                                    <div className="ml-3">
                                        <LoadingCircleSVG />
                                    </div>
                                ) : undefined
                            }
                        ></Tab>
                    </Tabs>
                </Box>

                {/* Vertrag */}
                <div
                    role="tabpanel"
                    hidden={tabValue !== 0}
                    id={`simple-tabpanel-${0}`}
                    aria-labelledby={`simple-tab-${0}`}
                >
                    {props.orderDocuments?.orderInvoice ? (
                        <div>{createPdfViewer(props.orderDocuments?.orderInvoice, true, props.height || 630)}</div>
                    ) : (
                        <div className="ml-3 flex">
                            <LoadingCircleSVG />
                            <div>Rechnung wird generiert...</div>
                        </div>
                    )}
                </div>

                {/* Beratungsprotokoll */}
                <div
                    role="tabpanel"
                    hidden={tabValue !== 1}
                    id={`simple-tabpanel-${1}`}
                    aria-labelledby={`simple-tab-${1}`}
                >
                    {props.orderDocuments?.orderConfirmation ? (
                        <div>{createPdfViewer(props.orderDocuments?.orderConfirmation, true, props.height || 630)}</div>
                    ) : (
                        <div className="ml-3 flex">
                            <LoadingCircleSVG />
                            <div>Bestellbestätigung wird generiert...</div>
                        </div>
                    )}
                </div>
            </Box>
        </div>
    );
}
