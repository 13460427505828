import React, { useEffect, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import {
    Company,
    Order,
    SignRequest,
    SignRequestType,
    useCreateSignRequestMutation,
    useGetSignRequestQuery,
} from "../../../__generated__/graphql-types";
import "react-datepicker/dist/react-datepicker.css";
import { useToasts } from "react-toast-notifications";
import { QRCodeSVG } from "qrcode.react";
import LoadingScreen from "../web-components/LoadingScreen";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props}></Slide>;
});

export const SignatureQrCodeModal = NiceModal.create((props: {
    company?: Company,
    order?: Order,
    requestType: SignRequestType
}) => {

    const [signRequest, setSignRequest] = useState<SignRequest | undefined>();
    const [errorText, setErrorText] = useState<string>("");
    const modal = useModal();
    const { addToast } = useToasts();
    const [createSignRequest, { loading: signRequestLoading, error: signRequestError }] = useCreateSignRequestMutation();
    const getSignRequest = useGetSignRequestQuery();

    useEffect(() => {
        (async () => {
            try {
                let signRequest = await createSignRequest({
                    variables: {
                        createSignRequestInput: {
                            company: props.company,
                            order: props.order,
                            requestType: props.requestType
                        },
                    },
                });
                console.log(signRequest.data?.createSignRequest.signLink)
                setSignRequest(signRequest?.data?.createSignRequest);
            } catch (error) {
                addToast("FEHLER: Anfrage mit dem QR-Code konnte nicht erstellt werden! " + error, { appearance: "error" })
            }
        })();
    }, [])

    return (
        <Dialog
            TransitionComponent={Transition}
            fullWidth
            maxWidth={"sm"}
            open={modal.visible}
            onClose={() => {
                modal.resolve({ resolved: false });
                modal.hide();
            }}
            TransitionProps={{
                onExited: () => {
                    modal.resolve({ resolved: false });
                    modal.remove();
                },
            }}
        >
            <DialogTitle>
                <div className="flex flex-row justify-between">
                    <h6 className="font-bold text-gray-900">
                        Unterschriften hinzufügen
                    </h6>
                    <div className="cursor-pointer" onClick={() => {
                        modal.resolve({ resolved: false });
                        modal.hide();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>

                {(signRequestError && !signRequest) && <div className="text-red-400 font-bold">FEHLER: Anfrage kann nicht bearbeitet werden! Versuche es später erneut.</div>}

                {signRequestLoading && <LoadingScreen>QR-Code wird erstellt...</LoadingScreen>}

                {signRequest && (
                    <div>
                        <div className="text-sm text-center">Scanne den QR-Code mit deinem Endgerät!</div>
                        <div className="text-sm text-center">Betätige den Button unten, nachdem der Kunde unterschrieben hat.</div>
                        <div className="mt-5">
                            <QRCodeSVG
                                value={signRequest.signLink}
                                size={128}
                                style={{ marginLeft: "auto", marginRight: "auto" }}
                                bgColor={"#ffffff"}
                                fgColor={"#000000"}
                                level={"L"}
                                includeMargin={false}
                            />
                        </div>
                    </div>
                )}

                {errorText && <div className="text-red-600 font-bold text-sm text-center mt-5">{errorText}</div>}

            </DialogContent>
            <DialogActions>
                {signRequest && (
                    <Button
                        onClick={async () => {

                            let updatedSignRequest = await getSignRequest.fetchMore({
                                variables: {
                                    getSignRequestInput: {
                                        requestId: signRequest._id
                                    }
                                }
                            });

                            if (!updatedSignRequest.data.getSignRequest?.signed) {
                                setErrorText("Die Unterschriften wurden noch nicht hochgeladen!")
                                return;
                            }

                            modal.resolve({
                                resolved: true,
                                signRequestId: signRequest._id
                            });
                            modal.hide();
                        }}
                        color="success"
                        variant="outlined"
                    >
                        KUNDE HAT UNTERSCHRIEBEN
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
);
