import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from 'reselect';
import { IRootState } from "../store";
import { Subscription } from "../../__generated__/graphql-types";

export interface AppUser {
  isLoggedIn: boolean;
  subscription: Subscription | null;
  currentAppUser?: { _id: string, username: string, email: string } | undefined | null;
  access_token?: string;
}

const initialState: AppUser = {
  isLoggedIn: false,
  subscription: null,
  currentAppUser: undefined,
  access_token: undefined,
}

export interface AppUserPayload {
  currentAppUser?: { _id: string, username: string, email: string } | undefined | null;
  access_token?: string;
  isLoggedIn?: boolean;
}

export const appUserSliceNative = createSlice({
  name: 'appUser',
  initialState,
  reducers: {
    setSubscriptionData: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action?.payload;
    },
    loginAppUser: (state, action: PayloadAction<AppUserPayload>) => {
      state.isLoggedIn = true;
      state.currentAppUser = action.payload.currentAppUser;
      state.access_token = action.payload.access_token;
    },
    logoutAppUser: (state) => {
      state.isLoggedIn = false;
      state.currentAppUser = undefined;
      state.subscription = null;
      state.access_token = undefined;
    }
  }
})

// Reselect-Selektoren
const selectAppUserState = (root: IRootState) => root.appUser;

export const selectAppUser = createSelector(
  [selectAppUserState],
  (appUser) => ({
    currentAppUser: appUser.currentAppUser,
    access_token: appUser.access_token,
    isLoggedIn: appUser.isLoggedIn,
    subscription: appUser.subscription
  })
);

export const appUserSliceNativeReducer = appUserSliceNative.reducer;
export const {
  loginAppUser,
  logoutAppUser,
  setSubscriptionData
} = appUserSliceNative.actions;