import React from "react";
import "../styles.css";
import { useSelector } from "react-redux";
import { selectWebUser } from "../../../store/web-slices/userSlice.web";
import {
  createHttpLink,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
//@ts-ignore
import apolloLogger from "apollo-link-logger";

export default function Test(props: any) {
  return <>{props.children}</>;
}
