import React, { useEffect, useMemo, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Slide,
    TextField,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import * as yup from "yup";
import { Formik } from "formik";
import {
    AddOrderPaymentInput,
    GetAllSignRequestsQuery,
    Order,
    OrderPosition,
    SignRequest,
    SignRequestType,
    useGetOrderByIdQuery,
    useUpdateOrderPositionMutation,
} from "../../../__generated__/graphql-types";
import "react-datepicker/dist/react-datepicker.css";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { formatNumberToCurrency } from "../../../helpers";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { Feather } from "@expo/vector-icons";
import { useToasts } from "react-toast-notifications";
import ContractDocumentsPreview from "../web-components/ContractDocumentsPreview";
import SignPads from "../web-components/SignPads";
import DialogIcon from "react-native-paper/lib/typescript/src/components/Dialog/DialogIcon";
import OrderDocumentsPreview from "../web-components/OrderDocumentsPreview";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props}></Slide>;
});

export const SignatureReviewModal = NiceModal.create((props: {
    signRequest: GetAllSignRequestsQuery["getAllSignRequests"][0]
}) => {
    const modal = useModal();
    const { addToast } = useToasts();

    console.log('SignatureReviewModal ', props.signRequest)

    return (
        <Dialog
            keepMounted
            TransitionComponent={Transition}
            fullWidth
            maxWidth={"lg"}
            open={modal.visible}
            onClose={() => {
                modal.resolve({ resolved: false });
                modal.hide();
            }}
            TransitionProps={{
                onExited: () => {
                    modal.resolve({ resolved: false });
                    modal.remove();
                },
            }}
        >
            <DialogTitle>
                <div className="flex flex-row justify-between">
                    <h6 className="font-bold text-gray-900">
                        Externe Unterschriften für {props.signRequest.requestType === SignRequestType.Contract
                            ? `Vertrag ${props.signRequest.company?.betreiberDetails.firma}`
                            : `Bestellung #${props.signRequest.order?.orderNumber} - ${props.signRequest.order?.rechnungsadresse?.firma}`}
                    </h6>
                    <div className="cursor-pointer" onClick={() => {
                        modal.resolve({ resolved: false });
                        modal.hide();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="bg-white p-6 m-3 rounded-xl shadow-md">
                    <SignPads
                        signPadsInput={{
                            lettersPad: {
                                showPad: true,
                                disabled: true,
                                description: "Vorname & Nachname in Druckbuchstaben",
                                signLink: props.signRequest.signs.signatureLetters,
                                signDate: props.signRequest.signedAt
                            },
                            signPad: {
                                showPad: true,
                                disabled: true,
                                description: "Unterschrift",
                                signLink: props.signRequest.signs.signatureSign,
                                signDate: props.signRequest.signedAt
                            },
                            submitButtonConfig: {
                                show: false
                            }
                        }}
                        onSubmit={async (signResult) => {
                            console.log(signResult)
                            try {
                                // TODO: vertrag /bestellung erstellen mit den hinterlegten unterschriften 
                            } catch (error) {
                                console.error(error);
                                addToast("FEHLER: " + error, { appearance: "error" })
                            }
                        }}
                    ></SignPads>
                </div>
                {props.signRequest.requestType === SignRequestType.Contract && (
                    <ContractDocumentsPreview contractDocuments={props.signRequest.company?.documentLinks} authorityRequired={props.signRequest.company?.beraterKundendatenDetails?.authorityRequired}></ContractDocumentsPreview>
                )}
                {props.signRequest.requestType === SignRequestType.Order && (
                    <OrderDocumentsPreview orderDocuments={props.signRequest.order?.documents} height={444}></OrderDocumentsPreview>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        modal.resolve({ resolved: true, signsAccepted: false });
                        modal.hide();
                    }}
                    color="error"
                    variant="outlined"
                >
                    Unterschriften ablehnen
                </Button>
                <Button
                    onClick={async () => {
                        modal.resolve({ resolved: true, signsAccepted: true });
                        modal.hide();
                    }}
                    color="success"
                    variant="outlined"
                >
                    Unterschriften akzeptieren
                </Button>
            </DialogActions>
        </Dialog>
    )
}
);
