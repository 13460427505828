import { SimpleLineIcons } from "@expo/vector-icons";
import React from "react";

export default class WebErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error: error, errorInfo: errorInfo });
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const { error, errorInfo } = this.state;
      const stackLines = (errorInfo && errorInfo.componentStack) || "";

      // Teilen Sie den componentStack-String in einzelne Zeilen auf
      const stackTrace = stackLines.split("\n");

      // Wählen Sie nur die ersten fünf Zeilen aus
      const firstFiveLines = stackTrace.slice(0, 10).join("\n");

      return (
        <div className="p-8">
          <div className="text-red-500 font-bold tracking-widest flex flex-row">
            <SimpleLineIcons name="exclamation" size={29} color="red" />
            <h1 className="ml-3 text-lg">
              Es ist ein unerwarteter Fehler aufgetreten.
            </h1>
          </div>
          <div className="border-b border border-red-500 mt-3 mb-3 max-w-300px"></div>

          <div className="text-sm">
            <p>{error && error.toString()}</p>
            <p>Weitere Informationen:</p>
            <pre>{firstFiveLines}</pre>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}