import React, { useState } from "react";
import { Text } from "react-native";
import { base64ToBlob, createPdfViewer } from "../../../helpers";
import { Box, Tabs, Tab } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DocumentLinks } from "../../../__generated__/graphql-types";
import LoadingCircleSVG from "../admin/svg-icons/loading-circle-svg";
import LoadingScreen from "./LoadingScreen";

export const MySwal = withReactContent(Swal);

export default function ContractDocumentsPreview(props: {
    contractDocuments: DocumentLinks | undefined;
    authorityRequired: boolean | undefined;
}) {
    const [tabValue, setTabValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <div>
            {!props.contractDocuments && <LoadingScreen>Dokumente werden erstellt.. Bitte warten..</LoadingScreen>}

            <Box className="w-full mt-5">
                <Box className="border-b border-gray-100">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        className="p-1 shadow-sm bg-gray-50 select-none rounded-lg"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Vertrag & Erfüllungsorte"
                            id={`simple-tab-${0}`}
                            iconPosition="end"
                            icon={
                                !props.contractDocuments ? (
                                    <div className="ml-3">
                                        <LoadingCircleSVG />
                                    </div>
                                ) : undefined
                            }
                        ></Tab>
                        <Tab
                            label="Beratungsprotokoll"
                            id={`simple-tab-${1}`}
                            iconPosition="end"
                            icon={
                                !props.contractDocuments ? (
                                    <div className="ml-3">
                                        <LoadingCircleSVG />
                                    </div>
                                ) : undefined
                            }
                        ></Tab>
                        {props.authorityRequired && (
                            <Tab
                                label="Vollmacht"
                                id={`simple-tab-${2}`}
                                iconPosition="end"
                                icon={
                                    !props.contractDocuments ? (
                                        <div className="ml-3">
                                            <LoadingCircleSVG />
                                        </div>
                                    ) : undefined
                                }
                            ></Tab>
                        )}
                    </Tabs>
                </Box>

                {/* Vertrag */}
                <div
                    role="tabpanel"
                    hidden={tabValue !== 0}
                    id={`simple-tabpanel-${0}`}
                    aria-labelledby={`simple-tab-${0}`}
                >
                    {props.contractDocuments?.contract ? (
                        <div>{createPdfViewer(props.contractDocuments?.contract)}</div>
                    ) : (
                        <div className="ml-3 flex">
                            <LoadingCircleSVG />
                            <div>Vertrag wird generiert...</div>
                        </div>
                    )}
                </div>

                {/* Beratungsprotokoll */}
                <div
                    role="tabpanel"
                    hidden={tabValue !== 1}
                    id={`simple-tabpanel-${1}`}
                    aria-labelledby={`simple-tab-${1}`}
                >
                    {props.contractDocuments?.adviceProtocol ? (
                        <div>{createPdfViewer(props.contractDocuments?.adviceProtocol)}</div>
                    ) : (
                        <div className="ml-3 flex">
                            <LoadingCircleSVG />
                            <div>Beratungsprotokoll wird generiert...</div>
                        </div>
                    )}
                </div>

                {/* Vollmacht */}
                <div
                    role="tabpanel"
                    hidden={tabValue !== 2}
                    id={`simple-tabpanel-${2}`}
                    aria-labelledby={`simple-tab-${2}`}
                >
                    {props.contractDocuments?.authority ? (
                        <div>{createPdfViewer(props.contractDocuments?.authority)}</div>
                    ) : (
                        <div className="ml-3 flex">
                            <LoadingCircleSVG />
                            <div>Vollmacht wird generiert...</div>
                        </div>
                    )}
                </div>
            </Box>
        </div>
    );
}
