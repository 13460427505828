import { AntDesign, Feather } from "@expo/vector-icons";
import { Formik, Form, Field } from "formik";
import { useState } from "react";
import { View, Text } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  Company,
  PerformancePlace,
  UpdatePerformancePlacesOpeningHoursInput,
  useUpdatePerformancePlacesOpeningHoursMutation,
} from "../../../../../__generated__/graphql-types";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientButton from "../../../web-components/ClientButton";
import ClientTextInput from "../../../web-components/ClientTextInput";
import { OP_TIMES_DROPDOWN_DATA } from "../../../web-components/dropdown_data";
import { previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import ErfuellungsOrteTabs from "../../../web-components/ErfuellungsOrteTabs";

export default function OpeningHoursForm(props: {
  form: Company;
  contuineAndSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup
    .array()
    .of(
      yup.object({
        extraOpeningHours: yup.string(),
        closedDays: yup.array().of(yup.string()),
        days: yup
          .array()
          .of(
            yup.object().shape({
              fromDay: yup.string(),
              tillDay: yup.string().nullable(),
              start_time_1: yup.string().oneOf(OP_TIMES_DROPDOWN_DATA),
              end_time_1: yup.string().nullable(),
              start_time_2: yup.string().nullable(),
              end_time_2: yup.string().nullable().when("start_time_2", addValidation()),
            })
          )
          .min(1),
      })
    )
    .min(1);

  const weekdays = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];

  const [formData] = useState<Company>(props.form);
  const [currentErfuellungsOrtIndex, setCurrentErfuellungsOrt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const [updateOpeningHours] = useUpdatePerformancePlacesOpeningHoursMutation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentErfuellungsOrt(newValue);
  };

  const getOpeningHours = (values: PerformancePlace["openingHours"][]) => {
    if (!values[currentErfuellungsOrtIndex].days.length) {
      values[currentErfuellungsOrtIndex].days = [
        {
          fromDay: "",
          tillDay: "",
          end_time_1: "",
          end_time_2: "",
          start_time_1: "",
          start_time_2: "",
        },
      ];
    }
    return values[currentErfuellungsOrtIndex];
  };


  return (
    <Formik
      initialValues={
        formData.performancePlaces.map((a) => a.openingHours) || []
      }
      validationSchema={schema}
      validateOnMount
      onSubmit={async (openingHours) => {
        setIsLoading(true);
        try {
          const inputs: UpdatePerformancePlacesOpeningHoursInput[] = openingHours.map((images, index) => ({
            performancePlaceId: formData.performancePlaces[index]._id,
            openingHours: images,
          }));

          let { data } = await updateOpeningHours({ variables: { updatePerformancePlacesOpeningHoursInputs: inputs } })
          props.contuineAndSave();
          addToast("Erfolgreich gespeichert", { appearance: "success" });
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        }
        finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, setFieldValue, errors }) => (
        <Form>
          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}
            <ClientButton
              type="submit"
              onClick={() => ""}
              disabled={!isValid}
              loading={isLoading}
              icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
              </svg>
              }
              label="Speichern & Weiter"
            ></ClientButton>
          </View>

          <AdminFormHeader>Öffnungszeiten</AdminFormHeader>

          <ErfuellungsOrteTabs
            performancePlaces={formData.performancePlaces}
            onChange={handleChange}
          ></ErfuellungsOrteTabs>

          <Field
            component={ClientTextInput}
            name={`[${currentErfuellungsOrtIndex}].extraOpeningHours`}
            label="Sonstige Öffnungszeiten / Frühstückszeiten (optional)"
            mode="text"
          ></Field>

          <div className="ml-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mt-4">
              Ruhetage (optional)
            </label>

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              className="mt-2 border-red-400"
              sx={{
                height: "2.5rem",
                color: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#cbd5e1",
                  borderRadius: 2,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#f9a8d4",
                },
              }}
              value={values[currentErfuellungsOrtIndex].closedDays || []}
              onChange={(e: SelectChangeEvent) => {
                let value = e.target.value;
                setFieldValue(`[${currentErfuellungsOrtIndex}].closedDays`, typeof value === "string" ? value.split(",") : value);
              }}
              fullWidth
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              {weekdays.map((weekDay) => (
                <MenuItem key={weekDay} value={weekDay}>
                  <Checkbox
                    checked={
                      (values[currentErfuellungsOrtIndex].closedDays?.indexOf(
                        weekDay
                      )) > -1
                    }
                  />
                  <span>{weekDay}</span>
                </MenuItem>
              ))}
            </Select>
          </div>

          <View className="p-3 mt-2">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold -mb-2">
              Öffnungszeiten
            </label>
            {getOpeningHours(values).days.map((day, index) => (
              <View className="flex flex-row items-center mt-1">
                <View className="flex-row items-center mt-3">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      let newArr = [...getOpeningHours(values).days];
                      newArr.splice(index + 1, 0, {} as any);
                      setFieldValue(
                        `${currentErfuellungsOrtIndex}.days`,
                        newArr
                      );
                    }}
                  >
                    <Feather name="plus-circle" size={21} color="#14b8a6" />
                  </div>

                  {index === 0 && <View className="ml-5"></View>}

                  {index !== 0 && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        let newArr = [...getOpeningHours(values).days];
                        newArr.splice(index, 1);
                        console.log(newArr);
                        setFieldValue(
                          `${currentErfuellungsOrtIndex}.days`,
                          newArr
                        );
                      }}
                    >
                      <Feather name="minus-circle" size={21} color="#FF4C4C" />
                    </div>
                  )}

                  {/* <Field
                    component={ClientTextInput}
                    viewClassName={"ml-1"}
                    name={`[${currentErfuellungsOrtIndex}].days[${index}].fromDay`}
                    dropdownData={weekdays}
                    mode="dropdown"
                  ></Field> */}


                  <Autocomplete
                    size="small"
                    key={Math.random()}
                    className="w-36"
                    noOptionsText="Tag nicht gefunden."
                    disablePortal={false}
                    disableClearable
                    options={weekdays}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].fromDay}
                    onChange={(event, value) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].fromDay`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tag" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />

                  <View className="mx-1">
                    <Text className="text-gray-500 text-xs">-</Text>
                  </View>

                  <Autocomplete
                    size="small"
                    key={Math.random()}
                    style={{ width: "160px" }}
                    noOptionsText="Tag nicht gefunden."
                    disablePortal={false}
                    options={weekdays}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].tillDay}
                    onChange={(event, value) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].tillDay`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tag" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />

                  <View className="mx-1">
                    <Text className="text-gray-500 text-xs">-</Text>
                  </View>

                  <Autocomplete
                    size="small" key={Math.random()}
                    style={{ width: "100px" }}
                    noOptionsText="Uhrzeit nicht gefunden."
                    disablePortal={false}
                    disableClearable
                    options={OP_TIMES_DROPDOWN_DATA}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].start_time_1}
                    onChange={(event, value) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].start_time_1`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Uhrzeit" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />

                  <View className="mx-1">
                    <AntDesign name="minus" size={8} color="gray" />
                  </View>

                  <Autocomplete
                    size="small" key={Math.random()}
                    style={{ width: "100px" }}
                    noOptionsText="Uhrzeit nicht gefunden."
                    disablePortal={false}
                    disableClearable
                    options={OP_TIMES_DROPDOWN_DATA}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].end_time_1}
                    onChange={(event, value) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].end_time_1`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Uhrzeit" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />

                  <View className="mx-1">
                    <Text className="text-gray-500 text-xs">und</Text>
                  </View>

                  <Autocomplete
                    size="small" key={Math.random()}
                    style={{ width: "120px" }}
                    noOptionsText="Uhrzeit nicht gefunden."
                    disablePortal={false}
                    options={OP_TIMES_DROPDOWN_DATA}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].start_time_2}
                    onChange={(event, value) => {
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].start_time_2`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Uhrzeit" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />

                  <View className="mx-1">
                    <AntDesign name="minus" size={8} color="gray" />
                  </View>

                  <Autocomplete
                    size="small" key={Math.random()}
                    style={{ width: "120px" }}
                    noOptionsText="Uhrzeit nicht gefunden."
                    disablePortal={false}
                    options={OP_TIMES_DROPDOWN_DATA}
                    defaultValue={values[currentErfuellungsOrtIndex].days[index].end_time_2}
                    onChange={(event, value) => {
                      console.log(value)
                      setFieldValue(`[${currentErfuellungsOrtIndex}].days[${index}].end_time_2`, value)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Uhrzeit" inputProps={{ ...params.inputProps, style: { fontSize: "14px" } }} />
                    )}
                  />
                </View>
              </View>
            ))}
          </View>
          {/* <Text>{JSON.stringify(values, null, "\t")}</Text>
          {JSON.stringify(errors)} */}
        </Form>
      )}
    </Formik>
  );

  function addValidation(): (
    values: any[],
    schema: yup.StringSchema<string | undefined, yup.AnyObject, undefined, "">,
    options: { value?: any; parent?: any; context?: any }
  ) => yup.ISchema<any, any, any, any> {
    return (time, schema) => {
      console.log(time[0]);
      if (time[0] && time[0] !== "") {
        return schema.oneOf(OP_TIMES_DROPDOWN_DATA).required();
      }
      return schema;
    };
  }
}
