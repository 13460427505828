import {
  Alert,
  Dimensions,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet
} from "react-native";
import {
  Ionicons,
  MaterialIcons,
  Octicons,
  Feather,
  EvilIcons,
  SimpleLineIcons,
  MaterialCommunityIcons,
  FontAwesome5,
} from "@expo/vector-icons";
import Carousel from "react-native-reanimated-carousel";
import { LinearGradient } from "expo-linear-gradient";
import {
  Company,
  PerformancePlace,
  useGetCompanyByPerformancePlaceIdLazyQuery,
  Coupon,
  Maybe,
  useCanRedeemCouponTodayLazyQuery,
  CanRedeemCouponTodayResult,
  useAddOrRemoveFavoriteMutation,
  CanRedeemReason
} from "../../__generated__/graphql-types";
import { textvorlage_2_extras } from "../../constants";
import * as WebBrowser from "expo-web-browser";
import * as Linking from "expo-linking";
import React, { useEffect, useState } from "react";
import { Image } from "expo-image";
import { useSelector } from "react-redux";
import { selectAppUser } from "../../store/native-slices/userSlice.native";
import Animated, {
  interpolate,
  useAnimatedRef,
  useAnimatedStyle,
  useScrollViewOffset
} from 'react-native-reanimated';

import {
  Animated as REACT_NATIVE_ANIMATED
} from 'react-native';
import { ExpandingDot } from "react-native-animated-pagination-dots";
import { NavigationProp } from "@react-navigation/native";

export default function CompanyDetailScreen(props: {
  performancePlaceId: string;
  adminPreview: boolean;
  navigation: NavigationProp<any, any>,
  route: any;
}) {
  const getPerformancePlace = (): PerformancePlace => {
    if (company) {
      return company.performancePlaces.find(a => a._id === performancePlaceId)!;
    }
    return {} as any;
  };

  const width = Dimensions.get("window").width;
  const IMG_HEIGHT = 300;

  let performancePlaceId = "";
  let goBack = () => undefined;

  if (props.adminPreview) {
    goBack = () => undefined;
    performancePlaceId = props.performancePlaceId;
  } else {
    goBack = props?.navigation?.goBack;
    performancePlaceId = props.route.params.performancePlaceId;
  }

  const [company, setCompany] = useState<Company | undefined | null>(undefined);
  const [refetchCompany, { error, loading }] = useGetCompanyByPerformancePlaceIdLazyQuery();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [canRedeemCouponTodayCoupon1, { data: canRedeemCoupon1, loading: canRedeemCoupon1Loading, refetch: refetchCanRedeemCoupon1 }] = useCanRedeemCouponTodayLazyQuery();
  const [canRedeemCouponTodayCoupon2, { data: canRedeemCoupon2, loading: canRedeemCoupon2Loading, refetch: refetchCanRedeemCoupon2 }] = useCanRedeemCouponTodayLazyQuery();
  const [addOrRemoveFavorite] = useAddOrRemoveFavoriteMutation();
  const { currentAppUser, isLoggedIn } = useSelector(selectAppUser);
  const scrollRef = useAnimatedRef<Animated.ScrollView>();
  const scrollOffset = useScrollViewOffset(scrollRef);
  const [scrollX, setScrollX] = useState(new REACT_NATIVE_ANIMATED.Value(0));
  const [favorite, setIsFavorite] = useState<boolean>(props.route?.params?.isFavorite || false);


  const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#fff'
    },
    image: {
      width: width,
      height: IMG_HEIGHT
    },
    header: {
      backgroundColor: '#fff',
      height: 115,
      borderWidth: StyleSheet.hairlineWidth,
      borderColor: "silver"
    }
  });

  const imageAnimatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: interpolate(
            scrollOffset.value,
            [-IMG_HEIGHT, 0, IMG_HEIGHT],
            [-IMG_HEIGHT / 2, 0, IMG_HEIGHT * 0.75]
          )
        },
        {
          scale: interpolate(scrollOffset.value, [-IMG_HEIGHT, 0, IMG_HEIGHT], [2, 1, 1])
        }
      ]
    };
  });

  const headerAnimatedStyle = useAnimatedStyle(() => {
    return {
      opacity: interpolate(scrollOffset.value, [0, IMG_HEIGHT / 1.5], [0, 1])
    };
  });

  useEffect(() => {
    props.navigation?.setOptions({
      headerTransparent: true,
      headerLeft: () => <>
        {backButton()}
      </>,
      headerRight: () => (
        <View className="flex flex-row justify-between">
          <>
            <TouchableOpacity activeOpacity={0.6} className="right-0 mr-3 rounded-full z-40 w-12 h-12 justify-center text-center bg-white border border-gray-200 text-white"
              onPress={async () => {
                if (!isLoggedIn) {
                  props.navigation.navigate("register-screen");
                } else {
                  await addOrRemoveFavorite({
                    variables: {
                      performancePlaceId
                    }
                  });
                  setIsFavorite(a => !a);
                }
              }}>
              <Text className="mx-auto">
                {favorite
                  ?
                  <MaterialCommunityIcons name="cards-heart" size={28} color="#ec4899" />
                  :
                  <MaterialCommunityIcons name="cards-heart-outline" size={28} color={"#ec4899"} />}
              </Text>
            </TouchableOpacity>
            {/* {shareButton()} */}
          </>
        </View>
      ),
      headerBackground: () => <Animated.View style={[styles.header, headerAnimatedStyle]} />
    })
  }, [props, favorite])

  useEffect(() => {
    refetchCompany({ variables: { performancePlaceId } }).then((a) => {
      let fetchedCompany = a.data?.getCompanyByPerformancePlaceId;
      // console.log("fetchedCompany ", JSON.stringify(fetchedCompany, null, "\t"));
      setCompany(fetchedCompany as Company);

      canRedeemCouponTodayCoupon1({
        variables: {
          couponId: fetchedCompany?.angebotseinstellungenDetails.coupon_1?._id,
          performancePlaceId: performancePlaceId
        }
      });

      if (fetchedCompany?.angebotseinstellungenDetails.coupon_2?.isActive) {
        canRedeemCouponTodayCoupon2({
          variables: {
            couponId: fetchedCompany?.angebotseinstellungenDetails.coupon_2?._id,
            performancePlaceId: performancePlaceId
          }
        });
      }
    });
  }, [performancePlaceId]);

  const openWindow = async (url: string) => {
    if (!url) {
      return;
    }

    if (!url.startsWith("http") || !url.startsWith("https")) {
      url = "https://" + url;
    }
    if (Platform.OS === "web") {
      window.open(url);
    } else {
      await await WebBrowser.openBrowserAsync(url);
    }
  };

  const backButton = () => <TouchableOpacity
    onPress={() => {
      goBack();
    }}
    className="ml-3 mr-1 rounded-full z-40 w-12 h-12 justify-center text-center bg-white border border-gray-200 text-white"
  >
    <View className="mx-auto">
      <Feather name="arrow-left" size={26} color="#ec4899" />
    </View>
  </TouchableOpacity>

  const shareButton = () => <TouchableOpacity className="right-0 mr-3 rounded-full z-40 w-12 h-12 justify-center text-center bg-white border border-gray-200  text-white">
    <View className="mx-auto">
      <Feather name="share" size={20} color="#ec4899" />
    </View>
  </TouchableOpacity>

  if (!loading && company) {
    return (
      <View style={styles.container}>
        <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} scrollEventThrottle={16} >
          <Animated.View
            style={[styles.image, imageAnimatedStyle]}
          >
            {!getPerformancePlace().imagesDetails?.images?.length && (
              <View className="items-center justify-center h-80">
                <EvilIcons name="image" size={150} color="#E5E7EB" />
              </View>
            )}
            {getPerformancePlace().imagesDetails?.images?.length > 0 && (
              <View className="flex-1">
                <View className="relative z-30">
                  <View className="mt-72 absolute mx-auto bg-white top-1/2 left-1/2 transform -translate-x-1/2">
                    <ExpandingDot
                      data={getPerformancePlace().imagesDetails?.images || []}
                      expandingDotWidth={30}
                      scrollX={scrollX}
                      activeDotColor="white"
                      inActiveDotColor="white"
                      dotStyle={{
                        width: 10,
                        height: 10,
                        backgroundColor: '#347af0',
                        borderRadius: 5,
                        marginHorizontal: 5
                      }}
                      containerStyle={{
                        top: 20,
                      }}
                    />
                  </View>
                </View>
                <Carousel
                  style={{
                    backgroundColor: "white",
                    width: "100%",
                    minHeight: 350,
                    height: 350,
                    maxHeight: 350,
                    marginBottom: 30,
                  }}
                  loop
                  width={width}
                  height={width / 2}
                  autoPlay={getPerformancePlace().imagesDetails?.images?.length > 1}
                  onScrollEnd={(index) => {
                    setCurrentImageIndex(index + 1);
                    setScrollX(new REACT_NATIVE_ANIMATED.Value(width * index))
                  }}
                  autoPlayInterval={3333}
                  data={getPerformancePlace().imagesDetails?.images || []}
                  scrollAnimationDuration={400}
                  renderItem={({ index, item }) => (
                    <View
                      style={{
                        // wegen vorschau auf desktop admin
                        maxWidth: Platform.OS === "web" ? 344 : "auto",
                      }}
                    >
                      <Image
                        style={{ height: 350 }}
                        contentFit="cover"
                        source={{
                          uri: getPerformancePlace().imagesDetails?.images![
                            index
                          ],
                        }}
                      ></Image>
                    </View>
                  )}
                />
              </View>
            )}
          </Animated.View>
          <View>
            <View className="bg-white APP_MOBILE pt-4">
              <View className="flex items-center flex-row flex-shrink">
                <View>
                  <View>
                    <View className="mx-4"
                      style={{
                        // wegen vorschau auf desktop admin
                        maxWidth: Platform.OS === "web" ? 344 : "auto",
                      }}>
                      <Text className="text-4xl text-slate-700 max-w-xs mt-2 mb-2 font-bold">
                        {getPerformancePlace().erfuellungsOrt}
                      </Text>

                      <TouchableOpacity className="max-w-xs mb-2 flex flex-row items-center" onPress={async () => {
                        await navigateToPerformancePlace();
                      }}>
                        <View>
                          <Octicons name="location" size={24} color="rgb(71, 85, 105)" />
                        </View>
                        <Text className="text-base text-slate-600 font-light ml-1.5">
                          {getPerformancePlace().strasse + " " + getPerformancePlace().postleitzahl + " " + getPerformancePlace().ort}
                        </Text>
                      </TouchableOpacity>

                      <View className="mt-1 flex flex-row">
                        <View
                          style={{ flex: 1 }}
                          className="bg-gray-100 rounded-3xl"
                        >
                          <Text className="mx-auto items-center py-2 px-4 font-semibold  text-center tracking-wider text-pink-500 text-sm">
                            {
                              company.angebotseinstellungenDetails.coupon_1
                                ?.subCategory?.name
                            }
                          </Text>
                        </View>

                        {company.angebotseinstellungenDetails.coupon_2?.isActive && company.angebotseinstellungenDetails.coupon_1?.subCategory?.name !== company.angebotseinstellungenDetails.coupon_2.subCategory?.name && (
                          <View
                            style={{ flex: 1 }}
                            className="bg-gray-100 rounded-3xl ml-1.5"
                          >
                            <Text className="mx-auto items-center py-2 px-4 font-semibold  text-center tracking-wider text-pink-500 text-sm">
                              {company.angebotseinstellungenDetails.coupon_2?.subCategory?.name}
                            </Text>
                          </View>
                        )}
                      </View>
                      {
                        createCouponElement({
                          coupon: company.angebotseinstellungenDetails?.coupon_1,
                          buttonType: "default",
                          canRedeemCouponTodayResult: canRedeemCoupon1?.canRedeemCouponToday,
                          loading: canRedeemCoupon1Loading,
                          isLoggedIn,
                          navigation: props.navigation,
                          onRedeem: async () => {
                            props?.navigation?.navigate("company-redeem-modal", {
                              coupon: company.angebotseinstellungenDetails?.coupon_1,
                              performancePlaceId: getPerformancePlace()._id,
                              images: getPerformancePlace().imagesDetails.images,
                              onSlide: async () => {
                                await refetchCanRedeemCoupon1();
                              }
                            });
                          }
                        })
                      }

                      {
                        createCouponElement({
                          coupon: company.angebotseinstellungenDetails?.coupon_2,
                          buttonType: "default",
                          canRedeemCouponTodayResult: canRedeemCoupon2?.canRedeemCouponToday,
                          loading: canRedeemCoupon2Loading,
                          isLoggedIn,
                          navigation: props.navigation,
                          onRedeem: async () => {
                            props?.navigation?.navigate("company-redeem-modal", {
                              coupon: company.angebotseinstellungenDetails?.coupon_2,
                              performancePlaceId: getPerformancePlace()._id,
                              images: getPerformancePlace().imagesDetails.images,
                              onSlide: async () => {
                                await refetchCanRedeemCoupon2();
                              }
                            });
                          }
                        })
                      }

                    </View>

                    <TouchableOpacity
                      className=" bg-gray-100 mx-4 mt-4 rounded-full"
                      onPress={() => {
                        props?.navigation?.navigate("company-redeem-rules-modal", {});
                      }}
                    >
                      <Text className="">
                        <View className="flex flex-row items-center py-4 px-20">
                          <Text className="font-medium text-center uppercase tracking-wider text-pink-500 mt-1 ml-4">
                            Einlösebedingungen
                          </Text>
                          <View className="ml-4 mt-1">
                            <Octicons name="info" size={22} color="#E43D95" />
                          </View>
                        </View>
                      </Text>
                    </TouchableOpacity>

                    {createBorder()}

                    <Text className="ml-4 text-lg text-slate-700 font-semibold mb-1">
                      Informationen
                    </Text>

                    <View className="flex flex-col flex-wrap p-4 gap-6">

                      <View className="flex flex-row">
                        <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                          <Ionicons
                            name="location-outline"
                            size={30}
                            color="#ec4899"
                          />
                        </View>

                        <View className="ml-3">
                          <Text className="text-base text-slate-700 font-semibold">
                            Standort
                          </Text>
                          <View>
                            <TouchableOpacity
                              className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                              onPress={async () => {
                                await navigateToPerformancePlace();
                              }}
                            >
                              <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                Navigation starten
                              </Text>
                              <View className="mr-3">
                                <Ionicons
                                  name="navigate-circle-outline"
                                  size={16}
                                  color="#E43D95"
                                />
                              </View>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>



                      {/* <Image
                  className="mx-auto ml-3 mt-5 m-3 rounded-xl cursor-pointer"
                  source={{uri:`https://maps.google.com/?q=${getErfuellungsort().location?.coordinates[0]},${getErfuellungsort().location?.coordinates[1]}`}}
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?q=${getErfuellungsort().location?.coordinates[0]},${getErfuellungsort().location?.coordinates[1]}`
                    );
                  }}
                ></Image> */}

                      {getPerformancePlace().telefon && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                            <Ionicons
                              name="call-outline"
                              size={30}
                              color="#ec4899"
                            />
                          </View>

                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-semibold">
                              Festnetz
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  Linking.openURL("tel:" + getPerformancePlace().telefon);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  {getPerformancePlace().telefon} anrufen
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}

                      {getPerformancePlace().mobil && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                            <Ionicons
                              name="call-outline"
                              size={30}
                              color="#ec4899"
                            />
                          </View>

                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-semibold">
                              Mobil
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  Linking.openURL("tel:" + getPerformancePlace().mobil);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  {getPerformancePlace().mobil} anrufen
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}

                      {getPerformancePlace().whatsapp && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                            <Ionicons name="logo-whatsapp" size={30} color="#ec4899" />
                          </View>

                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-semibold">
                              WhatsApp
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  await Linking.openURL("http://api.whatsapp.com/send?phone=" + getPerformancePlace().whatsapp);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  Chat starten
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}



                      {getPerformancePlace().website && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                            <Feather name="globe" size={30} color="#ec4899" />
                          </View>

                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-semibold">
                              Website
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  await openWindow(getPerformancePlace().website);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  Öffnen
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}


                      {getPerformancePlace().speisekarte && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">

                            {company.angebotseinstellungenDetails.coupon_1?.category?.name === "category_essen_trinken"
                              ? (
                                <Ionicons
                                  name="restaurant-outline"
                                  size={30}
                                  color="#ec4899"
                                />
                              ) : (
                                <MaterialIcons name="euro-symbol" size={30} color="#ec4899" />
                              )}
                          </View>

                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-bold">
                              {company.angebotseinstellungenDetails.coupon_1?.category?.name === "category_essen_trinken" ? "Speisekarte" : "Preise"}
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  await openWindow(getPerformancePlace().speisekarte);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  Öffnen
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}

                      {getPerformancePlace().email && (
                        <View className="flex flex-row">
                          <View className="bg-gray-100 p-2 rounded-xl  justify-center">
                            <MaterialIcons
                              name="alternate-email"
                              size={30}
                              color="#ec4899"
                            />
                          </View>
                          <View className="ml-3">
                            <Text className="text-base text-slate-700 font-semibold">
                              E-Mail
                            </Text>
                            <View>
                              <TouchableOpacity
                                className="bg-gray-100 rounded-xl ml-0 flex flex-row items-center"
                                onPress={async () => {
                                  await Linking.openURL("mailto:" + getPerformancePlace().email);
                                }}
                              >
                                <Text className="mx-auto items-center py-1 pl-4 font-bold  text-center text-pink-500 text-xs uppercase">
                                  Öffnen
                                </Text>
                                <View className="mr-3">
                                  <Ionicons
                                    name="navigate-circle-outline"
                                    size={16}
                                    color="#E43D95"
                                  />
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      )}
                    </View>

                    {getPerformancePlace().openingHours?.days?.length > 0 && (
                      <>
                        {createBorder()}
                        <Text className="ml-4 text-lg text-slate-700 font-semibold mb-1">
                          Öffnungszeiten
                        </Text>

                        <View className="py-1 px-4">
                          {getPerformancePlace().openingHours?.days?.map(
                            (day, index) => (
                              <View key={index} className="flex flex-row justify-between items-center py-1">
                                {/* Tage links */}
                                <View>
                                  <Text className="text-base text-gray-400 font-normal">
                                    {day && (
                                      day.fromDay === day.tillDay
                                        ? `${day.fromDay}`
                                        : `${day.fromDay}${day.tillDay ? `-${day.tillDay}` : ""}`
                                    )}
                                  </Text>
                                </View>

                                {/* Uhrzeiten rechts */}
                                <View className="flex flex-row ml-auto">
                                  {/* Erste Uhrzeit */}
                                  <Text className="text-base text-pink-500 font-medium">
                                    <Text className="text-pink-500">{day?.start_time_1}</Text>
                                    <Text className="text-pink-500"> - </Text>
                                    <Text className="text-pink-500">{day?.end_time_1}</Text>
                                  </Text>

                                  {/* Zweite Uhrzeit (falls vorhanden) */}
                                  {day?.start_time_2 && day?.end_time_2 && (day?.start_time_2 !== "00:00" && day?.end_time_2 !== "00:00") && (
                                    <Text className="text-base font-medium text-pink-500">
                                      <Text className="text-pink-500">, {day?.start_time_2}</Text>
                                      <Text className="text-pink-500"> - </Text>
                                      <Text className="text-pink-500">{day?.end_time_2}</Text>
                                    </Text>
                                  )}
                                </View>
                              </View>
                            )
                          )}

                          {getPerformancePlace()?.openingHours?.closedDays && getPerformancePlace().openingHours.closedDays!.length > 0 && (
                            <View className="flex flex-row justify-between items-center">
                              <Text className="text-base text-gray-400 font-normal">
                                Ruhetage
                              </Text>
                              <Text className="text-base font-medium text-pink-500">
                                {getPerformancePlace()?.openingHours?.closedDays?.join(', ')}
                              </Text>
                            </View>
                          )}

                          {getPerformancePlace()?.openingHours?.extraOpeningHours && (
                            <View className="flex flex-row justify-between items-center bg-gray-100 p-4 mt-2 rounded-2xl">
                              <Text className="text-base text-pink-500 font-medium">
                                Sonstiges: {getPerformancePlace()?.openingHours?.extraOpeningHours}
                              </Text>
                            </View>
                          )}
                        </View>

                        {company.reviews?.length > 0 && (
                          <>
                            {createBorder()}
                            <View className="mx-4 mb-3">
                              <Text className="text-lg text-slate-700 font-semibold mb-1">
                                Bewertungen ({company.reviews?.length})
                              </Text>
                              <View className="bg-gray-100 p-6 rounded-2xl">
                                <Text className="text-3xl text-pink-500 font-bold">100%</Text>
                                <View className="flex flex-row flex-wrap mt-1">
                                  <Text className="font-light">würden dieses Erlebnis </Text>
                                  <Text className="font-bold text-pink-500">weiterempfehlen.</Text>
                                </View>
                              </View>
                            </View>
                          </>
                        )}
                      </>
                    )}

                    {createBorder()}
                    <View className="mx-4 mb-3">
                      <Text className="text-lg text-slate-700 font-semibold mb-1">
                        Ausstattung
                      </Text>
                      <View className="flex flex-row flex-wrap gap-2 mt-1">
                        {company.performancePlaces[0].propertyFeatures &&
                          Object.keys(getPerformancePlace().propertyFeatures).filter(
                            (key, index) => {
                              return index != 0 && getPerformancePlace().propertyFeatures[key] === true
                            }
                          )
                            .map((extra) => {
                              let item = textvorlage_2_extras.find(
                                (a) => a.formName === extra
                              );
                              return (
                                <View className="flex flex-row items-center bg-gray-100 px-4 py-3 rounded-2xl" key={item?.name}>
                                  <View className="">
                                    {item?.icon}
                                  </View>
                                  <Text className="text-sm font-medium mx-auto ml-2 text-pink-500">
                                    {item?.name}
                                  </Text>
                                </View>
                              );
                            })}
                      </View>
                    </View>

                    {(getPerformancePlace().instagram || getPerformancePlace().tiktok || getPerformancePlace().facebook) && (
                      <>
                        {createBorder()}
                        <View className="mx-4 mb-7">
                          <View className="flex flex-row justify-between items-center">
                            <Text className="text-lg text-slate-700 font-semibold mb-1">
                              Social Media
                            </Text>
                          </View>
                          <View className="flex flex-row flex-wrap gap-6 mt-1 justify-center">
                            {getPerformancePlace()?.instagram && (
                              <TouchableOpacity className="rounded-3xl p-4 bg-gray-100"
                                onPress={() => {
                                  Linking.openURL(getPerformancePlace().instagram)
                                }}>
                                <View className="">
                                  <Ionicons name="logo-instagram" size={28} color="#ec4899" />
                                </View>
                              </TouchableOpacity>
                            )}

                            {getPerformancePlace()?.facebook && (
                              <TouchableOpacity className="rounded-3xl p-4 bg-gray-100"
                                onPress={() => {
                                  Linking.openURL(getPerformancePlace().facebook)
                                }}>
                                <View className="">
                                  <SimpleLineIcons name="social-facebook" size={26} color="#ec4899" />
                                </View>
                              </TouchableOpacity>
                            )}

                            {getPerformancePlace()?.tiktok && (
                              <TouchableOpacity className="rounded-3xl p-4 bg-gray-100"
                                onPress={() => {
                                  Linking.openURL(getPerformancePlace().tiktok)
                                }}>
                                <View className="">
                                  <FontAwesome5 name="tiktok" size={28} color="#ec4899" />
                                </View>
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                      </>
                    )}
                  </View>
                </View>
              </View >
            </View >
          </View>
        </ScrollView>
      </View>

    );
  }

  async function navigateToPerformancePlace() {
    const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' });
    const latLng = `${getPerformancePlace().location?.coordinates[1]},${getPerformancePlace().location?.coordinates[0]}`;
    const label = getPerformancePlace().erfuellungsOrt;
    const url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`
    });
    await Linking.openURL(url);
  }
}

export function createBorder() {
  return (
    <View
      className="border-b border-gray-50 mt-3 mb-3 max-w-300px"
      style={{
        marginLeft: 53,
        marginRight: 30,
      }}
    ></View>
  );
}

export const createCouponElement = ({
  coupon,
  canRedeemCouponTodayResult,
  buttonType,
  loading,
  onRedeem,
  isLoggedIn,
  navigation
}: {
  coupon: Maybe<Coupon> | undefined,
  canRedeemCouponTodayResult: CanRedeemCouponTodayResult | undefined,
  buttonType: "default" | "slide" | "none",
  loading?: boolean,
  onRedeem?: () => Promise<void | undefined>,
  isLoggedIn?: boolean,
  navigation?: any
}
) => {
  return (
    coupon && coupon.isActive && (
      <View className="rounded-3xl max-w-36rem mt-5 ">
        <LinearGradient
          className="h-30 rounded-t-3xl "
          style={{ borderTopLeftRadius: 30, borderTopRightRadius: 30 }}
          start={{ x: 0.6, y: 0.1 }}
          end={{ x: 1, y: 1 }}
          colors={["#EF216E", "#CD15B5"]}
        >
          {/* Überschrift */}
          <View className="">
            <View>
              <Text className="text-center pt-6 pb-4 px-4 text-lg space text-white break-words font-normal">
                {coupon.header}
              </Text>
            </View>
          </View>

          <View style={{ flexDirection: "row", alignItems: "center" }} className="px-3 pb-3 opacity-90">
            <View style={{ flex: 1 }} className="bg-gray-100 rounded-full">
              <Text className="mx-auto items-center py-2 pl-4 pr-6 font-medium text-center  text-slate-700 text-sm">
                ~ {coupon.maximumAmount || "-"}€{" "}Ersparnis
              </Text>
            </View>
            <View style={{ flex: 1 }} className="bg-gray-100 rounded-full ml-2">
              <Text className="mx-auto items-center py-2 pl-4 pr-6 font-medium text-center  text-slate-700 text-sm">
                + 10 Punkte
              </Text>
            </View>
          </View>

          {/* Beschreibung */}
          <View className="bg-gray-100 flex flex-row">
            <Text className="text-sm pl-6 pr-5 pt-4 pb-2 text-slate-700 bg-gray-100 break-words pink font-light">
              {coupon.description}
            </Text>
          </View>
        </LinearGradient>
        <View className="bg-gray-100 rounded-b-3xl">
          <>
            {canRedeemCouponTodayResult?.canActivate || canRedeemCouponTodayResult?.canRedeemReason === CanRedeemReason.NOT_LOGGED_IN || canRedeemCouponTodayResult?.canRedeemReason === CanRedeemReason.MISSING_SUBSCRIPTION ? (
              buttonType === "default" &&
              <TouchableOpacity
                className="mb-4 mx-4 mt-2"
                onPress={async () => {
                  if (!isLoggedIn) {
                    navigation.navigate("register-screen");
                  } else if (canRedeemCouponTodayResult.canRedeemReason === CanRedeemReason.MISSING_SUBSCRIPTION) {
                    navigation.navigate("checkout-screen");
                  } else {
                    onRedeem && await onRedeem();
                  }
                }}
              >
                {loading ? (
                  // <SkeletonLoading background={"#d1d5db"} highlight={"#e5e7eb"}>
                  //   <View style={{ height: 62 }} className='w-full rounded-full bg-gray-100 z-50 p-6 space text-white font-bold text-center uppercase tracking-wider text-base' />
                  // </SkeletonLoading>
                  <></>
                ) : (
                  <LinearGradient
                    className="text-center"
                    style={{ borderRadius: 9999 }}
                    start={{ x: 0.6, y: 0.1 }}
                    end={{ x: 1, y: 1 }}
                    colors={["#EF216E", "#CD15B5"]}
                  >
                    <Text className="p-6 space text-white font-bold text-center uppercase tracking-wider text-base">
                      Jetzt einlösen
                    </Text>
                  </LinearGradient>
                )}
              </TouchableOpacity>
            )
              :
              (
                <TouchableOpacity
                  className="mb-4 mx-4 mt-2"
                  onPress={() => {
                    Alert.alert(canRedeemCouponTodayResult?.reasonTitle || '', canRedeemCouponTodayResult?.reasonDescription || '');
                  }}
                >
                  {loading ? (
                    // <SkeletonLoading background={"#d1d5db"} highlight={"#e5e7eb"}>
                    //   <View style={{ height: 62 }} className='w-full rounded-full bg-gray-100 z-50 p-6 space text-white font-bold text-center uppercase tracking-wider text-base' />
                    // </SkeletonLoading>
                    <></>
                  ) : (
                    <LinearGradient
                      className="text-center"
                      style={{ borderRadius: 9999 }}
                      start={{ x: 0.6, y: 0.1 }}
                      end={{ x: 1, y: 1 }}
                      colors={["#e5e7eb", "#e5e7eb"]}
                    >
                      <View className="flex flex-row items-center justify-center p-6">
                        <Ionicons name="lock-closed-outline" size={24} color="rgb(55, 65, 81)" />
                        <View>
                          <Text className="ml-2 space text-gray-700 font-medium text-center uppercase tracking-wider text-base">
                            {canRedeemCouponTodayResult?.reasonTitle}
                          </Text>
                          {canRedeemCouponTodayResult?.canRedeemReason === CanRedeemReason.ALREADY_REDEEMED && (
                            <Text className="ml-2 space text-gray-700 font-medium text-center  tracking-wider text-xs">
                              Alle 365 Tage einlösbar
                            </Text>
                          )}
                        </View>
                      </View>
                    </LinearGradient>
                  )}
                </TouchableOpacity>
              )
            }
          </>
        </View>
      </View>
    )
  );
};