import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo, useState } from "react";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  CouponCodes,
  useGetAllCouponCodesRequestsQuery,
} from "../../../__generated__/graphql-types";
import { Feather } from "@expo/vector-icons";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { IconButton } from "@mui/material";
import { transformDate } from "../../../helpers";

export default function AdminCouponCodesRequests() {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const {
    data: couponCodeRequests,
    error,
    loading: couponCodeRequestsOnLoading,
    refetch: refetchCouponCodeRequests,
  } = useGetAllCouponCodesRequestsQuery();

  const columns = useMemo<MRT_ColumnDef<CouponCodes>[]>(
    () => [
      {
        accessorFn: (row) => row.codes,
        header: "Gedruckt?",
        Cell({ cell, column, row, table }) {
          return cell.getValue<boolean>() ? (
            <Text
              className={`p-1.5 bg-teal-600 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Gedruckt
            </Text>
          ) : (
            <Text
              className={`p-1.5 bg-yellow-400 text-white font-semibold rounded-md text-sm uppercase tracking-wide`}
            >
              Nicht Gedruckt
            </Text>
          );
        },
      },
      {
        accessorFn: (row) => row.duration,
        header: "Abo (Monate)",
      },
      {
        accessorFn: (row) => row.codes?.length,
        header: "Anzahl Codes",
      },
      {
        accessorFn: (row) => transformDate(row.createdAt, true),
        header: "Erstellt am",
      },
    ],
    []
  );

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white">
      <MaterialReactTable
        enableColumnFilterModes
        enableRowActions
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={couponCodeRequests?.getAllCouponCodesRequests || []}
        state={{
          showSkeletons: couponCodeRequestsOnLoading,
        }}
        columns={columns as any}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => {
            row?.original &&
              row.original._id &&
              navigate(`/client/overview/${row.original._id}`);
          },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Aktionen",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Gutscheine
          </h2>
        )}
      ></MaterialReactTable>
    </View>
  );
}
