export function UploadImage(
  base64: string,
  folder: string,
  upload_preset: string = "sf4l8j60"
) {
  return new Promise((resolve, reject) => {
    const url = "https://api.cloudinary.com/v1_1/youtwome/image/upload";
    const formData = new FormData();
    formData.append("file", `data:application/pdf;base64,${base64}`);
    formData.append("folder", folder);
    formData.append("upload_preset", upload_preset);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        resolve(JSON.parse(data));
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
}

export async function UploadImages(
  images: { base64: string; folder: string; upload_preset: string }[]
) {
  let promises = images.map(
    async (img) => await UploadImage(img.base64, img.folder, img.upload_preset)
  );

  const results = await Promise.all(promises);
  return results;
}
