import { Text, View } from "react-native";
import { classNames } from "../../../helpers";

export default function AdminFormHeader(props) {
  return (
    <View className={classNames(props.noTopMargin ? "" :  "mt-10", 'p-3 ml-3 shadow-sm bg-gray-50 select-none rounded-lg', props.extraStyle)} {...props}>
      <Text className="text-base font-bold leading-7 text-pink-600">
        {props?.children}
      </Text>
    </View>
  );
}
