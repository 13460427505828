import { Text, View } from "react-native";
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Company,
  CouponCodes,
  CompanyState,
  useGetCompanyWebQuery,
  useGetCouponCodesByIdQuery,
  useUpdateCompanyMutation,
  useUpdateCompanyStateMutation,
  useGetOrdersByCompanyIdQuery,
  useGetSignRequestByCompanyIdLazyQuery,
  useSendContractConfirmationEmailMutation,
  SignRequestType,
  useDeleteCompanyMutation,
  useSendMailMutation,
  useRegisterContractTerminationMutation,
  GetCompanyWebQuery,
  Role,
  useMergePdfsMutation,
} from "../../../../../__generated__/graphql-types";
import { useParams } from "react-router-dom";
import {
  classNames,
  formatNumberToCurrency,
  transformDate,
} from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import {
  AntDesign,
  Feather,
  FontAwesome5,
  Ionicons,
  MaterialCommunityIcons,
  Octicons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import ContractStateBadge from "../../../web-components/ContractStateBadge";
import ClientButton from "../../../web-components/ClientButton";
import { useToasts } from "react-toast-notifications";
import { MRT_ColumnDef } from "material-react-table";
import { Checkbox } from "@mui/material";

import { MySwal } from "../../../../../../WebApp";
import LoadingScreen from "../../../web-components/LoadingScreen";
import CompanyDetailScreen from "../../../../company/CompanyDetailScreen";
import { textvorlage_2_extras } from "../../../../../constants";
import ErfuellungsOrteTabs from "../../../web-components/ErfuellungsOrteTabs";
import ImageGallery from "react-image-gallery";
import EntityTimeLine from "../../../web-components/EntityTimeLine";
import Skeleton from "@mui/material/Skeleton";
import OrderStateBadge from "../../../web-components/OrderStateBadge";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import { Image } from "expo-image";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { selectWebUser } from "../../../../../store/web-slices/userSlice.web";

export const CompanyOverviewScreen = () => {
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const navigate = useNavigate();
  const [sendContractConfirmationEmail] = useSendContractConfirmationEmailMutation();
  const [updateCompany] = useUpdateCompanyMutation();
  const [registerContractTermination] = useRegisterContractTerminationMutation();
  const [mergePdfs] = useMergePdfsMutation();
  const [updateCompanyState] = useUpdateCompanyStateMutation();
  const [sendMail] = useSendMailMutation();
  const { addToast } = useToasts();
  let { id } = useParams();
  let { currentWebUser } = useSelector(selectWebUser);
  const [reviewResult, setReviewResult] = useState<boolean | undefined>(undefined);
  const [rejectedReason, setRejectedReason] = useState<GetCompanyWebQuery["getCompany"]["rejectedInformation"] | undefined | null>(undefined);
  const [cancelContractInformation, setCancelContractInformation] = useState<GetCompanyWebQuery["getCompany"]["cancelContractInformation"] | undefined | null>(undefined);
  const [deleteCompany] = useDeleteCompanyMutation();
  const [currentPerformancePlaceImagesIndex, setCurrentPerformancePlaceImagesIndex,] = useState(0);

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files;
    console.log(fileUploaded);
  };

  let {
    loading,
    error,
    refetch: refetchCompany,
  } = useGetCompanyWebQuery({
    variables: { id: id || "" },
  });

  const {
    refetch: refetchCouponCodes,
  } = useGetCouponCodesByIdQuery({});

  const { loading: ordersLoading, data: orders } = useGetOrdersByCompanyIdQuery(
    {
      variables: {
        companyId: id,
      },
    }
  );

  const [
    getSignRequestByCompanyId,
    { data: signRequest },
  ] = useGetSignRequestByCompanyIdLazyQuery();

  useEffect(() => {
    if (id) {
      refetchCompany({ id }).then((res) => {
        let company = res.data?.getCompany;
        setCompany(company);
        getSignRequestByCompanyId({
          variables: { getSignRequestByCompanyIdInput: { companyId: id } },
        });
        if (company.state === CompanyState.REJECTED) {
          setReviewResult(false);
          console.log(company.rejectedInformation);
          setRejectedReason(company.rejectedInformation);
        }
        setCancelContractInformation(company.cancelContractInformation);
      });

      refetchCouponCodes({ companyId: id });
    }
  }, [id]);

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  const createTextWithHeader = (
    header: string,
    values: string | string[] | any
  ) => {
    return (
      <div>
        {header && (
          <View>
            <Text className="text-sm font-bold text-gray-800 mt-6 tracking-wider">
              {header}
            </Text>
            <View className="border-b border-gray-50"></View>
          </View>
        )}
        {createText(Array.isArray(values) ? values : [values])}
        <br></br>
      </div>
    );
  };

  const createText = (values: string[]) => {
    return (
      <>
        {values && values?.filter((a) => !!a)?.length > 0 && (
          <Text className="text-sm text-gray-500">{values.join(" ")}</Text>
        )}
        {!values ||
          (values?.filter((a) => !!a)?.length === 0 && (
            <Text className="text-sm font-bold text-orange-400">
              Keine Angabe
            </Text>
          ))}
      </>
    );
  };

  const DocumentLinkItem = (
    label: string,
    documentLink?: string) => {
    return (
      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm font-semibold">
        <div className="w-0 flex-1 flex items-center">
          {documentLink ? (
            <SimpleLineIcons name="check" size={24} color="green" />
          ) : (
            <SimpleLineIcons name="close" size={24} color="red" />
          )}
          <span className="ml-2 flex-1 w-0 truncate">{label}</span>
        </div>
        <div className="ml-4 flex-shrink-0">
          {documentLink ? (
            <Text
              className="font-medium text-indigo-600 hover:text-indigo-500 hover:cursor-pointer"
              onPress={async () => window.open(documentLink)}
            >
              Anzeigen
            </Text>
          ) : (
            <Text className="font-medium text-red-500  opacity-50 uppercase">
              Nicht vorhanden
            </Text>
          )}
        </div>
      </li>
    );
  };

  const addVollmacht = () => {
    if (!company) {
      return;
    }
    if (company.documentLinks.authority) {
      MySwal.fire({
        title: `Vollmacht vorhanden`,
        showDenyButton: true,
        html: "Wenn du eine neue Vollmacht erstellen möchtest, wird die vorhandene Vollmacht überschrieben. <br><strong>Bist du sicher, dass du fortfahren möchtest?</strong>",
        showCancelButton: true,
        confirmButtonColor: "#4f46e5",
        confirmButtonText: `Ja, neue Vollmacht erstellen`,
        denyButtonText: `Vollmacht anzeigen`,
        denyButtonColor: "black",
        cancelButtonText: "Abbrechen",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(`/client/vollmacht/${company._id}`);
        }
        if (result.isDenied) {
          window.open(company.documentLinks.authority);
        }
      });
    } else {
      navigate(`/client/vollmacht/${company._id}`);
    }
  };

  const addBeratungsprotokoll = () => {
    if (!company) {
      return;
    }
    if (company.documentLinks.adviceProtocol) {
      MySwal.fire({
        title: `Beratungsprotokoll vorhanden`,
        showDenyButton: true,
        html: "Wenn du ein neues Beratungsprotokoll erstellen möchtest, wird das vorhandene Beratungsprotokoll überschrieben. <br><strong>Bist du sicher, dass du fortfahren möchtest?</strong>",
        showCancelButton: true,
        confirmButtonColor: "#4f46e5",
        confirmButtonText: `Ja, neues Protokoll erstellen`,
        denyButtonText: `Protokoll anzeigen`,
        denyButtonColor: "black",
        cancelButtonText: "Abbrechen",
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(`/client/beratungsprotokoll/${company._id}`);
        }
        if (result.isDenied) {
          window.open(company.documentLinks.adviceProtocol);
        }
      });
    } else {
      navigate(`/client/beratungsprotokoll/${company._id}`);
    }
  };


  if (loading) {
    return <LoadingScreen>Vertrag wird geladen...</LoadingScreen>;
  }

  if (!company) {
    return <div>Unternehmen nicht gefunden!</div>;
  }

  return (
    company && (
      <>
        <View className="bg-white p-6 m-3 shadow-md shadow-gray-200 lg:flex lg:flex-row lg:items-center lg:justify-between">
          <View className="flex flex-row">
            <View>
              <View>
                <ContractStateBadge state={company.state}></ContractStateBadge>
              </View>
              <Text className="text-sm muted text-gray-400 leading-6">
                Kundennummer: {company?.kundennummer}
              </Text>
            </View>
            <View className="rotate-90 -mx-1">
              <Feather name="minus" size={28} color="#e9e9e9" />
            </View>

            {company.state === CompanyState.APPROVED && (
              <View className="ml-5">
                <ClientButton
                  icon={<AntDesign name="printer" size={18} color="#ec4899" />}
                  onClick={async () => {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Dokumenten drucken`,
                      html: `Alle Dokumente (Vertrag, Beratungsprotokoll, ggf. Vollmacht, ggf. Rechnung) werden zum Ausdrucken vorbereitet. Der Vertrag wird anschließend auf "Genehmigt" gesetzt.
                      <br><br>Möchtest du diese Änderung wirklich vornehmen?
                      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Dokumente drucken!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    try {

                      addToast("Download gestartet...", {
                        appearance: "info",
                      });


                      let pdf = await mergePdfs({
                        variables: {
                          urls: [
                            company.documentLinks.contract,
                            company.documentLinks.authority,
                            company.documentLinks.adviceProtocol,
                            ...(orders?.getOrdersByCompanyId || [])?.flatMap(a => [
                              a.documents?.orderConfirmation,
                              a.documents?.orderInvoice
                            ])
                          ].filter(a => !!a)
                        }
                      });

                      const linkSource = `data:application/pdf;base64,${pdf?.data?.mergePdfs}`;
                      const downloadLink = document.createElement("a");
                      const fileName = `dokumente_${company.kundennummer}_${company.betreiberDetails.firma?.replaceAll(" ", "_")}`;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();

                      addToast("Download erfolgreich", {
                        appearance: "success",
                      });

                      await updateCompanyState({
                        variables: {
                          id: company._id,
                          state: CompanyState.PRINTED_SENDED,
                        },
                      });

                      addToast("Vertrag erfolgreich auf GENEHMIGT gesetzt.", {
                        appearance: "success",
                      });
                    } catch (error) {
                      addToast('Unerwarteter Fehler ' + error, {
                        appearance: "warning",
                      });
                    }
                  }}
                  label={"Dokumente drucken"}
                  mode="secondary"
                  iconLeft={true}
                ></ClientButton>
              </View>
            )}


            {company.state === CompanyState.PRINTED_SENDED && (
              <View className="ml-5">
                <ClientButton
                  icon={<Feather name="check" size={18} color="#ec4899" />}
                  onClick={async () => {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Vertrag aktivieren`,
                      html: `Der Vertrag wird aktiviert und in der YouTwoMe App angezeigt.
                      <br><br>Möchtest du diese Änderung wirklich vornehmen?
                      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Vertrag aktivieren und in der App veröffentlichen!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    await updateCompanyState({
                      variables: {
                        id: company._id,
                        state: CompanyState.ACTIVE,
                      },
                    });

                    try {
                      await sendMail({
                        variables: {
                          sendMailInput: {
                            subject: "Dein YouTwoMe-Vertrag ist nun aktiv!",
                            to: company.kommunikationsanschriftDetails.email!,
                            mailType: "contract-active",
                            htmlData: {
                              replacements: {
                                customerNumber: company.kundennummer,
                                name: company.kommunikationsanschriftDetails?.vorname + " " + company.kommunikationsanschriftDetails?.nachname
                              }
                            }
                          }
                        }
                      })
                    } catch (error) {
                      addToast("E-Mail an den Kunden konnte nicht verschickt werden. Der Vertrag wurde aber erfolgreich aktiviert!", {
                        appearance: "warning",
                      });
                    }

                    addToast("Vertrag erfolgreich aktiviert.", {
                      appearance: "success",
                    });
                  }}
                  label={"Vertrag aktivieren"}
                  mode="secondary"
                  iconLeft={true}
                ></ClientButton>
              </View>
            )}
          </View>
          <div>
            <Text className="flex justify-center text-2xl font-bold leading-7 text-slate-700 sm:truncate sm:text-3xl sm:tracking-tight lg:break-words">
              {company?.performancePlaces?.length === 0 ? (
                <div>(Erfüllungsort)</div>
              ) : (
                ""
              )}
              {company?.performancePlaces?.length === 1 ? (
                <div>{company?.performancePlaces[0].erfuellungsOrt}</div>
              ) : (
                ""
              )}
              {company?.performancePlaces?.length > 1 ? (
                <div>
                  <span>{company?.performancePlaces[0].erfuellungsOrt}</span>
                  <span className="text-gray-400 font-normal">
                    {" + " +
                      (company?.performancePlaces.length - 1) +
                      " weitere..."}
                  </span>
                </div>
              ) : (
                ""
              )}
            </Text>
            {cancelContractInformation && (
              cancelContractInformation.canceled ?
                <div className="text-rose-600 font-medium">Vertrag wurde am {dayjs(cancelContractInformation.cancelDate).format("DD.MM.YYYY")} gekündigt</div>
                :
                <div className="text-rose-600 font-medium">ACHTUNG: Vertrag wird am {dayjs(cancelContractInformation.cancelDate).format("DD.MM.YYYY")} gekündigt</div>
            )}
          </div>



          <div className="mt-5 flex lg:ml-4 lg:mt-0 my-auto flex-wrap gap-2">
            {company.isReadonly && (
              <ClientButton
                icon={<Ionicons name="eye-outline" size={18} color="#ec4899" />}
                onClick={() => {
                  navigate(`/client/update/${company._id}/1`);
                }}
                label={"Vertrag ansehen"}
                mode="secondary"
                iconLeft={true}
              ></ClientButton>
            )}

            {(currentWebUser?.role === Role.ADMIN || currentWebUser?._id === company.createdBy?._id) && (
              <ClientButton
                icon={
                  company.isReadonly ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                  ) : (
                    <Octicons name="pencil" size={18} color="#ec4899" />
                  )
                }
                onClick={async () => {
                  if (
                    company.state === CompanyState.REJECTED &&
                    company.rejectedInformation?.isContractRelevant === true
                  ) {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Vertragsrelevate Änderungen`,
                      html: `Vertragsrelevante Änderungen erforderlich, Unterschriften werden gelöscht und müssen vom Teilnehmer nochmals eingeholt werden.
      <br><br>Möchtest du diese Änderung wirklich vornehmen?
      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Vertrag auflösen & bearbeiten`,
                      denyButtonText: `Abbrechen`,
                    });
                    if (!swalResult.isConfirmed) {
                      return;
                    }
                    await updateCompany({
                      variables: {
                        id: company._id,
                        updateCompanyInput: {
                          documentLinks: {
                            adviceProtocol: "",
                            authority: "",
                            contract: "",
                          },
                          signs: {
                            signatureLetters: "",
                            signatureSign: "",
                          },
                        },
                      },
                    });
                    await updateCompanyState({
                      variables: {
                        id: company._id,
                        state: CompanyState.DRAFT,
                      },
                    });
                    navigate(`/client/update/${company._id}/1`);
                    addToast(
                      "Vertrag wurde erfolgreich auf Status 'Entwurf' gesetzt.",
                      { appearance: "success" }
                    );
                  }
                  // Lesemodus nur weil Vertrag Aktiv ist!
                  else if (company.isReadonly) {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Aktiver Vertrag`,
                      html: `Wenn du Änderungen am Vertrag vornimmst, werden alle Unterschriften entfernt, und es ist erforderlich, ein neues Vertragsverhältnis mit dem Kunden zu erstellen.
      <br><br>Möchtest du diese Änderung wirklich vornehmen?
      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Vertrag auflösen & bearbeiten`,
                      denyButtonText: `Abbrechen`,
                    });
                    if (!swalResult.isConfirmed) {
                      return;
                    }
                    await updateCompany({
                      variables: {
                        id: company._id,
                        updateCompanyInput: {
                          documentLinks: {
                            adviceProtocol: "",
                            authority: "",
                            contract: "",
                          },
                          signs: {
                            signatureLetters: "",
                            signatureSign: "",
                          },
                          orderRequiredInformation: {
                            orderRequired: null,
                            reasonRejected: ""
                          },
                          cancelContractInformation: null
                        },
                      },
                    });
                    await updateCompanyState({
                      variables: {
                        id: company._id,
                        state: CompanyState.DRAFT,
                      },
                    });
                    navigate(`/client/update/${company._id}/1`);
                    addToast(
                      "Vertrag wurde erfolgreich auf Status 'Entwurf' gesetzt.",
                      { appearance: "success" }
                    );
                  } else {
                    navigate(`/client/update/${company._id}/1`);
                  }
                }}
                label={"Vertrag bearbeiten"}
                mode="secondary"
                iconLeft={true}
              ></ClientButton>
            )}


            {company.state === CompanyState.ACTIVE && !cancelContractInformation && (
              <ClientButton
                icon={<MaterialCommunityIcons name="progress-close" size={18} color="#ec4899" />}
                onClick={async () => {
                  try {
                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Vertrag kündigen`,
                      html: `Die Kündigung wird vorgemerkt für den ${dayjs().add(3, 'months').endOf('month').format("DD.MM.YYYY")}.
                            <br><br>Möchtest du diese Änderung wirklich vornehmen?
                            <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Kündigung vormerken!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    let response = await registerContractTermination({
                      variables: {
                        companyId: company._id
                      }
                    });
                    setCancelContractInformation(response.data?.registerContractTermination);
                    addToast("Kündigung erfolgreich vorgemerkt für den " + dayjs(response.data?.registerContractTermination.cancelDate).format("DD.MM.YYYY"), { appearance: "success" });
                  } catch (error) {
                    addToast("FEHLER: Vertrag konnte nicht gekündigt werden." + error, { appearance: "error", })
                  }
                }}
                label={"Vertrag kündigen"}
                mode="secondary"
                iconLeft={true}
              ></ClientButton>
            )}

            {(company?.state === CompanyState.DRAFT || company?.state === CompanyState.WAIT_CUSTOMER_REACTION) && (company.createdBy?._id === currentWebUser?._id || currentWebUser?.role === "ADMIN") && (
              <ClientButton
                icon={<Octicons name="trash" size={19} color="#ec4899" />}
                label="Vertrag löschen"
                mode="secondary"
                iconLeft={true}
                onClick={async () => {
                  try {

                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Vertrag löschen`,
                      html: `Das Unternehmen wird entfernt inkl. allen Bildern & Dokumenten.
                            <br><br>Möchtest du diese Änderung wirklich vornehmen?
                            <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Unternehmen entfernen!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    try {
                      await deleteCompany({ variables: { companyId: company._id } });
                    } catch (error) {
                      addToast("FEHLER: " + error, { appearance: "error", })
                    }
                    addToast("Vertrag erfolgreich gelöscht!", { appearance: "success" });
                    navigate(`/client/contracts`);
                  } catch (error) {
                    addToast("FEHLER: Vertrag konnte nicht dupliziert werden." + error, { appearance: "error", })
                  }
                }}
              ></ClientButton>
            )}

            <ClientButton
              icon={<Feather name="shopping-cart" size={18} color="white" />}
              label="Bestellung +"
              iconLeft={true}
              onClick={async () => {
                navigate(`/client/orders/newOrder?companyId=${company._id}`);
              }}
            ></ClientButton>
          </div>
        </View>

        <div className="overflow-scroll sm:rounded-lg mx-3">
          <div className="border-t border-gray-50 px-0 py-0">
            <dl className="grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-5  gap-x-4 gap-y-4 ">

              {/* In Überprüfung: Bitte auswählen */}
              {(company.state === CompanyState.IN_REVIEW ||
                company.state === CompanyState.REJECTED) &&
                signRequest?.getSignRequestByCompanyId?.signed &&
                currentWebUser?.role === Role.ADMIN &&
                signRequest?.getSignRequestByCompanyId?.signsAccepted && (
                  <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                    <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                      <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#E43D95"
                          className="w-7 h-7"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                          />
                        </svg>
                      </View>
                      Überprüfung
                    </dt>
                    <dd className="mt-4">
                      <label className="uppercase tracking-wide text-gray-700 text-xs font-bold mt-5">
                        {company.state === CompanyState.IN_REVIEW &&
                          "Bitte auswählen"}
                        {company.state === CompanyState.REJECTED && (
                          <span className="text-red-600 bg-red-50 p-2">
                            Vertrag wurde abgelehnt!
                          </span>
                        )}
                      </label>
                      <div className="divide-x flex flex-row text-center bg-white rounded-lg shadow divide-gray-200 mt-2">
                        <div
                          onClick={() => {
                            if (company.state === CompanyState.IN_REVIEW) {
                              setReviewResult(true);
                            }
                          }}
                          className={classNames(
                            company.state === CompanyState.IN_REVIEW
                              ? "cursor-pointer"
                              : "cursor-not-allowed",
                            "w-0 flex-1 rounded-l-lg",
                            reviewResult ? "bg-teal-500/60" : ""
                          )}
                        >
                          <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                            <Octicons
                              name="check"
                              size={21}
                              color={reviewResult ? "white" : "#14b8a6"}
                            />
                          </div>
                        </div>
                        <div
                          className={classNames(
                            "w-0 flex-1 cursor-not-allowed",
                            reviewResult === undefined ? "bg-zinc-200" : ""
                          )}
                        >
                          <div className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent  hover:text-gray-500">
                            <Feather
                              name="slash"
                              size={21}
                              color={
                                reviewResult === undefined ? "gray" : "#e4e4e7"
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={classNames(
                            company.state === CompanyState.IN_REVIEW
                              ? "cursor-pointer"
                              : "cursor-not-allowed",
                            "w-0 flex-1 rounded-r-lg",
                            reviewResult === false ? "bg-red-500/70" : ""
                          )}
                          onClick={() => {
                            if (company.state === CompanyState.IN_REVIEW) {
                              setReviewResult(false);
                            }
                          }}
                        >
                          <div className="relative w-0 flex-1 inline-flex items-center justify-center py-3 text-sm text-gray-700 font-medium border border-transparent hover:text-gray-500">
                            <AntDesign
                              name="close"
                              size={21}
                              color={
                                reviewResult === false ? "white" : "#f43f5e"
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {reviewResult === true && (
                        <div className="flex items-start space-x-4 mt-2">
                          <div className="min-w-0 flex-1">
                            <div className="flex-shrink-0">
                              <button
                                onClick={async () => {
                                  try {
                                    let swalResult = await MySwal.fire({
                                      title: `ACHTUNG: Vertrag genehmigen`,
                                      html: `Der Vertrag wird genehmigt. Nach der Genehmigung kann er aktiviert werden, um für alle Nutzer online verfügbar zu sein.
                                    <br><br>Möchtest du diese Änderung wirklich vornehmen?
                                    <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                                      showDenyButton: true,
                                      width: 700,
                                      confirmButtonText: `Ja, Vertrag genehmigen`,
                                      denyButtonText: `Abbrechen`,
                                    });

                                    if (!swalResult.isConfirmed) {
                                      return;
                                    }

                                    await updateCompanyState({
                                      variables: {
                                        id: company._id,
                                        state: CompanyState.APPROVED,
                                      },
                                    });

                                    await sendContractConfirmationEmail({
                                      variables: {
                                        companyId: company._id,
                                      }
                                    });

                                    addToast("Vertrag wurde genehmigt! Vertragsbestätigung wurde an den Kunden verschickt.", {
                                      appearance: "success",
                                    });
                                    navigate(-1);
                                  } catch (error) {
                                    addToast(
                                      "FEHLER: Vertrag konnte nicht genehmigt werden!" +
                                      error
                                    );
                                  }
                                }}
                                className="w-full inline-flex uppercase items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-teal-600/70 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                              >
                                <div className="mx-auto">
                                  Vertrag Genehmigen
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      {reviewResult === false && (
                        <div className="flex items-start space-x-4">
                          <div className="min-w-0 flex-1">
                            <div className="border border-gray-200 focus:border-pink-500 mt-2">
                              <textarea
                                rows={9}
                                disabled={
                                  company.state !== CompanyState.IN_REVIEW
                                }
                                name="rejected_commtent"
                                id="rejected_commtent"
                                className="block w-full border-0 border-b border-transparent focus:outline-none p-3 pb-2 focus:ring-0 focus:border-pink-500 sm:text-sm"
                                placeholder="Grund für die Ablehnung..."
                                defaultValue={
                                  company.rejectedInformation?.reason || ""
                                }
                                onChange={(e) =>
                                  setRejectedReason({
                                    ...rejectedReason,
                                    reason: (e.currentTarget as any).value,
                                  })
                                }
                              />
                            </div>
                            <div className="flex flex-row pt-4 items-center">
                              <div>Vertragrelevant?</div>
                              <div
                                title="Vertragsrelevate Bereiche: Betreiber, Angebot, Erfüllungsort, Ansprechpartner"
                                className="ml-2 cursor-help"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#2563eb"
                                  className="w-6 h-6"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="flex flex-row justify-center">
                              <div className="flex flex-row items-center">
                                <div>Ja</div>
                                <Checkbox
                                  disabled={
                                    company.state !== CompanyState.IN_REVIEW
                                  }
                                  checked={
                                    rejectedReason?.isContractRelevant === true
                                  }
                                  onChange={() => {
                                    setRejectedReason({
                                      ...rejectedReason,
                                      isContractRelevant: true,
                                    });
                                  }}
                                />
                              </div>
                              <div className="px-4 font-bold my-auto">oder</div>
                              <div className="flex flex-row items-center">
                                <div>Nein</div>
                                <Checkbox
                                  disabled={
                                    company.state !== CompanyState.IN_REVIEW
                                  }
                                  checked={
                                    rejectedReason?.isContractRelevant === false
                                  }
                                  onChange={() => {
                                    setRejectedReason({
                                      ...rejectedReason,
                                      isContractRelevant: false,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="pt-2">
                              {company.state === CompanyState.IN_REVIEW && (
                                <button
                                  disabled={
                                    !rejectedReason?.reason?.length ||
                                    rejectedReason?.isContractRelevant ===
                                    undefined ||
                                    rejectedReason?.isContractRelevant === null
                                  }
                                  onClick={async () => {
                                    try {
                                      let text = "";

                                      if (rejectedReason?.isContractRelevant) {
                                        text =
                                          'Vertragsrelevante Änderungen sind erforderlich. Wenn der Vertrag erneut bearbeitet wird, wechselt der Status auf "Entwurf", und der Vertrag muss erneut zur Prüfung vorgelegt werden. Alle bisherigen Unterschriften werden gelöscht und müssen erneut vom Teilnehmer eingeholt werden.';
                                      } else {
                                        text =
                                          'Es sind keine vertragsrelevanten Änderungen erforderlich. Bei einer erneuten Bearbeitung des Vertrages wird der Zustand erneut auf "In Prüfung" gesetzt. Es werden keine neuen Dokumente erstellt noch neue Unterschriften beantragt.';
                                      }

                                      let swalResult = await MySwal.fire({
                                        title: `ACHTUNG: Vertrag ablehnen`,
                                        html: `${text}
                                      <br><br>Möchtest du diese Änderung wirklich vornehmen?
                                      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                                        showDenyButton: true,
                                        width: 700,
                                        confirmButtonText: `Ja, Vertrag ablehnen`,
                                        denyButtonText: `Abbrechen`,
                                      });

                                      if (!swalResult.isConfirmed) {
                                        return;
                                      }

                                      if (rejectedReason?.isContractRelevant) {
                                        await updateCompany({
                                          variables: {
                                            id: company._id,
                                            updateCompanyInput: {
                                              signs: undefined,
                                            },
                                          },
                                        });
                                      }
                                      await updateCompanyState({
                                        variables: {
                                          id: company._id,
                                          state: CompanyState.REJECTED,
                                          rejectedInformation: rejectedReason,
                                        },
                                      });
                                      addToast("Vertrag wurde abgelehnt.", {
                                        appearance: "success",
                                      });
                                      navigate("/client/contracts");
                                    } catch (error) {
                                      addToast(
                                        "FEHLER: Vertrag konnte nicht genehmigt werden!" +
                                        error
                                      );
                                    }
                                  }}
                                  className={classNames(
                                    !rejectedReason?.reason?.length ||
                                      (rejectedReason?.isContractRelevant !==
                                        true &&
                                        rejectedReason?.isContractRelevant !==
                                        false)
                                      ? "cursor-not-allowed bg-red-300"
                                      : " hover:bg-red-700",
                                    "w-full text-center ml-auto inline-flex uppercase items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-red-600/70  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  )}
                                >
                                  <div className="mx-auto">
                                    Vertrag Ablehnen
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </dd>
                  </div>
                )}

              {/* Vorhandner Signrequest */}
              {(company.state === CompanyState.WAIT_CUSTOMER_REACTION ||
                company.state === CompanyState.IN_REVIEW) &&
                signRequest &&
                signRequest.getSignRequestByCompanyId?.signsAccepted === null &&
                signRequest?.getSignRequestByCompanyId?.requestType === SignRequestType.Contract && (
                  <div>
                    <div className="bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow-sm rounded-lg mb-2">
                      <div className="flex flex-row ml-3">
                        <dt>
                          <div className="shadow-sm shadow-pink-100 rounded-xl mr-4 p-4">
                            <FontAwesome5
                              name="signature"
                              size={24}
                              color="#E43D95"
                            />
                          </div>
                        </dt>
                        <dd className="text-xl font-bold text-gray-900 my-auto">
                          Unterschrift
                        </dd>
                      </div>
                      <div className="p-4">
                        <div className="flex flex-row justify-between">
                          <div className="text-gray-400 text-sm font-light">
                            Anfrage geschickt am
                          </div>
                          <div className="text-sm font-medium">
                            {transformDate(
                              signRequest.getSignRequestByCompanyId?.createdAt
                            )}
                          </div>
                        </div>
                        <div className="border-b my-2"></div>
                        <div className="flex flex-row justify-between">
                          <div className="text-gray-400 text-sm font-light">
                            E-Mail
                          </div>
                          <div className="text-sm font-medium">
                            {signRequest.getSignRequestByCompanyId.email}
                          </div>
                        </div>
                        <div className="border-b my-2"></div>
                        <div className="flex flex-row justify-between">
                          <div className="text-gray-400 text-sm font-light">
                            Erinnerungen
                          </div>
                          <div className="text-sm font-medium">
                            {
                              signRequest.getSignRequestByCompanyId.reminderMailCounter ? signRequest.getSignRequestByCompanyId.reminderMailCounter : "Keine"
                            }
                          </div>
                        </div>
                        {signRequest.getSignRequestByCompanyId.reminderMailCounter > 0 && (
                          <>
                            <div className="border-b my-2"></div>
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                Letzte Erinnerung
                              </div>
                              <div className="text-sm font-medium">
                                {transformDate(
                                  signRequest.getSignRequestByCompanyId
                                    .reminderSendedAt
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="border-b my-2"></div>
                        <div className="flex flex-row justify-between">
                          <div className="text-gray-400 text-sm font-light">
                            Unterschrieben?
                          </div>
                          {signRequest.getSignRequestByCompanyId.signed &&
                            signRequest.getSignRequestByCompanyId.signedAt ? (
                            <div className="p-1.5 bg-teal-600 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center">
                              Ja, {" "}
                              {transformDate(
                                signRequest.getSignRequestByCompanyId.signedAt
                              )}
                            </div>
                          ) : (
                            <div className="p-1.5 bg-red-400 text-white font-semibold rounded-md text-xs uppercase tracking-wide text-center">
                              Nein
                            </div>
                          )}
                        </div>
                        {signRequest.getSignRequestByCompanyId.signed && (
                          <>
                            <div className="border-b my-2"></div>
                            <div className="flex flex-row justify-between">
                              <div className="text-gray-400 text-sm font-light">
                                Prüfung
                              </div>
                              <a
                                className="text-sm font-bold underline text-pink-500 cursor-pointer"
                                href="/client/reactions/sign-requests-reactions"
                              >
                                Unterschriften überprüfen
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Feather name="shopping-cart" size={26} color="#E43D95" />
                  </View>
                  Bestellungen
                </dt>
                <dd className="mt-1">
                  {company.state != CompanyState.DRAFT && (
                    <div className="px-3 pb-3">
                      {createTextWithHeader(
                        "Ist eine Bestellung erforderlich?",
                        [
                          company.orderRequiredInformation?.orderRequired ===
                            true
                            ? "Ja"
                            : `Nein, ${company.orderRequiredInformation?.reasonRejected}`,
                        ]
                      )}
                    </div>
                  )}

                  {ordersLoading ? (
                    <div>
                      <Skeleton
                        variant="rounded"
                        height={30}
                        className="mt-2"
                      />
                      <Skeleton
                        variant="rounded"
                        height={30}
                        className="mt-2"
                      />
                      <Skeleton
                        variant="rounded"
                        height={30}
                        className="mt-2"
                      />
                    </div>
                  ) : (
                    <div>
                      {orders?.getOrdersByCompanyId?.length ? (orders?.getOrdersByCompanyId?.map((order, index) => (
                        <div className="border-b border-b-gray-100 p-3">
                          <span className="text-sm font-bold text-slate-900">#{++index} Bestellung</span>
                          <div className="text-sm text-slate-900">
                            {formatNumberToCurrency(order.summary?.grossAmount)} Brutto
                          </div>
                          <a className="text-sm text-pink-500 cursor-pointer underline" href={`/client/orders/${order._id}`}>#{order.orderNumber}</a>
                          <div className="mt-1">
                            <OrderStateBadge
                              state={order.state}
                            ></OrderStateBadge>
                          </div>
                        </div>
                      ))
                      ) : (
                        <div className="text-sm font-bold text-gray-800 mt-6 tracking-wider">
                          Es wurden noch keine Bestellungen aufgegeben.
                        </div>
                      )}
                    </div>
                  )}
                </dd>
              </div>

              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Feather name="user" size={26} color="#E43D95" />
                  </View>
                  Betreiber
                </dt>
                <dd className="mt-1">
                  {createTextWithHeader("Rechtsform", [
                    company?.betreiberDetails.rechtsform,
                  ])}
                  {createTextWithHeader("Adresse", [
                    company?.betreiberDetails.firma,
                  ])}
                  {createTextWithHeader("", [
                    company?.betreiberDetails.postleitzahl,
                    company?.betreiberDetails.ort,
                    company?.betreiberDetails.land,
                  ])}
                  {createTextWithHeader("WhatsApp", [
                    company?.betreiberDetails.mobil,
                  ])}
                  {createTextWithHeader("Mobil", [
                    company?.betreiberDetails.mobil,
                  ])}
                  {createTextWithHeader("E-Mail", [
                    company?.betreiberDetails.email,
                  ])}
                  {createTextWithHeader("Vertretung", [
                    company?.betreiberDetails.vertretung_position,
                    company?.betreiberDetails.vertretung_anrede,
                    company?.betreiberDetails.vertretung_vorname,
                    company?.betreiberDetails.vertretung_nachname,
                  ])}
                </dd>
              </div>

              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Feather name="phone-call" size={26} color="#E43D95" />
                  </View>
                  Kommunikation
                </dt>
                <dd className="mt-1">
                  {createTextWithHeader("Ansprechpartner", [
                    company?.kommunikationsanschriftDetails.anrede,
                    company?.kommunikationsanschriftDetails.vorname,
                    company?.kommunikationsanschriftDetails.nachname,
                  ])}
                  {createTextWithHeader("", [
                    company?.kommunikationsanschriftDetails.firma,
                    company?.kommunikationsanschriftDetails.strasse,
                    company?.kommunikationsanschriftDetails.postleitzahl,
                    company?.kommunikationsanschriftDetails.ort,
                    company?.kommunikationsanschriftDetails.land,
                  ])}
                  {createTextWithHeader("Mobil", [
                    company?.kommunikationsanschriftDetails.mobil,
                  ])}
                  {createTextWithHeader("Telefon", [
                    company?.kommunikationsanschriftDetails.telefon,
                  ])}
                  {createTextWithHeader("Kommunikations E-Mail", [
                    company?.kommunikationsanschriftDetails.email,
                  ])}
                </dd>
              </div>
              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Feather name="map-pin" size={26} color="#E43D95" />
                  </View>
                  Erfüllungsort
                </dt>
                <dd
                  className="mt-1 overflow-y-scroll"
                  style={{ maxHeight: 450 }}
                >
                  <div>
                    {createTextWithHeader("Anzahl Erfüllungsorte", [
                      company?.performancePlaces?.length,
                    ])}

                    {company?.performancePlaces?.map((item, index) => (
                      <>
                        {createTextWithHeader(
                          `${index + 1}. Erfüllungsort - Standort`,
                          [item.erfuellungsOrt, item.strasse]
                        )}
                        {createTextWithHeader("", [
                          item.postleitzahl,
                          item.ort,
                          item.land,
                        ])}
                        {createTextWithHeader(
                          `${index + 1}. Erfüllungsort - Kontakt`,
                          [`Telefon: ${item.telefon}`]
                        )}
                        {createTextWithHeader("", [
                          `WhatsApp: ${item.whatsapp}`,
                        ])}
                        {createTextWithHeader("", [`Mobil: ${item.mobil}`])}

                        {createTextWithHeader(
                          `${index + 1}. Erfüllungsort - E-Mails`,
                          [`E-Mail: ${item.email}`]
                        )}
                        {createTextWithHeader("", [`Website: ${item.website}`])}
                        {createTextWithHeader("", [
                          `Preisliste/Speisekarte: ${item.speisekarte}`,
                        ])}
                        {createTextWithHeader(
                          `${index + 1}. Erfüllungsort - Ruhetage: `,
                          [item.openingHours?.closedDays?.join(", ")]
                        )}
                        {createTextWithHeader(
                          `${index + 1}. Preisliste/Speisekarte: `,
                          [item.speisekarte]
                        )}
                        {createTextWithHeader(
                          `${index + 1}. Sonstige Öffnungszeiten`,
                          [item.openingHours?.extraOpeningHours]
                        )}
                        {item.openingHours?.days?.map((day) => (
                          <View className="flex flex-row ">
                            <Text className="text-sm text-slate-500 mb-auto w-32">
                              {day
                                ? day.fromDay === day.tillDay
                                  ? `${day.fromDay.charAt(0).toUpperCase() +
                                  day.fromDay.slice(1, 2)
                                  }`
                                  : `${day.fromDay.charAt(0).toUpperCase() +
                                  day.fromDay.slice(1, 2)
                                  }${day.tillDay
                                    ? `-${day.tillDay
                                      .charAt(0)
                                      .toUpperCase() +
                                    day.tillDay.slice(1, 2)
                                    }`
                                    : ""
                                  }:`
                                : null}
                            </Text>

                            <View className="flex flex-col items-start">
                              <Text className="text-sm text-gray-800">
                                <Text className="text-gray-500">
                                  {day?.start_time_1}
                                </Text>
                                <Text className="text-gray-500"> Uhr - </Text>
                                <Text className="text-gray-500">
                                  {day?.end_time_1}
                                </Text>
                                <Text className="text-gray-500"> Uhr</Text>
                              </Text>

                              {day?.start_time_2 && day?.end_time_2 && (
                                <Text className="text-sm text-gray-500">
                                  <Text className=" text-gray-500">
                                    {day?.start_time_2}
                                  </Text>
                                  <Text className="text-gray-500"> Uhr - </Text>
                                  <Text className="text-gray-500">
                                    {day?.end_time_2}
                                  </Text>
                                  <Text className="text-gray-500"> Uhr</Text>
                                </Text>
                              )}
                            </View>
                          </View>
                        ))}
                        {createTextWithHeader(
                          `${index + 1}. Erfüllungsort - Social Media`,
                          [`TikTok: ${item.tiktok}`]
                        )}
                        {createTextWithHeader("", [
                          `Instagram: ${item.instagram}`,
                        ])}
                        {createTextWithHeader("", [
                          `Facebook: ${item.facebook}`,
                        ])}
                        {createTextWithHeader("Ausstattungsmerkmale", [" "])}
                        <div className="flex flex-row flex-wrap gap-x-0 gap-y-4 mt-1 justify-center">
                          {item.propertyFeatures &&
                            Object.keys(item.propertyFeatures)
                              .filter((key, index) => {
                                return (
                                  index != 0 &&
                                  item.propertyFeatures[key] === true
                                );
                              })
                              .map((extra) => {
                                let item = textvorlage_2_extras.find(
                                  (a) => a.formName === extra
                                );
                                return (
                                  <View className="basis-1/2" key={item?.name}>
                                    <View className="bg-gray-100 p-2 rounded-xl w-15 center mx-auto">
                                      {item?.icon}
                                    </View>
                                    <Text className="text-xs font-semibold mx-auto mt-1">
                                      {item?.name}
                                    </Text>
                                  </View>
                                );
                              })}
                        </div>
                      </>
                    ))}
                  </div>
                </dd>
              </div>
              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Octicons name="briefcase" size={26} color="#E43D95" />
                  </View>
                  Berater- & Kundendaten
                </dt>
                <dd className="mt-1">
                  {createTextWithHeader("Mitarbeiter", [
                    company?.createdBy?.firstName +
                    " " +
                    company?.createdBy?.lastName +
                    "(" +
                    company?.createdBy?.employeeNumber +
                    ")",
                    company?.createdBy?.email,
                  ])}
                  {createTextWithHeader("Gesprochen mit", [
                    company?.beraterKundendatenDetails.gesprochen_mit_anrede,
                    company?.beraterKundendatenDetails.gesprochen_mit_vorname,
                    company?.beraterKundendatenDetails.gesprochen_mit_nachname,
                  ])}
                  {createTextWithHeader("Unterzeichnet von", [
                    company?.beraterKundendatenDetails.unterzeichnet_position,
                    company?.beraterKundendatenDetails.unterzeichnet_anrede,
                    company?.beraterKundendatenDetails.unterzeichnet_vorname,
                    company?.beraterKundendatenDetails.unterzeichnet_nachname,
                  ])}
                  {createTextWithHeader("Ort der Vertragsschliessung", [
                    company?.beraterKundendatenDetails.ort_vertragsschliessung,
                  ])}
                </dd>
              </div>

              <div className="bg-white sm:col-span-1 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#E43D95"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        stroke-linejoin="round"
                        d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5"
                      />
                    </svg>
                  </View>
                  Verlauf
                </dt>
                <EntityTimeLine logs={company.logs}></EntityTimeLine>
              </div>

              <div className="bg-white col-span-3 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#E43D95"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                      />
                    </svg>
                  </View>
                  Logo & Bilder
                  <div className="ml-auto">
                    <ClientButton
                      onClick={async () => {
                        navigate(`/client/update/${company._id}/10`);
                      }}
                      icon={
                        <div className="-ml-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                            />
                          </svg>
                        </div>
                      }
                      iconLeft
                      mode="secondary"
                      label="Bilder & Logo Bearbeiten"
                    ></ClientButton>
                  </div>
                </dt>
                <dd className="mt-3 text-sm text-gray-900">
                  <ErfuellungsOrteTabs
                    performancePlaces={company.performancePlaces}
                    onChange={(event, newIndex) => {
                      setCurrentPerformancePlaceImagesIndex(newIndex);
                    }}
                  ></ErfuellungsOrteTabs>
                  <View className="pt-3 pb-3 pl-3 grid grid-cols-1 lg:grid-cols-3">
                    <div className="">
                      <AdminFormHeader noTopMargin>Logo</AdminFormHeader>
                      <div className="mt-2">
                        {company.performancePlaces[currentPerformancePlaceImagesIndex]?.imagesDetails?.logo ? (
                          <View >
                            <Image
                              contentFit="contain"
                              style={{
                                height: "200px",
                                width: "200px",
                                margin: "auto"
                              }}
                              source={company.performancePlaces[currentPerformancePlaceImagesIndex]?.imagesDetails?.logo}
                            />
                          </View>
                        ) :
                          (
                            <div className="flex flex-col justify-center items-center cursor-pointer"
                              onClick={async () => {
                                navigate(`/client/update/${company._id}/10`);
                              }}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#E5E7EB" className="w-28 h-28">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                              </svg>
                              <span className="text-xs tracking-wide text-gray-400">Kein Logo vorhanden!</span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <AdminFormHeader noTopMargin>Bilder</AdminFormHeader>
                      {company.performancePlaces[currentPerformancePlaceImagesIndex]?.imagesDetails?.images.length > 0 ? (
                        <View className="border border-dashed border-gray-200 p-11 mx-3 mt-2">
                          <ImageGallery
                            items={company.performancePlaces[
                              currentPerformancePlaceImagesIndex
                            ]?.imagesDetails?.images?.map((img: string) => ({
                              original: img,
                              thumbnail: img,
                            }))}
                            renderItem={(data) => <Image
                              contentFit="contain"
                              style={{
                                height: "400px",
                                width: "400px",
                                margin: "auto"
                              }}
                              source={data.original}
                            />}
                            showFullscreenButton
                            autoPlay={false}
                            slideInterval={1000}
                            showPlayButton={false}
                            showBullets={true}
                            showIndex={true}
                          />
                        </View>
                      ) :
                        <div className="flex flex-col justify-center items-center cursor-pointer mt-2"
                          onClick={async () => {
                            navigate(`/client/update/${company._id}/10`);
                          }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#E5E7EB" className="w-28 h-28">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                          </svg>
                          <span className="text-xs tracking-wide text-gray-400">Keine Bilder vorhanden!</span>
                        </div>
                      }
                    </div>
                  </View>
                </dd>
              </div>

              <div className="bg-white col-span-1rounded-xl pl-0 p-3">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <AntDesign name="mobile1" size={24} color="rgb(236, 72, 153)" />
                  </View>
                  Mobile Vorschau
                </dt>
                <dd className="mt-3 text-sm text-gray-900">
                  <View className="min-h-600px rounded-xl py-2 pl-2 bg-white border border-gray-100">
                    <View
                      className="w-96 overflow-y-scroll mt-2"
                      style={{ maxHeight: "720px" }}
                    > {company?.performancePlaces?.length > 0 && company?.performancePlaces[0]?._id && (
                      <CompanyDetailScreen
                        adminPreview
                        navigation={null}
                        route={null}
                        key={null}
                        performancePlaceId={company?.performancePlaces[0]._id}
                      ></CompanyDetailScreen>
                    )}</View>
                  </View>
                </dd>
              </div>


              <div className="bg-white sm:col-span-2 shadow-md shadow-gray-200 p-8">
                <div className="flex flex-row justify-between">
                  <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                    <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                      <SimpleLineIcons
                        name="paper-clip"
                        size={23}
                        color="#E43D95"
                      />
                    </View>
                    Dokumente
                  </dt>

                  <ClientButton
                    onClick={async () => {
                      
                      let itemCount = 0;
                      let uploadCount = 0;
                      let urlsTemp: string[] = [];

                      const onUploadFinish = (urls: string[]) => {
                        console.log('onUploadFinish ', urls);
                      }

                      let myUploadWidget = window.cloudinary.openUploadWidget(
                        {
                          cloudName: "youtwome",
                          uploadPreset: "sf4l8j60",
                          folder: company._id,
                          tags: ["myname"],
                          maxImageWidth: 900,
                          sources: ["local", "url", "camera"],
                          multiple: true,
                          theme: "white",
                          secure: true,
                          text: {
                            // https://upload-widget.cloudinary.com/2.7.4/global/text.json
                          },
                        },
                        (error: any, result: any) => {
                          if (result.event === "upload-added") {
                            itemCount++;
                          }
                          if (!error && result.event === "success") {
                            let url = result?.info?.url || '';
                  
                            //FORCE HTTPS!
                            if (url.startsWith("http")) {
                              if (url.indexOf("cloudinary") > -1) {
                                url = url.replace("http", "https");
                              }
                            }
                  
                            urlsTemp.push(url);
                            uploadCount++;
                            if (itemCount === uploadCount) {
                              //Erst callback wenn alle Bilder hochgeladen worden sind um einzelne callbacks zu vermeiden
                              onUploadFinish(urlsTemp);
                            }
                          }
                        }
                      );
                    }}
                    icon={
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                      </svg>
                    }
                    iconLeft
                    mode="secondary"
                    label="Dateien hochladen"
                  ></ClientButton>

                </div>

                <dd className="mt-3 text-sm text-gray-900">
                  <ul
                    role="list"
                    className="border border-gray-100 rounded-md divide-y divide-gray-100"
                  >
                    {DocumentLinkItem(
                      "Vertrag",
                      company.documentLinks.contract
                    )}
                    {DocumentLinkItem(
                      "Beratungsprotokoll",
                      company.documentLinks.adviceProtocol
                    )}
                    {DocumentLinkItem(
                      "Vollmacht",
                      company.documentLinks.authority
                    )}
                    {company?.additionalDocuments && company?.additionalDocuments.map(url => (
                      DocumentLinkItem(
                        url.split('\\')?.pop()?.split('/').pop() || 'Dokument hochgeladen',
                        url,
                      )
                    ))}
                    {orders?.getOrdersByCompanyId && orders.getOrdersByCompanyId.map((order, index) => (
                      <>
                        {
                          DocumentLinkItem(
                            `${index + 1}. Bestellung #${order.orderNumber}`,
                            order.documents?.orderInvoice
                          )
                        }
                        {
                          DocumentLinkItem(
                            `${index + 1}. Bestellbestätigung #${order.orderNumber}`,
                            order.documents?.orderConfirmation
                          )
                        }
                      </>
                    ))}
                  </ul>
                </dd>
              </div>

              {/* <div className="bg-white sm:col-span-2 shadow-md shadow-gray-200 p-8">
                <dt className="text-2xl font-bold text-slate-700 tracking-wider flex items-center">
                  <View className="shadow-md shadow-pink-100 w-14 p-4 rounded-xl mr-2">
                    <Octicons name="tag" size={26} color="#E43D95" />
                  </View>
                  Generierte Gutscheine
                </dt>
                <dd className="mt-3 text-sm text-gray-900">
                  <MaterialReactTable
                    enableColumnFilterModes
                    enableRowActions
                    localization={MRT_Localization_DE}
                    positionToolbarAlertBanner="bottom"
                    enableFullScreenToggle
                    data={couponCodesRequests?.getCouponCodesById || []}
                    state={{
                      showSkeletons: loadingGetCouponCodesRequests,
                    }}
                    columns={columns as any}
                    muiTablePaperProps={{
                      elevation: 0,
                      sx: {
                        borderRadius: "20",
                      },
                    }}
                    displayColumnDefOptions={{
                      "mrt-row-actions": {
                        header: "Aktionen",
                      },
                    }}
                  ></MaterialReactTable>
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
      </>
    )
  );
};
