import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useParams } from "react-router-dom";
import { DocumentLinks, OrderDocuments, SignRequestType, useCreateContractDocumentsMutation, useCreateOrderDocumentsMutation, useGetSignRequestQuery, useResolveSignRequestMutation } from "../../../__generated__/graphql-types";
import SignPads from "../web-components/SignPads";
import logo from "../../../../assets/youtwome-big-logo.jpg";
import { Image } from "expo-image";
import ContractDocumentsPreview from "../web-components/ContractDocumentsPreview";
import OrderDocumentsPreview from "../web-components/OrderDocumentsPreview";

export default function SignRequestResolveScreen() {
    const { addToast } = useToasts();
    const [isLoading, setIsLoading] = useState(false);


    let { id, step } = useParams();
    if (!id) {
        return (
            <div className="p-4 rounded-2xl mt-2 bg-gray-50">
                <div className="text-sm font-bold text-pink-500 whitespace-pre-line">ANFRAGE ID {id} NICHT GEFUNDEN. Bitte melde dich beim YouTwoMe Support +49 170 9909070</div>
            </div>
        )
    }
    const { data, error, loading } = useGetSignRequestQuery({ variables: { getSignRequestInput: { requestId: id } } });
    const [contractDocuments, setContractDocuments] = useState<DocumentLinks | undefined>();
    const [orderDocuments, setOrderDocuments] = useState<OrderDocuments | undefined>();

    const [createContractDocuments] = useCreateContractDocumentsMutation();
    const [createOrderDocuments] = useCreateOrderDocumentsMutation();
    const [resolveSignRequest] = useResolveSignRequestMutation();

    useEffect(() => {
        (async () => {
            console.log('useEffect ', data?.getSignRequest?._id)
            if (!data?.getSignRequest) {
                return;
            }
            if (data?.getSignRequest?.requestType === SignRequestType.Contract || data?.getSignRequest?.requestType === SignRequestType.ContractOnSide) {
                let contractDocuments = await createContractDocuments({
                    variables: {
                        createContractDocumentsInput: {
                            companyId: data?.getSignRequest.company?._id,
                            upload: false
                        },
                    },
                });
                setContractDocuments(contractDocuments?.data?.createContractDocuments);
            }
            else if (data?.getSignRequest?.requestType === SignRequestType.Order || data?.getSignRequest?.requestType === SignRequestType.OrderOnSide) {
                let docs = await createOrderDocuments({
                    variables: {
                        createOrderDocumentsInput: {
                            orderId: data?.getSignRequest.order?._id,
                            upload: false
                        },
                    },
                });
                setOrderDocuments(docs?.data?.createOrderDocuments);
            }
        })();
    }, [data?.getSignRequest?._id]);

    if (error?.message) {
        return <div className="p-4 rounded-2xl bg-gray-50 mt-2 h-36">
            <div className="text-sm font-bold text-pink-500 whitespace-pre-line">
                Deine Anfrage kann momentan nicht bearbeitet werden.
                <br></br>
                Versuche es später erneut oder kontaktiere den YouTwoMe Support +4917621622491
                <br></br>
                FEHLER: {error?.message}
            </div>
        </div>
    }


    if (!data?.getSignRequest?._id) {
        return (
            <div className="p-4 rounded-2xl mt-2 bg-gray-50">
                <div className="text-sm font-bold text-pink-500 whitespace-pre-line">Wird geladen...</div>
            </div>
        )
    }

    return (data && data.getSignRequest &&
        (<div className="flex flex-col bg-gray-300 mx-auto w-full">
            <div className="flex flex-row justify-between items-center bg-white p-6 m-3 rounded-xl shadow-md">
                <div>
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 bg-white sm:truncate sm:text-3xl sm:tracking-tight">
                        <div>Deine Unterschrift für <span className="text-pink-500">YouTwoMe</span></div>
                    </h2>

                    {(data.getSignRequest.requestType !== SignRequestType.ContractOnSide && data.getSignRequest.requestType !== SignRequestType.OrderOnSide) && (
                        <div className="p-4 rounded-2xl mt-2 bg-gray-50">
                            <div className="text-sm font-bold text-pink-500 whitespace-pre-line">
                                {data.getSignRequest.signed && data.getSignRequest.requestType === SignRequestType.Contract && (
                                    data.getSignRequest.signed ? (
                                        <div>
                                            Wir haben deine Unterschrift erhalten. Deine Daten werden nun überprüft. Dies kann bis zu <span className="font-extrabold">3 Werktagen dauern.</span>
                                            {"\n"}
                                            Sobald deine Daten verifiziert sind, erhältst du eine E-Mail, dass dein Unternehmen nun für alle YouTwoMe-Mitglieder in unserer APP verfügbar ist.
                                        </div>
                                    ) : (
                                        <div>
                                            Um deinen Vertrag abzuschließen, <span className="font-extrabold">unterschreibe bitte in den beiden unten stehenden Unterschriftsfeldern.</span>
                                            {"\n"}
                                            Sobald du dies getan hast, wirst du nach einer Überprüfung ein Teil von YouTwoMe sein, und dein Unternehmen wird in der App für alle YouTwoMe-Mitglieder öffentlich sein!
                                        </div>
                                    )
                                )}
                                {data.getSignRequest.requestType === SignRequestType.Order && (
                                    data.getSignRequest.signed ? (
                                        <div>
                                            Wir haben deine Unterschrift erhalten. Deine Daten werden nun überprüft. Dies kann bis zu <span className="font-extrabold">3 Werktagen dauern.</span>
                                            {"\n"}
                                            Sobald deine Daten verifiziert sind, erhältst du eine E-Mail, dass dein Unternehmen nun für alle YouTwoMe-Mitglieder in unserer APP verfügbar ist.
                                        </div>
                                    ) : (
                                        <div>
                                            Um deine Bestellung abzuschließen, <span className="font-extrabold">unterschreibe bitte in den beiden unten stehenden Unterschriftsfeldern.</span>
                                            {"\n"}
                                            Sobald du dies getan hast, ---
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    )}
                    {(data.getSignRequest.requestType === SignRequestType.ContractOnSide || data.getSignRequest.requestType === SignRequestType.OrderOnSide) && (
                        <div className="p-4 rounded-2xl bg-gray-50 mt-2">
                            <div className="text-sm font-bold text-pink-500 whitespace-pre-line">
                                {data.getSignRequest.signed ? (
                                    <div>
                                        <strong>Unterschrift erfolgreich hochgeladen.</strong> Bitte teile es deinem YouTwoMe Ansprechpartner mit.
                                    </div>
                                ) : (
                                    <div>
                                        Unterschreibe bitte in den beiden unten stehenden Unterschriftsfeldern.
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className="bg-white rounded-3xl p-2">
                    <Image
                        style={{
                            resizeMode: "center",
                            height: 150,
                            width: 150,
                        }}
                        source={logo}
                    />
                </div>
            </div>

            {!data.getSignRequest.signed && (
                <div className="bg-white">
                    <div className="bg-white p-6 m-3 rounded-xl shadow-md">
                        <SignPads
                            signPadsInput={{
                                lettersPad: {
                                    showPad: true,
                                    description: "Vorname & Nachname in Druckbuchstaben",
                                    signLink: data?.getSignRequest.signed ? data?.getSignRequest.company?.signs?.signatureLetters : "",
                                    signDate: data?.getSignRequest.signed ? data?.getSignRequest.signedAt : new Date(),
                                    uploadFolderName: data?.getSignRequest.company?._id,
                                },
                                signPad: {
                                    showPad: true,
                                    description: "Unterschrift",
                                    signLink: data?.getSignRequest.signed ? data?.getSignRequest.company?.signs?.signatureSign : "",
                                    signDate: data?.getSignRequest.signed ? data?.getSignRequest.signedAt : new Date(),
                                    uploadFolderName: data?.getSignRequest.company?._id,
                                },
                                submitButtonConfig: {
                                    show: true
                                }
                            }}
                            onSubmit={async (signResult) => {
                                console.log(signResult)
                                try {
                                    await resolveSignRequest({
                                        variables: {
                                            resolveSignRequestInput: {
                                                _id: data.getSignRequest!._id,
                                                signs: {
                                                    signatureLetters: signResult.signatureLettersLink,
                                                    signatureSign: signResult.signatureSignLink
                                                }
                                            }
                                        }
                                    });
                                    data.getSignRequest.signed = true;
                                } catch (error) {
                                    console.error(error);
                                    addToast("FEHLER: " + error, { appearance: "error" })
                                }
                            }}
                        ></SignPads>
                    </div>

                    <div className="bg-white">
                        {data.getSignRequest.requestType === SignRequestType.Contract || data.getSignRequest.requestType === SignRequestType.ContractOnSide && (
                            <div className="bg-white p-6 m-3 rounded-xl shadow-md">

                                <div>
                                    <a className="mt-2 bg-gray-100 hover:opacity-80 border-slate-200 text-pink-500 ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-xl focus:outline-none tracking-wider"
                                        href={`data:application/octet-stream;base64,${contractDocuments?.contract}`}
                                        download={"youtwome-vertrag.pdf"}>
                                        <span className="flex flex-row items-center">
                                            Vertrag herunterladen
                                            <div className="ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </div>
                                        </span>
                                    </a>

                                    <a className="mt-2 bg-gray-100 hover:opacity-80 border-slate-200 text-pink-500 ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-xl focus:outline-none tracking-wider"
                                        href={`data:application/octet-stream;base64,${contractDocuments?.adviceProtocol}`}
                                        download={"youtwome-beratungsprotokoll.pdf"}>
                                        <span className="flex flex-row items-center">
                                            Beratungsprotokoll herunterladen
                                            <div className="ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </div>
                                        </span>
                                    </a>

                                    {data?.getSignRequest.company?.beraterKundendatenDetails?.authorityRequired && (
                                        <a className="mt-2 bg-gray-100 hover:opacity-80 border-slate-200 text-pink-500 ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-xl focus:outline-none tracking-wider"
                                            href={`data:application/octet-stream;base64,${contractDocuments?.authority}`}
                                            download={"youtwome-vollmacht.pdf"}>
                                            <span className="flex flex-row items-center">
                                                Vollmacht herunterladen
                                                <div className="ml-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                    </svg>
                                                </div>
                                            </span>
                                        </a>
                                    )}
                                </div>
                                <div className="hidden lg:block">
                                    <ContractDocumentsPreview contractDocuments={contractDocuments} authorityRequired={data?.getSignRequest.company?.beraterKundendatenDetails?.authorityRequired}></ContractDocumentsPreview>
                                </div>
                            </div>
                        )}

                        {data.getSignRequest.requestType === SignRequestType.Order || data.getSignRequest.requestType === SignRequestType.OrderOnSide && (
                            <div className="bg-white p-6 m-3 rounded-xl shadow-md">
                                <div>
                                    <a className="mt-2 bg-gray-100 hover:opacity-80 border-slate-200 text-pink-500 ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-xl focus:outline-none tracking-wider"
                                        href={`data:application/octet-stream;base64,${orderDocuments?.orderConfirmation}`}
                                        download={"youtwome-bestellformular.pdf"}>
                                        <span className="flex flex-row items-center">
                                            Bestellformular herunterladen
                                            <div className="ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </div>
                                        </span>
                                    </a>

                                    <a className="mt-2 bg-gray-100 hover:opacity-80 border-slate-200 text-pink-500 ml-1 inline-flex items-center uppercase px-3 py-3 border border-transparent shadow-sm text-sm leading-4 font-semibold rounded-xl focus:outline-none tracking-wider"
                                        href={`data:application/octet-stream;base64,${orderDocuments?.orderInvoice}`}
                                        download={"youtwome-rechnung.pdf"}>
                                        <span className="flex flex-row items-center">
                                            Rechnung herunterladen
                                            <div className="ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                </svg>
                                            </div>
                                        </span>
                                    </a>
                                </div>
                                <div className="hidden lg:block">
                                    <OrderDocumentsPreview orderDocuments={orderDocuments}></OrderDocumentsPreview>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>)
    )
}
