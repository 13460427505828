import { MaterialCommunityIcons, Octicons } from "@expo/vector-icons";
import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { View, Text, Switch, TouchableOpacity } from "react-native";
import { useToasts } from "react-toast-notifications";
import {
  AngebotseinstellungenDetails,
  Company,
  CompanySubCategorie,
  CouponTextTemplate,
  GetAllCategoriesQuery,
  useGetAllCategoriesQuery,
  useGetAllCouponTextTemplatesQuery,
  useGetCompanyWebQuery,
  useUpdateCompanyMutation,
} from "../../../../../__generated__/graphql-types";
import { createCouponElement } from "../../../../company/CompanyDetailScreen";
import AdminFormHeader from "../../../web-components/AdminFormHeader";
import ClientButton from "../../../web-components/ClientButton";
import ClientTextInput from "../../../web-components/ClientTextInput";
import { contuineFormButton, previousFormButton } from "../screens/ContractFormScreen";
import * as yup from "yup";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Image } from "expo-image";
import { classNames } from "../../../../../helpers";
import LoadingScreen from "../../../web-components/LoadingScreen";

export default function Angebotseinstellungen(props: {
  form: Company;
  contuineAndSave: () => void;
  contuineWithoutSave: () => void;
  onBackClick: () => void;
}) {
  let schema = yup.object({
    coupon_1: yup.object({
      isActive: yup.boolean().required().default(true),
      category: yup.object({
        __typename: yup.string().required(),
        _id: yup.string().required(),
        description: yup.string().required(),
        name: yup.string().required(),
        color: yup.string().required(),
        subCategories: yup.array(),
      }),
      subCategory: yup
        .object({
          _id: yup.string().required(),
          name: yup.string().required(),
        })
        .required(),
      header: yup.string().required(),
      description: yup.string().required(),
      maximumAmount: yup.string().required(),
    }),
    coupon_2: yup.object({
      isActive: yup.boolean().required().default(false), // Standardwert auf false setzen
      category: yup.object({
        __typename: yup.string(),
        _id: yup.string(),
        description: yup.string(),
        name: yup.string(),
        color: yup.string(),
        subCategories: yup.array(),
      }),
      subCategory: yup.object({
        _id: yup.string(),
        name: yup.string(),
      }),
      header: yup.string(),
      description: yup.string(),
    }),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [categories, setCategories] = useState<
    GetAllCategoriesQuery["getAllCategories"]
  >([]);
  const updateCompanyMutation = useUpdateCompanyMutation()[0];
  const { addToast } = useToasts();

  let {
    loading,
    error,
    refetch: refetchCompany,
  } = useGetCompanyWebQuery({
    variables: { id: "" },
  });

  const {
    error: categoriesError,
    loading: categoriesLoading,
    refetch: refetchCategories,
  } = useGetAllCategoriesQuery({
    variables: {},
  });

  useEffect(() => {
    if (props.form._id) {
      refetchCompany({ id: props.form._id }).then((a) => {
        setCompany(a.data?.getCompany);
      });

      refetchCategories().then((a) => {
        let categories = a.data?.getAllCategories;
        setCategories(categories);
      });
    }
  }, []);

  const { data: getAllTextTemplates, loading: textTemplatesLoading } =
    useGetAllCouponTextTemplatesQuery({ variables: { categorieId: "" } });

  if (
    !company ||
    loading ||
    textTemplatesLoading ||
    categoriesLoading ||
    categories?.length === 0
  ) {
    return <LoadingScreen>Daten werden geladen...</LoadingScreen>
  }

  const isDisabled = (values: AngebotseinstellungenDetails): boolean => {
    let disabled = false;

    if (!values.coupon_1) {
      return true;
    }

    if (!values.coupon_1.category || !values.coupon_1.subCategory || !values.coupon_1.description || !values.coupon_1.header || !values.coupon_1.maximumAmount) {
      disabled = true
    }

    if (values.coupon_2?.isActive) {
      if (!values.coupon_2.category || !values.coupon_2.subCategory || !values.coupon_2.description || !values.coupon_2.header || !values.coupon_2.maximumAmount) {
        disabled = true
      }
    }
    return disabled;
  }

  return (
    <Formik
      initialValues={company.angebotseinstellungenDetails}
      validateOnMount
      enableReinitialize
      onSubmit={async (newkommunikationsanschriftForm) => {
        try {
          console.log(newkommunikationsanschriftForm)
          setIsLoading(true);
          console.log("NEW FORM -> ", newkommunikationsanschriftForm);
          let response = await updateCompanyMutation({
            variables: {
              ...company,
              id: company._id,
              updateCompanyInput: {
                angebotseinstellungenDetails: {
                  ...company["angebotseinstellungenDetails"],
                  ...newkommunikationsanschriftForm,
                },
              },
            },
          });
          props.contuineAndSave();
          addToast("Erfolgreich gespeichert", { appearance: "success" });
        } catch (error) {
          addToast("Fehler " + error, { appearance: "error" });
          console.log("Fehler ", error);
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {({ isValid, values, errors, resetForm, setFieldValue, submitForm }) => (
        <Form>
          {/* {JSON.stringify(values.coupon_1, null, "\t")}
          {JSON.stringify(values.coupon_2, null, "\t")}
          <div>-----------</div>
          {JSON.stringify(errors, null, "\t")} */}

          <View className="flex flex-row ml-auto mb-5 justify-end">
            {previousFormButton(props.onBackClick)}
            {contuineFormButton({
              formIsReadonly: props.form.isReadonly,
              disabled: isDisabled(values),
              isLoading,
              submitForm,
              contuineWithoutSaving: props.contuineWithoutSave
            })}
          </View>

          <div className="grid grid-cols-1 lg:grid-cols-2 divide-x">
            <div>
              <AdminFormHeader
                noTopMargin
                onClick={(e: any) => console.log(values)}
              >
                <View className="flex flex-row justify-between">
                  <Text>1. Gutschein - Kategorie & Max. Wert €</Text>
                  <Text className="font-bold text-base uppercase text-teal-700">
                    Aktiv
                  </Text>
                </View>
              </AdminFormHeader>
              {values.coupon_1 && (
                <View className="flex">
                  <View className="ml-2">
                    <View className="flex flex-row flex-wrap gap-3 mt-6 justify-center">
                      {categories?.map((categorie) => (
                        <TouchableOpacity
                          className="basis-1/4 border border-gray-100 rounded-3xl p-1"
                          disabled={props.form.isReadonly}
                          onPress={() => {
                            setFieldValue(`coupon_1.category`, categorie);
                            setFieldValue(`coupon_1.subCategory`, undefined);
                          }}
                        >
                          <View className="right-0 absolute p-1">
                            {values.coupon_1?.category?._id ===
                              categorie._id ? (
                              <MaterialCommunityIcons
                                name="checkbox-marked-circle-outline"
                                size={28}
                                color="#E43D95"
                              />
                            ) : (
                              !props.form.isReadonly &&
                              <MaterialCommunityIcons
                                name="checkbox-blank-circle-outline"
                                size={28}
                                color="black"
                              />
                            )}
                          </View>
                          <Image
                            style={{
                              resizeMode: "center",
                              height: 90,
                              width: 90,
                              marginTop: 17,
                            }}
                            source={categorie.image}
                          />
                          <Text className="text-xs font-bold text-black mx-auto tracking-widest text-center">
                            {categorie.description}
                          </Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>

                  <div className="ml-5 mt-6">
                    <Autocomplete
                      className="cursor-pointer"
                      options={values.coupon_1?.category?.subCategories || []}
                      disabled={props.form.isReadonly}
                      size="small"
                      clearOnBlur={false}
                      value={values.coupon_1.subCategory}
                      loadingText="Unterkategorien werden geladen..."
                      noOptionsText={`Keine Unterkategorien für ${values.coupon_1?.category?.description} gefunden.`}
                      disablePortal={false}
                      onChange={(event, selectedSubCategory) => {
                        if (!selectedSubCategory) {
                          return;
                        }
                        setFieldValue(
                          "coupon_1.subCategory",
                          selectedSubCategory
                        );
                      }}
                      getOptionLabel={(subCategorie: CompanySubCategorie) =>
                        `${subCategorie.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Unterkategorie`}
                        ></TextField>
                      )}
                    />
                  </div>

                  <View className="ml-2">
                    <Field
                      component={ClientTextInput}
                      disabled={props.form.isReadonly}
                      type="text"
                      name="coupon_1.maximumAmount"
                      label="Max. Wert € bei 2:1- Einlösung"
                    ></Field>
                  </View>

                  <AdminFormHeader>1. Gutschein - Titel & Beschreibung</AdminFormHeader>

                  {values.coupon_1.category && (
                    <div className="ml-5 mt-6">
                      <Autocomplete
                        options={
                          getAllTextTemplates?.getAllCouponTextTemplates.filter(
                            (template) => {
                              return template.category?._id === values.coupon_1?.category?._id && template.subCategories?.some(sub => sub._id === values.coupon_1?.subCategory?._id)
                            }
                          ) || []
                        }
                        disabled={props.form.isReadonly}
                        size="small"
                        clearOnBlur={false}
                        // value={values.coupon_1.category}
                        loadingText="Textvorlagen werden geladen..."
                        noOptionsText={`Keine Vorlagen gefunden für ${values.coupon_1?.category?.description} nicht gefunden.`}
                        disablePortal={false}
                        onChange={(event, selectedTemplate) => {
                          if (!selectedTemplate) {
                            return;
                          }
                          setFieldValue(
                            "coupon_1.header",
                            selectedTemplate.header
                          );
                          setFieldValue(
                            "coupon_1.description",
                            selectedTemplate.description
                          );
                        }}
                        getOptionLabel={(textTemplate: CouponTextTemplate) =>
                          `${textTemplate.name}`
                        }
                        renderOption={(props, option, { selected, index }) => (
                          <div
                            {...props}
                            className={classNames("p-3 hover:bg-gray-50 cursor-pointer", "border border-b border-gray-50")}
                          >
                            <div className="text-sm text-slate-700 font-bold">
                              {option.name}
                            </div>
                            <div className="text-xs text-slate-500">
                              {option.header} ({option.description})
                            </div>
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Textvorlagen für ${values.coupon_1?.category?.description}`}
                          ></TextField>
                        )}
                      />
                    </div>
                  )}

                  <View className="ml-2">
                    <Field
                      component={ClientTextInput}
                      type="text"
                      disabled={props.form.isReadonly}
                      maxLength={31}
                      name="coupon_1.header"
                      label="Titel"
                    ></Field>
                  </View>

                  <div className="ml-5 mt-5">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Beschreibung
                    </label>
                    <TextField
                      className="w-full"
                      name="coupon_1.description"
                      disabled={props.form.isReadonly}
                      placeholder="Beschreibung..."
                      multiline
                      value={values.coupon_1.description}
                      onChange={(e) => {
                        setFieldValue('coupon_1.description', (e.currentTarget as any).value)
                      }}
                      minRows={4}
                      rows={7}
                      maxRows={7} />
                  </div>
                  <View className="m-4 ">
                    {createCouponElement(values.coupon_1, { buttonType: "default" })}
                  </View>
                </View>
              )}
            </div>
            <div className="ml-4">
              <AdminFormHeader
                noTopMargin
                onClick={(e: any) => console.log(values)}
              >
                <View className="flex flex-row justify-between">
                  <Text>2. Gutschein - Kategorie & Max. Wert €</Text>

                  <View className="flex flex-row items-center -mr-9">
                    {values.coupon_2?.isActive ? (
                      <Text className="tracking-wide text-base font-bold uppercase text-teal-700 mr-2">
                        Aktiv
                      </Text>
                    ) : (
                      <Text className="tracking-wide text-base font-bold uppercase text-gray-500 mr-2">
                        Inaktiv
                      </Text>
                    )}

                    <div className="-my-4">
                      <FormControlLabel
                        disabled={props.form.isReadonly}
                        control={<Checkbox />}
                        label=""
                        checked={values.coupon_2?.isActive || false}
                        onChange={() => {
                          setFieldValue("coupon_2.isActive", !values.coupon_2?.isActive)
                        }}
                      />
                    </div>
                  </View>
                </View>
              </AdminFormHeader>

              {values.coupon_2 && values.coupon_2.isActive && (
                <View className="flex ml-5 mb-auto">
                  <View className="flex flex-row flex-wrap gap-3 mt-6 justify-center">
                    {categories?.map((categorie) => (
                      <TouchableOpacity
                        className={classNames("basis-1/4 border border-gray-100 rounded-3xl p-1")}
                        disabled={props.form.isReadonly}
                        onPress={() =>
                          setFieldValue(`coupon_2.category`, categorie)
                        }
                      >
                        <View className="right-0 absolute p-1">
                          {values.coupon_2!.category?._id === categorie._id ? (
                            <MaterialCommunityIcons
                              name="checkbox-marked-circle-outline"
                              size={28}
                              color="#E43D95"
                            />
                          ) : (
                            !props.form.isReadonly &&
                            <MaterialCommunityIcons
                              name="checkbox-blank-circle-outline"
                              size={28}
                              color="black"
                            />
                          )}
                        </View>
                        <Image
                          style={{
                            resizeMode: "center",
                            height: 90,
                            width: 90,
                            marginTop: 17,
                          }}
                          source={categorie.image}
                        />
                        <Text className="text-xs font-bold text-black mx-auto tracking-widest text-center">
                          {categorie.description}
                        </Text>
                      </TouchableOpacity>
                    ))}
                  </View>

                  <div className="ml-5 mt-6">
                    <Autocomplete
                      className="cursor-pointer"
                      options={values.coupon_2?.category?.subCategories || []}
                      disabled={props.form.isReadonly}
                      size="small"
                      clearOnBlur={false}
                      value={values.coupon_2.subCategory}
                      loadingText="Unterkategorien werden geladen..."
                      noOptionsText={`Keine Unterkategorien für ${values.coupon_2?.category?.description} gefunden.`}
                      disablePortal={false}
                      onChange={(event, selectedSubCategory) => {
                        if (!selectedSubCategory) {
                          return;
                        }
                        setFieldValue(
                          "coupon_2.subCategory",
                          selectedSubCategory
                        );
                      }}
                      getOptionLabel={(subCategorie: CompanySubCategorie) =>
                        `${subCategorie.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Unterkategorie`}
                        ></TextField>
                      )}
                    />
                  </div>

                  <View className="ml-2">
                    <Field
                      component={ClientTextInput}
                      disabled={props.form.isReadonly}
                      type="text"
                      name="coupon_2.maximumAmount"
                      label="Max. Wert bei 2:1- Einlösung"
                    ></Field>
                  </View>

                  <AdminFormHeader>2. Gutschein - Titel & Beschreibung</AdminFormHeader>

                  {values.coupon_2.category && (
                    <div className="ml-5 mt-6">
                      <Autocomplete
                        options={
                          getAllTextTemplates?.getAllCouponTextTemplates.filter(
                            (template) => {
                              return template.category?._id === values.coupon_2?.category?._id && template.subCategories?.some(sub => sub._id === values.coupon_2?.subCategory?._id)
                            }
                          ) || []
                        }
                        disabled={props.form.isReadonly}
                        size="small"
                        clearOnBlur={false}
                        loadingText="Textvorlagen werden geladen..."
                        noOptionsText={`Keine Vorlagen gefunden für ${values.coupon_2?.category?.description} nicht gefunden.`}
                        disablePortal={false}
                        onChange={(event, selectedTemplate) => {
                          if (!selectedTemplate) {
                            return;
                          }
                          setFieldValue(
                            "coupon_2.header",
                            selectedTemplate.header
                          );
                          setFieldValue(
                            "coupon_2.description",
                            selectedTemplate.description
                          );
                        }}
                        getOptionLabel={(textTemplate: CouponTextTemplate) =>
                          `${textTemplate.name}`
                        }
                        renderOption={(props, option, { selected, index }) => (
                          <div
                            {...props}
                            className={classNames("p-3 hover:bg-gray-50 cursor-pointer", "border border-b border-gray-50")}
                          >
                            <div className="text-sm text-slate-700 font-bold">
                              {option.name}
                            </div>
                            <div className="text-xs text-slate-500">
                              {option.header} ({option.description})
                            </div>
                          </div>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`Textvorlagen für ${values.coupon_2?.category?.description}`}
                          ></TextField>
                        )}
                      />
                    </div>
                  )}

                  <View className="ml-2">
                    <Field
                      component={ClientTextInput}
                      type="text"
                      disabled={props.form.isReadonly}
                      maxLength={31}
                      name="coupon_2.header"
                      label="Titel"
                    ></Field>
                  </View>

                  <div className="ml-5 mt-5">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                      Beschreibung
                    </label>
                    <TextField
                      className="w-full"
                      name="coupon_2.description"
                      disabled={props.form.isReadonly}
                      placeholder="Beschreibung..."
                      multiline
                      value={values.coupon_2.description}
                      onChange={(e) => {
                        setFieldValue('coupon_2.description', (e.currentTarget as any).value)
                      }}
                      minRows={4}
                      rows={7}
                      maxRows={7} />
                  </div>
                  <View className="m-4 ">
                    {createCouponElement(values.coupon_2, { buttonType: "default" })}
                  </View>
                </View>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
