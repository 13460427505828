import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles.css";
import { useSelector } from "react-redux";
import { selectWebUser } from "../../../store/web-slices/userSlice.web";

export default function AuthBrowserRouter(props: any) {
  let navigate = useNavigate();
  let { currentWebUser, isLoggedIn, access_token } = useSelector(selectWebUser);
  const location = useLocation();
  const [navigated, setNavigated] = useState(false);
  const [returnUrl, setReturnUrl] = useState('');

  useEffect(() => {
    console.log('Location changed  ' + location.pathname);
    if (currentWebUser && isLoggedIn && access_token) {
      console.log(`Aktueller Benutzer: ${JSON.stringify(currentWebUser)}`);
      if (location.pathname != '/client/login') {
        setReturnUrl(location.pathname?.replace('/client/', ''));
      }
    } else {
      if (!navigated) {
        setNavigated(true);
        console.log('Kein Aktueller Benutzer. Redirect zu /login');

        if (!location.pathname.toLowerCase().startsWith("/client/signrequest")) {
          navigate(`/client/login?returnUrl=${returnUrl}`);
        }
      }
    }
  }, [location, navigated, currentWebUser]);

  return <>{props.children}</>;
}