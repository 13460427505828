import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { MRT_ColumnFiltersState, type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Text, View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import {
  Company,
  CompanyState,
  CompanyWithOrders,
  GetCompaniesWebQuery,
  OrderState,
  useCopyCompanyMutation,
  useCreateNewCompanyMutation,
  useDeleteCompanyMutation,
  useGetCompaniesWebQuery,
  useSendMailMutation,
} from "../../../__generated__/graphql-types";
import { AntDesign, Feather, Octicons } from "@expo/vector-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContractStateBadge from "../web-components/ContractStateBadge";
import { useToasts } from "react-toast-notifications";
import ClientButton from "../web-components/ClientButton";
import { IconButton, Tooltip } from "@mui/material";
import { decisionTableIcon, enumToArray, transformDate } from "../../../helpers";
import CompanyDetailScreen from "../../company/CompanyDetailScreen";
import { MySwal } from "../../../../WebApp";
import { useSelector } from "react-redux";
import { selectWebUser } from "../../../store/web-slices/userSlice.web";

export default function AdminAllContractsScreen() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [createCompany] = useCreateNewCompanyMutation();
  const [copyCompany] = useCopyCompanyMutation();
  const [deleteCompany] = useDeleteCompanyMutation();
  let [searchParams] = useSearchParams();
  let { currentWebUser } = useSelector(selectWebUser);

  const {
    data: companies,
    error,
    loading,
    refetch: refetchCompanies,
  } = useGetCompaniesWebQuery({
    variables: {
      state: searchParams.get('state'),
      userId: searchParams.get('userId'),
      customerNumbers: searchParams.get('customerNumbers') && searchParams.get('customerNumbers')?.split(",") || []
    }
  });

  const getHeaderText = (state: string | null, userName: string | null) => {
    let headerText = '';

    if (searchParams.get('headerText')) {
      return searchParams.get('headerText');
    }

    switch (state?.toUpperCase()) {
      case CompanyState.DRAFT:
        headerText = "Verträge: Entwürfe"
        break;
      case CompanyState.WAIT_CUSTOMER_REACTION:
        headerText = "Verträge: Warte auf Kundenreaktion"
        break;
      case CompanyState.IN_REVIEW:
        headerText = "Verträge: In Prüfung"
        break;
      case CompanyState.APPROVED:
        headerText = "Verträge: Genehmigt"
        break;
      case CompanyState.REJECTED:
        headerText = "Verträge: Abgelehnt"
        break;
      case CompanyState.ACTIVE:
        headerText = "Verträge: Aktiv"
        break;
      case CompanyState.PRINTED_SENDED:
        headerText = "Verträge: Gedruckt & Verschickt"
        break;
      case CompanyState.INACTIVE:
        headerText = "Verträge: Inaktiv"
        break;
      default:
        headerText = "Verträge: Alle";
        break;
    }

    if (userName) {
      headerText += ` von ${userName}`
    }

    return headerText;
  }

  const getPayedOrderIcon = (row: CompanyWithOrders) => {
    if (row.orders && row.orders.length > 0) {
      if (row.orders.filter(order => order.state === OrderState.NOT_PAYED).length > 0) {
        return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ef4444" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>)
      } else if (row.orders.filter(order => order.state === OrderState.DRAFT || order.state === OrderState.IN_REVIEW || order.state === OrderState.REJECTED || order.state === OrderState.PAYED_NOT_PRINTED).length > 0) {
        return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#f59e0b" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
        </svg>
        )
      } else {
        return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#0d9488" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>)
      }
    } else {
      return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#9ca3af" className="ml-1 w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
      </svg>)
    }
  }

  const columns = useMemo<MRT_ColumnDef<GetCompaniesWebQuery["getCompaniesWeb"][0]>[]>(
    () => [
      {
        accessorFn: (row) => row.company?.state,
        accessorKey: "state",
        id: "state",
        header: "",
        size:200,
        filterSelectOptions: enumToArray(CompanyState),
        filterVariant: "multi-select",
        Cell({ cell, column, row, table }) {
          return <ContractStateBadge state={cell.getValue<CompanyState>()}></ContractStateBadge>;
        },
      },
      {
        accessorFn: (row) => row.company?.kundennummer,
        accessorKey: "kundennummer",
        header: "Kdnr.",
      },
      {
        accessorFn: (row) => row.company?.betreiberDetails?.firma,
        header: "Firma",
      },
      {
        accessorFn: (row) => row.company?.performancePlaces?.length > 1
          ? row.company?.performancePlaces[0].erfuellungsOrt + " + " + (row.company?.performancePlaces?.length - 1) + "weitere..."
          : row.company?.performancePlaces?.length === 1 ? row.company?.performancePlaces[0].erfuellungsOrt : "-",
        header: "Erfüllungsort(e)",
        Cell({ cell, column, row, table }) {
          return row.original.company?.performancePlaces?.length > 0 ?
            <Tooltip title={
              row.original.company?.performancePlaces.map(place => (
                <div>
                  <div>{place?.erfuellungsOrt}</div>
                  <div>{place?.strasse}</div>
                  <div>{place?.postleitzahl + " " + place?.ort}</div>
                </div>
              ))
            } >
              <div className="border-b border-dashed cursor-pointer max-w-300px">{row.original.company?.performancePlaces?.length > 1 ? row.original.company?.performancePlaces[0].erfuellungsOrt + " + " + (row.original.company?.performancePlaces?.length - 1) + "weitere..." : row.original.company?.performancePlaces[0].erfuellungsOrt}</div>
            </Tooltip>
            : <div>-</div>
        },
      },
      {
        Cell({ cell, column, row, table }) {
          return row.original.company?.performancePlaces.every(place => place.imagesDetails?.images?.length > 0 && place.imagesDetails.logo)
            ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#0d9488" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ef4444" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        },
        maxSize: 1,
        header: "Bilder/Logo?",
      },
      {
        Cell({ cell, column, row, table }) {
          return getPayedOrderIcon(row.original as CompanyWithOrders);
        },
        maxSize: 1,
        header: "Bezahlt?",
      },
      {
        accessorFn: (row) =>
          row.company?.beraterKundendatenDetails?.gesprochen_mit_anrede +
          " " +
          row.company?.beraterKundendatenDetails?.gesprochen_mit_vorname +
          " " +
          row.company?.beraterKundendatenDetails?.gesprochen_mit_nachname,
        header: "Ansprechpartner: Name",
      },
      {
        accessorFn: (row) =>
          row.company?.betreiberDetails?.vertretung_anrede +
          " " +
          row.company?.betreiberDetails?.vertretung_vorname +
          " " +
          row.company?.betreiberDetails?.vertretung_nachname,
        header: "Betreiber: Vertreter",
      },
      {
        accessorFn: (row) =>
          row.company?.betreiberDetails?.strasse +
          " " +
          row.company?.betreiberDetails?.postleitzahl +
          " " +
          row.company?.betreiberDetails?.ort,
        header: "Betreiber: Adresse",
      },
      {
        accessorFn: (row) => transformDate(row.company?.updatedAt, true) + " - " + row.company?.updatedBy?.firstName + " " + row.company?.updatedBy?.lastName + " #" + row.company?.updatedBy?.employeeNumber,
        header: "Aktualisiert am",
      },
      {
        accessorFn: (row) => transformDate(row.company?.createdAt, true) + " - " + row.company?.createdBy?.firstName + " " + row.company?.createdBy?.lastName + " #" + row.company?.createdBy?.employeeNumber,
        header: "Erstellt am",
      },
    ],
    []
  );

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white mt-2">
      <MaterialReactTable
        enableColumnFilterModes
        enableRowActions
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={companies?.getCompaniesWeb || []}
        state={{
          showSkeletons: loading,
          showAlertBanner: !!error,
        }}
        columns={columns as any}
        initialState={{ density: "compact", pagination: { pageIndex: 0, pageSize: 100 } }}
        muiToolbarAlertBannerProps={{
          color: 'error',
          children: 'Unerwarteter Fehler: Daten konnten nicht geladen werden! ' + error?.message,
        }}
        muiTableBodyRowProps={({ row }) => ({
          onDoubleClick: () => {
            row?.original &&
              row.original.company?._id &&
              navigate(`/client/overview/${row.original.company?._id}`);
          },
        })}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Aktionen",
            size: 1,
          },
        }}
        renderRowActions={({ row, table }) => (
          <View className="flex flex-row flex-nowrap gap-1 ml-0.5">

            {(row.original.company?.state === CompanyState.DRAFT || row.original.company?.state === CompanyState.REJECTED) &&
              <IconButton
                title="Vertrag bearbeiten / öffnen"
                onClick={() => {
                  navigate(`/client/overview/${row.original.company?._id}`)
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C274C" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                </svg>
              </IconButton>
            }

            {row.original.company?.state === CompanyState.IN_REVIEW &&
              <IconButton
                style={{ marginLeft: -18 }}
                title="Vertrag überprüfen"
                onClick={() => {
                  navigate(`/client/overview/${row.original.company?._id}`)
                }}
              >
                {decisionTableIcon()}
              </IconButton>
            }

            {(row.original.company?.state === CompanyState.ACTIVE || row.original.company?.state === CompanyState.INACTIVE || row.original.company?.state === CompanyState.APPROVED || row.original.company?.state === CompanyState.WAIT_CUSTOMER_REACTION || row.original?.company?.state === CompanyState.PRINTED_SENDED) &&
              <IconButton
                title="Vertrag anzeigen"
                onClick={() => {
                  navigate(`/client/overview/${row.original.company?._id}`)
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C274C" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
              </IconButton>
            }

            <IconButton
              title="Vertrag duplizieren"
              onClick={async () => {
                try {
                  let result = await copyCompany({ variables: { id: row?.original?.company?._id } });
                  addToast("Vertrag erfolgreich dupliziert!", { appearance: "success" });
                  await refetchCompanies({
                    state: searchParams.get('state'),
                    userId: searchParams.get('userId'),
                    customerNumbers: searchParams.get('customerNumbers')
                  });
                } catch (error) {
                  addToast("FEHLER: Vertrag konnte nicht dupliziert werden." + error, { appearance: "error", })
                }
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#a1a1aa" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
              </svg>

            </IconButton>

            {((row.original.company?.state === CompanyState.DRAFT || row.original.company?.state === CompanyState.WAIT_CUSTOMER_REACTION) && (row.original.company.createdBy?._id === currentWebUser?._id || currentWebUser?.role === "ADMIN")) &&
              <IconButton
                title="Vertrag löschen"
                onClick={async () => {
                  try {

                    let swalResult = await MySwal.fire({
                      title: `ACHTUNG: Vertrag löschen`,
                      html: `Das Unternehmen wird entfernt inkl. allen Bildern & Dokumenten.
                      <br><br>Möchtest du diese Änderung wirklich vornehmen?
                      <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                      showDenyButton: true,
                      width: 700,
                      confirmButtonText: `Ja, Unternehmen entfernen!`,
                      denyButtonText: `Abbrechen`,
                    });

                    if (!swalResult.isConfirmed) {
                      return;
                    }

                    try {
                      await deleteCompany({ variables: { companyId: row?.original?.company?._id } });
                    } catch (error) {
                      addToast("FEHLER: " + error, { appearance: "error", })
                    }
                    addToast("Vertrag erfolgreich gelöscht!", { appearance: "success" });
                    await refetchCompanies({
                      state: searchParams.get('state'),
                      userId: searchParams.get('userId'),
                      customerNumbers: searchParams.get('customerNumbers')
                    });
                  } catch (error) {
                    addToast("FEHLER: Vertrag konnte nicht dupliziert werden." + error, { appearance: "error", })
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#dc2626" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </IconButton>
            }
          </View>
        )}
        renderTopToolbarCustomActions={() => (
          <div className="flex flex-row items-center">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {getHeaderText(searchParams.get('state'), searchParams.get('userName'))}
            </h2>

            <div className="flex flex-row ml-2">
              {searchParams?.get('filterLabels') && (
                searchParams.get('filterLabels')?.split(",").map(label => (
                  <div key={label} className="rounded-full p-2 mr-2 bg-pink-50 border font-medium text-sm border-pink-500 text-pink-500">
                    {label}
                  </div>
                ))
              )}
            </div>

            <div className="ml-2">
              <ClientButton
                type="submit"
                onClick={() => {
                  createCompany()
                    .then((a) => {
                      addToast("Neues Unternehmen angelegt", {
                        appearance: "success",
                      });
                      if (a.data?.createNewCompany?._id) {
                        navigate(`/client/overview/${a.data?.createNewCompany?._id}`);
                      } else {
                        refetchCompanies();
                      }
                    }).catch((e) =>
                      addToast("Fehler beim Erstellen " + e, {
                        appearance: "error",
                      })
                    );
                }}
                label="Neu"
                icon={<AntDesign name="adduser" size={19} color="white" />}
              ></ClientButton>
            </div>
          </div>
        )}
      ></MaterialReactTable>
    </View>
  );
}
