import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { PerformancePlace } from "../../../__generated__/graphql-types";

export const ErfuellungsOrteTabs = ({
  performancePlaces,
  onChange,
}: {
  performancePlaces: PerformancePlace[];
  onChange: (event: React.SyntheticEvent, newIndex: number) => void;
}) => {
  const [currentErfuellungsOrtIndex, setCurrentErfuellungsOrt] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    onChange && onChange(event, newTabIndex);
    setCurrentErfuellungsOrt(newTabIndex);
  };

  return (
    <Tabs
      value={currentErfuellungsOrtIndex}
      onChange={handleChange}
      variant="scrollable"
      className="p-1 ml-3 shadow-sm bg-gray-50 select-none rounded-lg mt-3"
      scrollButtons="auto"
      allowScrollButtonsMobile
      aria-label="scrollable auto tabs example"
    >
      {performancePlaces.map((ort, index) => (
        <Tab
          key={index}
          label={
            <div className="flex flex-col">
              <span className="font-bold">{ort.erfuellungsOrt}</span>
              <span className="text-xs">{ort.strasse}</span>
              <span className="text-xs">{ort.postleitzahl + " " + ort.ort}</span>
            </div>
          }
        ></Tab>
      ))}
    </Tabs>
  );
};

export default ErfuellungsOrteTabs;
