import { View } from "react-native";

export default function ClientInputWrapper(props: any) {
  return (
    <>
      <View className={props.className + " flex flex-row"}>
        {props.children}
      </View>
    </>
  );
}
