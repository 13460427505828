import "react-native-gesture-handler";
import * as React from "react";
import MaterialReactTable, { type MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { View } from "react-native";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  CompanyCategory,
  GetAllCategoriesQuery,
  useCreateSubCategoryMutation,
  useDeleteSubCategoryMutation,
  useGetAllCategoriesQuery,
  useUpdateSubCategoryNameMutation,
} from "../../../../__generated__/graphql-types";
import { AntDesign, Feather } from "@expo/vector-icons";
import { MySwal } from "../../../../../WebApp";

export default function CategoriesScreen() {
  const { data: orders, error, loading, refetch: refetchCategories, } = useGetAllCategoriesQuery();
  const { addToast } = useToasts();

  const [createSubCategory] = useCreateSubCategoryMutation();
  const [updateSubCategoryName] = useUpdateSubCategoryNameMutation();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();

  const columns = useMemo<
    MRT_ColumnDef<CompanyCategory>[]
  >(
    () => [
      {
        accessorFn: (row) => row.description,
        header: "Hauptkategorie",
      },
      {
        accessorFn: (row) => row.subCategories?.map((a) => a.name),
        header: "Unterkategorien",
        Cell({ cell, column, row, table }) {
          return (
            <div className="flex flex-row gap-1">
              <div
                className="bg-gray-50 text-pink-500 p-2 hover:bg-white cursor-pointer h-10 border border-gray-200"
                onClick={() => {
                  MySwal.fire({
                    input: 'text',
                    title: `Unterkategorie hinzufügen zu ${row.original.description}`,
                    showCancelButton: true,
                    confirmButtonColor: "#4f46e5",
                    confirmButtonText: `Hinzufügen`,
                    cancelButtonText: "Abbrechen",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await createSubCategory({
                        variables: {
                          categoryId: row.original._id,
                          subCategoryName: result.value
                        },
                      });
                      refetchCategories();
                      addToast(`Unterkategorie '${result.value}' wurde erfolgreich hinzugefügt.`, { appearance: "success" });
                    }
                  });
                }}
              >
                <Feather name="plus" size={19} color="#059669" />
              </div>
              <div className="flex flex-row flex-wrap gap-2">
                {row.original.subCategories?.map((subCategory) => (
                  <div className="flex flex-row">
                    <div className="bg-gray-100 border border-gray-200">
                      <div className=" text-gray-700 p-2">{subCategory.name}</div>
                    </div>
                    <div className="bg-gray-100 border border-gray-200 hover:bg-white cursor-pointer"
                      onClick={() => {
                        MySwal.fire({
                          input: 'text',
                          inputPlaceholder: 'Neuer Kategorienname für \'' + subCategory.name + "'",
                          title: 'Änderung Kategorienname \'' + subCategory.name + "'",
                          showCancelButton: true,
                          confirmButtonColor: "#4f46e5",
                          confirmButtonText: `Änderungen speichern`,
                          cancelButtonText: "Abbrechen",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            try {
                              await updateSubCategoryName({
                                variables: {
                                  subCategoryId: subCategory._id,
                                  subCategoryName: result.value
                                }
                              });
                              refetchCategories();
                              addToast(`Unterkategorie '${subCategory.name}' wurde erfolgreich zu '${result.value}' umbenannt.`, { appearance: "success" });
                            } catch (error) {
                              addToast(`Unterkategorie konnte nicht umbenannt werden. ` + error, { appearance: "error" });
                            }
                          }
                        });
                      }}>
                      <div className="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#2563eb" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>

                      </div>
                    </div>
                    <div className="bg-gray-100 border border-gray-200 hover:bg-white cursor-pointer"
                      onClick={async () => {
                        let swalResult = await MySwal.fire({
                          title: `ACHTUNG: Entfernen von Unterkategorie '${subCategory.name}'`,
                          html: `Die Unterkategorie '${subCategory.name}' wird noch nicht verwendet und kann daher entfernt werden.
                    <br><br>Möchtest du diese Änderung wirklich vornehmen?
                    <br><strong>Bitte beachte, dass dieser Vorgang nicht rückgängig gemacht werden kann!</strong>`,
                          showDenyButton: true,
                          width: 700,
                          confirmButtonText: `Ja, Unterkategorie entfernen`,
                          denyButtonText: `Abbrechen`,
                        });
                        if (!swalResult.isConfirmed) {
                          return;
                        }
                        try {
                          await deleteSubCategory({
                            variables: {
                              subCategoryId: subCategory._id
                            }
                          });
                          refetchCategories();
                          addToast(`Unterkategorie '${subCategory.name}' wurde erfolgreich zu entfernt.`, { appearance: "success" });
                        } catch (error) {
                          addToast(`Unterkategorie konnte nicht entfernt werden. ` + error, { appearance: "error" });
                        }
                      }}>
                      <div className="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#be123c" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <View className="px-3 pb-3 mx-3 mb-3 rounded-xl bg-white mt-2">
      <MaterialReactTable
        enableColumnFilterModes
        localization={MRT_Localization_DE}
        positionToolbarAlertBanner="bottom"
        enableFullScreenToggle
        data={orders?.getAllCategories || []}
        state={{ showSkeletons: loading }}
        columns={columns as any}
        initialState={{ density: "compact" }}
        muiTablePaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <div className="flex flex-row items-center">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight mr-3">
              Kategorien
            </h2>
          </div>
        )}
      ></MaterialReactTable>
    </View>
  );
}
