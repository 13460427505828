import { useParams, useSearchParams } from "react-router-dom";

export default function AdminSignRequestConfirmationScreen() {

    let [searchParams] = useSearchParams();

    return (
        <div>
            <div className="bg-white p-6 m-3 rounded-xl border border-gray-100">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Bestellung {searchParams.get('orderNumber')} - Unterschriften angefordert! 
                </h2>
            </div>
            <div className="bg-white rounded-xl mx-3 border border-gray-100">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-bold text-pink-500">E-Mail gesendet!</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>Es wurde eine Anfrage für die Unterschriften an <span className="font-bold">{searchParams.get('email')}</span> gesendet.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}