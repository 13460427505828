import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "../store";
import { Role } from "../../__generated__/graphql-enum-maps";

export interface WebUser {
    isLoggedIn: boolean;
    currentWebUser?: { _id: string, firstName: string, lastName: string, email: string, employeeNumber: number, role: Role };
    access_token?: string;
}

const initialState: WebUser = {
    isLoggedIn: false,
    currentWebUser: undefined,
    access_token: undefined
}

export interface WebUserPayload {
    currentWebUser?: { _id: string, firstName: string, lastName: string, email: string, employeeNumber: number, role: Role };
    access_token?: string;
    isLoggedIn: boolean;
}

export const webUserSliceNative = createSlice({
    name: 'webUser',
    initialState,
    reducers: {
        loginWebUser: (state, action: PayloadAction<WebUserPayload>) => {
            state.isLoggedIn = true;
            state.currentWebUser = action.payload.currentWebUser;
            state.access_token = action.payload.access_token;
        },
        logoutWebUser: (state) => {
            state.isLoggedIn = false;
            state.currentWebUser = undefined;
            state.access_token = undefined;
        }
    }
})

export const selectWebUser = (root: IRootState): WebUser => {
    return {
        currentWebUser: root.webUser.currentWebUser,
        access_token: root.webUser.access_token,
        isLoggedIn: root.webUser.isLoggedIn,
    };
}

export const webUserSliceNativeReducer = webUserSliceNative.reducer;
export const {
    loginWebUser,
    logoutWebUser
} = webUserSliceNative.actions;