import { Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import Stepper, { StepInfo, initSteps } from "../../../web-components/Stepper";
import CompaniesList from "../../../../company/CompaniesList";
import { Ionicons, MaterialIcons, Octicons } from "@expo/vector-icons";
import { classNames } from "../../../../../helpers";
import CompanyDetailScreen from "../../../../company/CompanyDetailScreen";
import {
  Company,
  CompanyState,
  useCreateNewCompanyMutation,
  useGetCompanyWebQuery,
} from "../../../../../__generated__/graphql-types";
import { useNavigate, useParams } from "react-router-dom";
import ClientButton, {
} from "../../../web-components/ClientButton";
import { AsyncFunction, SyncFunction } from "../../../../../constants";
import BilderForm from "../contract-steps/10_bilder-form";
import ZusammenfassungForm from "../contract-steps/11_zusammenfassung-form";
import BetreiberForm from "../contract-steps/1_betreiber-form";
import KommunikationsanschriftForm from "../contract-steps/2_kommunikationsanschrift-form";
import Angebotseinstellungen from "../contract-steps/3_angebotseinstellungen-form";
import ErfuellungsortForm from "../contract-steps/4_erfuellungsort-form";
import BeraterUndKundendatenForm from "../contract-steps/5_berater-und-kundendaten-form";
import OpeningHoursForm from "../contract-steps/6_oeffungszeiten-form";
import PropertyFeaturesForm from "../contract-steps/7_ausstattungsmerkmale-form";
import VertragsvorschauScreen from "../contract-steps/8_vertragsvorschau_screen";
import UnterschriftenForm from "../contract-steps/9_unterschrift-form";
import ContractStateBadge from "../../../web-components/ContractStateBadge";
import WebErrorBoundary from "../../WebErrorBoundary";

export const previousFormButton = (onBackClickFn: SyncFunction) => {
  return (
    <div className="mr-1">
      <ClientButton
        onClick={onBackClickFn}
        mode="secondary"
        label="Zurück"
        iconLeft
        icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"></path>
        </svg>}
      ></ClientButton>
    </div>
  );
};

export const contuineFormButton = ({ formIsReadonly, disabled, isLoading, submitForm, contuineWithoutSaving }: { formIsReadonly: boolean, disabled?: boolean, isLoading?: boolean, submitForm: AsyncFunction, contuineWithoutSaving: SyncFunction }) => {
  return (
    <ClientButton
      loading={isLoading}
      type="button"
      mode={formIsReadonly ? "secondary" : "primary"}
      onClick={async () => {
        if (formIsReadonly) {
          contuineWithoutSaving();
        } else {
          await submitForm();
        }
      }}
      disabled={disabled}
      icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>
      }
      label={formIsReadonly ? "Weiter" : "Speichern & Weiter"}
    ></ClientButton>
  );
};

export const ContractFormScreen = (props: any) => {
  const [previewType, setPreviewType] = useState("detail");
  const [currentStep, setCurrentStep] = useState<StepInfo | undefined>(
    initSteps[0]
  );
  const [company, setCompany] = useState<Company | undefined>(undefined);
  let { id, step } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (step && Number(step)) {
      setCurrentStep(initSteps[Number(step) - 1]);
    } else {
      if (!currentStep) {
        setCurrentStep(initSteps[0]);
      }
    }
  }, [step]);

  let {
    loading,
    error,
    refetch: refetchCompany,
  } = useGetCompanyWebQuery({
    variables: { id: id || "" },
  });

  const createCreateMutation = useCreateNewCompanyMutation()[0];

  useEffect(() => {
    if (id) {
      refetchCompany({ id }).then((a) => {
        setCompany(a.data?.getCompany as Company);
      });
    } else {
      createCreateMutation().then((obj) => {
        let createdCompany = obj.data?.createNewCompany;
        setCompany(createdCompany as Company);
      });
    }
  }, [id]);

  const handleOnStepClick = (step: StepInfo | undefined) => {
    navigate(`/client/update/${company?._id}/${step?.index}`);
  };

  const contuineAndSave = async () => {
    if (currentStep) {
      let newCompany = await refetchCompany({ id: company?._id });
      let newIndex = currentStep.index + 1;

      if (newIndex > initSteps.length) {
        navigate(`/client/overview/${company?._id}`);
      } else {
        navigate(`/client/update/${company?._id}/${newIndex}`);
      }

      setCompany(newCompany?.data.getCompany);
      setCurrentStep(initSteps.find((a) => a.index === newIndex));
    }
  };

  const contuineWithoutSave = async () => {
    if (currentStep) {
      let newIndex = currentStep.index + 1;

      if (newIndex > initSteps.length) {
        navigate(`/client/overview/${company?._id}`);
      } else {
        navigate(`/client/update/${company?._id}/${newIndex}`);
      }

      setCurrentStep(initSteps.find((a) => a.index === newIndex));
    }
  };

  const handleOnBackClick = async () => {
    if (currentStep) {
      let newIndex = currentStep.index - 1;
      navigate(`/client/update/${company?._id}/${newIndex}`);
      setCurrentStep(initSteps.find((a) => a.index === newIndex));
    }
  };

  if (!company || loading) {
    return <Text>Wird geladen..</Text>;
  }

  if (error && error.graphQLErrors) {
    return (
      <div>
        <pre>
          Bad:{" "}
          {error.graphQLErrors.map(({ message }, i) => (
            <span key={i}>{message}</span>
          ))}
        </pre>
      </div>
    );
  }

  return (
    <>
      <View className="bg-white p-6 m-3 rounded-xl border border-gray-100 flex flex-row justify-between">
        <div>
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {currentStep?.name + ` (${currentStep?.index}/${initSteps.length})`}
          </h2>
          <div className="mt-2">
            <ContractStateBadge state={company.state}></ContractStateBadge> <span className="ml-2 text-xs muted text-gray-400 leading-6">Kundennummer: {company?.kundennummer}</span>
          </div>
        </div>
        <div className="h-0">
          <ClientButton
            onClick={async () => {
              navigate(`/client/overview/${company._id}`);
            }}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" dataSlot="icon" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 4.5v15m6-15v15m-10.875 0h15.75c.621 0 1.125-.504 1.125-1.125V5.625c0-.621-.504-1.125-1.125-1.125H4.125C3.504 4.5 3 5.004 3 5.625v12.75c0 .621.504 1.125 1.125 1.125Z" />
              </svg>
            }
            iconLeft
            mode="secondary"
            label="Zur Übersicht"
          ></ClientButton>
        </div>
      </View>
      <View className="flex">
        <View className="flex flex-row flex-wrap  m-4 max-w-94%">
          <View className="flex-grow bg-white p-4 rounded-xl w-min border border-gray-100 ">
            <WebErrorBoundary>
              {/* md:max-w-40rem xl:max-w-36rem */}
              {currentStep?.index === 1 && (
                <BetreiberForm
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></BetreiberForm>
              )}
              {currentStep?.index === 2 && (
                <KommunikationsanschriftForm
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></KommunikationsanschriftForm>
              )}
              {currentStep?.index === 3 && (
                <Angebotseinstellungen
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  form={company}
                  onBackClick={handleOnBackClick}
                />
              )}
              {currentStep?.index === 4 && (
                <ErfuellungsortForm
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></ErfuellungsortForm>
              )}
              {currentStep?.index === 5 && (
                <BeraterUndKundendatenForm
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></BeraterUndKundendatenForm>
              )}
              {currentStep?.index === 6 && (
                <OpeningHoursForm
                  contuineAndSave={contuineAndSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></OpeningHoursForm>
              )}
              {currentStep?.index === 7 && (
                <PropertyFeaturesForm
                  contuineAndSave={contuineAndSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></PropertyFeaturesForm>
              )}
              {currentStep?.index === 8 && (
                <VertragsvorschauScreen
                  contuineAndSave={contuineAndSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></VertragsvorschauScreen>
              )}
              {/* {currentStep?.index === 9 && (
                <UnterschriftenForm
                  contuineAndSave={contuineAndSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></UnterschriftenForm>
              )} */}
              {currentStep?.index === 9 && (
                <ZusammenfassungForm
                  contuineAndSave={contuineAndSave}
                  contuineWithoutSave={contuineWithoutSave}
                  onBackClick={handleOnBackClick}
                  form={company}
                ></ZusammenfassungForm>
              )}
              {currentStep?.index === 10 && (
                <BilderForm
                  contuineAndSave={contuineAndSave}
                  form={company}
                  onBackClick={handleOnBackClick}
                ></BilderForm>
              )}
            </WebErrorBoundary>
          </View>

          <View className="flex flex-row ml-5">
            <View className="items-center">
              <View className="min-h-600px rounded-xl py-2 pl-2 bg-white border border-gray-100">
                <span className="relative z-0 inline-flex rounded-md justify-center">
                  <button
                    onClick={() => setPreviewType("list")}
                    type="button"
                    className={classNames(
                      previewType === "list"
                        ? "bg-pink-500"
                        : "bg-white border border-gray-300",
                      "relative inline-flex items-center px-2 py-2 rounded-l-md"
                    )}
                  >
                    <span className="sr-only">Listenansicht</span>
                    <Ionicons
                      name="grid-outline"
                      size={24}
                      color={previewType === "list" ? "white" : "black"}
                    />
                  </button>
                  <button
                    onClick={() => setPreviewType("detail")}
                    type="button"
                    className={classNames(
                      previewType === "detail"
                        ? "bg-pink-500"
                        : "bg-white border border-gray-300",
                      "-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md"
                    )}
                  >
                    <span className="sr-only">Detailansicht</span>
                    <MaterialIcons
                      name="phone-iphone"
                      size={24}
                      color={previewType === "detail" ? "white" : "black"}
                    />
                  </button>
                </span>
                <View
                  className="w-96 overflow-y-scroll mt-2"
                  style={{ maxHeight: "620px" }}
                >
                  {previewType === "list" && (
                    <CompaniesList
                      totalCount={company?.performancePlaces?.length}
                      companies={company.performancePlaces?.map(performancePlace => ({
                        _id: performancePlace?._id,
                        coupon_1_category: performancePlace?.company?.angebotseinstellungenDetails?.coupon_1?.category,
                        coupon_2_category: performancePlace?.company?.angebotseinstellungenDetails?.coupon_2?.category,
                        coupon_1_subCategory: performancePlace?.company?.angebotseinstellungenDetails?.coupon_1?.subCategory,
                        coupon_2_subCategory: performancePlace?.company?.angebotseinstellungenDetails?.coupon_2?.subCategory,
                        coupon_2_isActive: performancePlace?.company?.angebotseinstellungenDetails?.coupon_2?.isActive,
                        distance: 0,
                        erfuellungsOrt: performancePlace?.erfuellungsOrt,
                        logo: performancePlace?.imagesDetails?.logo,
                        image: performancePlace?.imagesDetails.images?.length && performancePlace?.imagesDetails.images[0],
                        totalSaving: null,
                        location: null,
                      }))}
                      navigation={props.navigation}
                      loading={false}
                      enablePagination={false}>
                    </CompaniesList>
                  )}

                  {previewType === "detail" && !company?.performancePlaces?.length && (
                    <div className="mx-auto mt-52 text-center">
                      <dt className="mx-auto text-base font-semibold leading-5 text-gray-900">
                        Vorschau nicht vorhanden.
                      </dt>
                      <dd className="mt-2 text-base leading-5 text-gray-500">Füge mindestens einen Erfüllungsort hinzu.</dd>
                    </div>)}

                  {previewType === "detail" && company?.performancePlaces?.length > 0 && company?.performancePlaces[0]?._id && (
                    <CompanyDetailScreen
                      adminPreview
                      navigation={null}
                      route={null}
                      key={null}
                      performancePlaceId={company?.performancePlaces[0]._id}
                    ></CompanyDetailScreen>
                  )}
                </View>
              </View>
            </View>

            <View className="ml-5">
              <Stepper
                step={currentStep?.index}
                onStepClick={handleOnStepClick}
                allowStepManual={company.isReadonly}
              ></Stepper>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};
