import { FontAwesome5 } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";

export default function AdminSettingsScreen() {
  const navigate = useNavigate();
  return (
    <View className="flex flex-wrap gap-2 m-10">
      <TouchableOpacity onPress={() => navigate("text-templates")} className="flex flex-row  w-60 rounded-lg p-4 border border-gray-100 bg-white cursor-pointer hover:border-gray-200 items-center">
        <View className="bg-gray-100 w-12 p-3 rounded-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>

        </View>
        <View>
          <Text className="text-pink-500 font-semibold ml-3">
            Textvorlagen
          </Text>
          <Text className="text-gray-400 ml-3 text-xs font-light">
            Anpassen der Vorlagen
          </Text>
        </View>
      </TouchableOpacity>

      <TouchableOpacity onPress={() => navigate("categories")} className="flex flex-row  w-60 rounded-lg p-4 border border-gray-100 bg-white cursor-pointer hover:border-gray-200 items-center">
        <View className="bg-gray-100 w-12 p-3 rounded-md text-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#ec4899" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
          </svg>
        </View>
        <View>
          <Text className="text-pink-500 font-semibold ml-3">
            Kategorien
          </Text>
          <Text className="text-gray-400 ml-3 text-xs font-light">
            Anpassen der Kategorien
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}
