import * as React from "react";

export default function BillSVG(props: any) {
  return (
    <svg
      fill="#EC4899"
      width="64px"
      height="64px"
      viewBox="-5.76 -5.76 35.52 35.52"
      id="bill-euro"
      data-name="Flat Line"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          id="secondary"
          d="M11,10a8,8,0,0,1,4.13-7H5A1,1,0,0,0,4,4V21l2.34-1,2.33,1L11,20l2.33,1,2.33-1L18,21V17.93A8,8,0,0,1,11,10Z"
          style={{
            fill: "#FCEBF4",
            strokeWidth: 2
          }}
        ></path>
        <path
          id="primary"
          d="M20,7.77A3,3,0,0,0,18,7h0a3,3,0,0,0-3,3h0a3,3,0,0,0,3,3h0a3,3,0,0,0,2-.77"
          style={{
            fill: "none",
            stroke: "#EC4899",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2
          }}
        ></path>
        <path
          id="primary-2"
          data-name="primary"
          d="M14,10h3m-5,5H8m2-4H8"
          style={{
            fill: "none",
            stroke: "#EC4899",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2
          }}
        ></path>
        <path
          id="primary-3"
          data-name="primary"
          d="M18,18v3l-2.33-1-2.33,1L11,20,8.67,21,6.34,20,4,21V4A1,1,0,0,1,5,3H15"
          style={{
            fill: "none",
            stroke: "#EC4899",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 2
          }}
        ></path>
      </g>
    </svg>
  );
}
