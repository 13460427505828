import { Text, View } from "react-native";
import { CompanyState } from "../../../__generated__/graphql-types";

export default function ContractStateBadge(props: { state: CompanyState }) {
  if (props.state === CompanyState.DRAFT) {
    return (
      <Text
        className={`p-1.5 bg-blue-100 text-blue-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Entwurf
      </Text>
    );
  } else if (props.state === CompanyState.ACTIVE) {
    return (
      <Text
        className={`p-1.5 bg-teal-100 text-teal-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Aktiv
      </Text>
    );
  } else if (props.state === CompanyState.APPROVED) {
    return (
      <Text
        className={`p-1.5 bg-lime-100 text-lime-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Genehmigt
      </Text>
    );
  } else if (props.state === CompanyState.WAIT_CUSTOMER_REACTION) {
    return (
      <Text
        className={`p-1.5 bg-gray-100 text-gray-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Warte auf Kunde
      </Text>
    );
  } else if (props.state === CompanyState.IN_REVIEW) {
    return (
      <Text
        className={`p-1.5 bg-yellow-100 text-yellow-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        In Prüfung
      </Text>
    );
  } else if (props.state === CompanyState.REJECTED) {
    return (
      <Text
        className={`p-1.5 bg-red-100 text-red-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Abgelehnt
      </Text>
    );
  } else if (props.state === CompanyState.INACTIVE) {
    return (
      <Text
        className={`p-1.5 bg-orange-100 text-orange-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Inaktiv
      </Text>
    );
  } else if (props.state === CompanyState.PRINTED_SENDED) {
    return (
      <Text
        className={`p-1.5 bg-fuchsia-100 text-fuchsia-700 font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        Gedruckt & Verschickt
      </Text>
    );
  }
  else {
    return (
      <Text
        className={`p-1.5 bg-white-200 text-black font-bold rounded-md text-xs uppercase tracking-wide text-center`}
      >
        {props.state}
      </Text>
    );
  }
}
