// CustomText.js

import { Text, StyleSheet } from "react-native";

export default function CustomText(props: any) {
  return (
    <Text className={props.className + ' text-gray-900'} style={[styles.defaultStyle, props.style]}>{props.children}</Text>
  );
}

const styles = StyleSheet.create({
  // ... add your default style here
  defaultStyle: {
    fontFamily: 'RadioCanada-VariableFont',
    fontWeight: '400'
  },
});
