import React, { useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import logo from "../../../../assets/youtwome-big-logo.jpg";
import { Image } from "expo-image";
import ClientTextInput from "../web-components/ClientTextInput";
import ClientButton from "../web-components/ClientButton";
import { Octicons } from "@expo/vector-icons";
import { useToasts } from "react-toast-notifications";
import { useLoginWebUserMutation } from "../../../__generated__/graphql-types";
import { ApolloError } from "@apollo/client";
import { useDispatch } from "react-redux";
import { loginWebUser, logoutWebUser } from "../../../store/web-slices/userSlice.web";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AdminLoginScreen(props: any) {
  const LoginValidationSchema = Yup.object().shape({
    email: Yup.string().email("Ungültige Email").required("Erforderlich"),
    password: Yup.string()
      .min(2, "Passwort zu kurz!")
      .max(30, "Passwort zu lang!")
      .required("Erforderlich"),
  });

  const { addToast } = useToasts();
  let [login] = useLoginWebUserMutation({});
  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(logoutWebUser());
  }, [])

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get("returnUrl");

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={LoginValidationSchema}
      validateOnMount
      onSubmit={(values) => {}}
    >
      {({ isValid, values }) => (
        <div className="mx-auto p-2 bg-white rounded-3xl">
          <div className="col flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <Image
                  style={{
                    resizeMode: "center",
                    height: 250,
                    width: 250,
                    margin: "auto",
                  }}
                  source={logo}
                />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Anmelden
                </h2>
              </div>

              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="POST" className="space-y-6">
                    <Field
                      component={ClientTextInput}
                      viewClassName={"ml-0"}
                      name="email"
                      label="E-Mail"
                      mode="text"
                    ></Field>

                    <Field
                      component={ClientTextInput}
                      viewClassName={"ml-0"}
                      name="password"
                      label="Passwort"
                      mode="text"
                      type="password"
                    ></Field>
{/* 
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Remember me
                        </label>
                      </div>
                    </div> */}

                    <div>
                      <ClientButton
                        type="submit"
                        disabled={!isValid}
                        label="Anmelden"
                        icon={
                          <Octicons
                            name="chevron-right"
                            size={21}
                            color="white"
                          />
                        }
                        onClick={async () => {
                          try {
                            let response = await login({
                              variables: {
                                user: {
                                  email: values.email,
                                  password: values.password,
                                },
                              },
                            });
                            if (response.data?.loginWebUser) {
                              addToast(
                                "Hallo, " +
                                  response.data.loginWebUser.user.firstName,
                                {
                                  appearance: "success",
                                }
                              );
                              dispatch(
                                loginWebUser({
                                  isLoggedIn: true,
                                  access_token:
                                    response.data.loginWebUser.token,
                                  currentWebUser:
                                    response.data.loginWebUser.user,
                                })
                              );
                              navigate(`/client/${returnUrl || 'dashboard'}`);
                            }
                          } catch (e) {
                            let error = e as ApolloError;
                            addToast(error.message, {
                              appearance: "error",
                            });
                            console.error(error.message);
                          }
                        }}
                      ></ClientButton>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
