import React, { Fragment } from 'react';
import { classNames, transformDate } from '../../../helpers';
import { EntityLog, Maybe } from '../../../__generated__/graphql-types';

export default function EntityTimeLine(props: { logs: Maybe<EntityLog[]> | EntityLog[] | undefined }) {
    if (!props.logs?.length || !Array.isArray(props.logs)) {
        return (<div>Keine Logs vorhanden.</div>)
    }
    return (
        <dd className="mt-3 text-sm text-gray-900 overflow-y-scroll" style={{ maxHeight: 450 }}>
            <div className="flow-root">
                <ul role="list" className="-mb-8">
                    {props.logs?.reverse()?.map((log, index) => (
                        <li key={index}>
                            <div className="relative pb-8">
                                {index !== props.logs?.length - 1 ? (
                                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                ) : null}
                                <div className="relative flex items-start space-x-3">
                                    <div>
                                        <div className="h-9 w-9 rounded-full bg-gray-200 flex items-center justify-center ring-8 ring-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#6B7280" className="w-5 h-5">
                                                <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <div>
                                            <div className="text-sm">
                                                <a className="font-semibold text-gray-900">
                                                    {log.createdBy ? log.createdBy?.firstName + " " + log.createdBy?.lastName : 'Mitarbeiter unbekannt'}
                                                </a>
                                            </div>
                                            <p className="mt-0.5 text-xs text-gray-500">{transformDate(log.createdAt)}</p>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                            <p>{log.comment}</p>
                                        </div>
                                        {log.tag && (
                                            <Fragment key={log.tag}>
                                                <a
                                                    className="relative inline-flex items-center rounded-full border border-gray-300 px-3 mt-1 text-sm"
                                                >
                                                    <span className="absolute flex-shrink-0 flex items-center justify-center">
                                                        <span
                                                            className={classNames('bg-blue-400', 'h-1.5 w-1.5 rounded-full')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                    <span className="ml-3.5 font-medium text-gray-900">{log.tag}</span>
                                                </a>{' '}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </dd>
    );
};